import CalendarSVG from 'assets/images/calendar.svg';
import WhiteboardSVG from 'assets/images/whiteboard.svg';
import RecordSVG from 'assets/images/record.svg';
import GaleWarningSVG from 'assets/images/gale-warning.svg';

export const CALL_MENU_DATA = [
  {
    id: 1,
    name: 'Calendar',
    icon: CalendarSVG,
    iconWidth: 20,
    iconHeight: 20,
  },
  {
    id: 2,
    name: 'Whiteboard',
    icon: WhiteboardSVG,
    iconWidth: 20,
    iconHeight: 20,
  },
  {
    id: 3,
    name: 'Start recording',
    icon: RecordSVG,
    iconWidth: 20,
    iconHeight: 20,
  },
  {
    id: 4,
    name: 'Report',
    icon: GaleWarningSVG,
    iconWidth: 20,
    iconHeight: 20,
  },
];

export const STATUSES = [
  {
    id: 1,
    name: 'Online',
    iconWidth: 20,
    iconHeight: 20,
  },
  {
    id: 2,
    name: 'Offline',
    iconWidth: 20,
    iconHeight: 20,
  },
];
