import React, { useEffect, useState } from 'react';
import { Modal, LoaderOn, Loader, Collapse } from 'tt-ui-lib/core';
import { clsx } from 'clsx';
import styles from '../FceAdmin.module.scss';
import FieldModal from './FieldModal';
import { useDigitalAssetsTTAPI } from 'modules/tt-digital-assets-provider';
import SettingsItem from './SettingsItem';
import { RightIcon } from 'tt-ui-lib/icons';

const ExpandIcon = ({ isActive }) => (
  <RightIcon
    style={{
      display: 'block',
      marginTop: 10,
      width: 20,
      height: 20,
      transform: `rotate(${isActive ? 270 : 90}deg)`,
    }}
  />
);

const SettingsModal = ({ open, setOpen, id, name, onClose }) => {
  const { getSettingsFieldsList, getSettingsParamsListValues } = useDigitalAssetsTTAPI();
  const [settingsSections, setSettingsSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [secondLoading, setSecondLoading] = useState(false);
  const [fetchData, setFetchData] = useState(false);

  const [fieldModalOpen, setFieldModalOpen] = useState(false);
  const [fieldId, setFieldId] = useState('');
  const [fieldName, setFieldName] = useState('');
  const [fieldTupleModalOpen, setFieldTupleModalOpen] = useState(false);
  const [fieldTupleId, setFieldTupleId] = useState({ id: '', param: '' });

  const [ttTypes, setTTTypes] = useState([]);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getSettingsData = (withMainLoading = true) => {
    let loadSetter = setLoading;
    if (!withMainLoading) loadSetter = setSecondLoading;

    loadSetter(true);

    getSettingsFieldsList(id?.id, id?.version, true)
      .then((res) => {
        setSettingsSections([]);
        setSettingsSections(res || []);

        getSettingsParamsListValues('TT_TYPE_METHOD', true)
          .then((resList) => {
            setTTTypes(resList.map((item) => ({ label: item.name, value: item.id })) || []);
          })
          .finally(() => {
            loadSetter(false);
          });
      })
      .catch(() => {
        loadSetter(false);
      });
  };

  const openTupleModal = (tupleId, param) => {
    if (!tupleId || !param) return;

    setFieldTupleId({ id: tupleId, param: param });
    setFieldTupleModalOpen(true);
  };

  useEffect(() => {
    if (open && id) {
      getSettingsData();
    }
  }, [open]);

  useEffect(() => {
    if (fetchData) {
      setExpanded(false);
      getSettingsData(false);
      setFetchData(false);
    }
  }, [fetchData]);

  useEffect(() => {
    if (!fieldModalOpen) {
      setFieldId('');
    }
  }, [fieldModalOpen]);

  useEffect(() => {
    if (!fieldTupleModalOpen) {
      setFieldTupleId({ id: '', param: '' });
    }
  }, [fieldTupleModalOpen]);

  return (
    <>
      <Modal
        title={`${name || ''} Settings`}
        open={open}
        onClose={() => {
          setOpen(false);
          if (onClose) {
            onClose();
          }
        }}
        closeOnlyByControls
        destroyOnClose
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignContent: 'space-between',
          }}
        >
          {secondLoading && <LoaderOn />}
          {loading ? (
            <div
              style={{
                height: 200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Loader />
            </div>
          ) : (
            <div
              className={styles.accordionContent}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
                marginTop: 20,
                marginBottom: 30,
              }}
            >
              <Collapse
                items={settingsSections.map((item, index) => ({
                  key: index,
                  label: <div style={{ fontWeight: 600 }}>{item.method}</div>,
                  children: (
                    <div className={clsx(styles.accordionContent, 'accordionContent')}>
                      <SettingsItem
                        itemData={item}
                        contract={id?.id}
                        version={id?.version}
                        setFieldId={setFieldId}
                        setFieldModalOpen={setFieldModalOpen}
                        ttTypes={ttTypes}
                        setFetchData={setFetchData}
                        setFieldName={setFieldName}
                      />
                    </div>
                  ),
                }))}
                expandIcon={ExpandIcon}
                appearance="expanded"
                accordion
              />
            </div>
          )}
        </div>
      </Modal>

      <FieldModal
        open={fieldModalOpen}
        setOpen={setFieldModalOpen}
        id={fieldId}
        openTupleModal={openTupleModal}
        name={fieldName}
        setName={setFieldName}
      />
      <FieldModal
        open={fieldTupleModalOpen}
        setOpen={setFieldTupleModalOpen}
        id={fieldTupleId}
        name={fieldName}
        setName={setFieldName}
        isTuple
      />
    </>
  );
};

export default SettingsModal;
