/* eslint no-restricted-imports: ["error", "fs"] */

import React from 'react';
import { Button, Modal } from 'tt-ui-lib/core';
import { HelpIcon } from 'tt-ui-lib/icons';
import clsx from 'clsx';
import styles from '../styles.module.scss';
import { useDigitalAssetsCaFFee } from 'modules/tt-digital-assets-provider';

const ReceiveModal = ({
  showReceiveConfirm,
  operationToReceive,
  setOperationToReceiveInd,
  setShowReceiveConfirm,
  setOperationToReceive,
  chainSettings,
  redeem,
}) => {
  const { showAboutCaFFee } = useDigitalAssetsCaFFee();

  return (
    <Modal //  Receive from {bridge}
      open={showReceiveConfirm}
      onClose={() => {
        setOperationToReceiveInd(0);
        setShowReceiveConfirm(false);
        setOperationToReceive(null);
      }}
      fullWidth
      maxWidth="md"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      title="Receive request"
    >
      <div>
        <div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
          <div className={clsx(styles.confirmDetailRow, 'confirmDetailRow')}>
            <div>From:</div>
            <div>{operationToReceive?.net_from_name}</div>
          </div>
          <div className={clsx(styles.confirmDetailRow, 'confirmDetailRow')}>
            <div>To:</div>
            <div>{operationToReceive?.net_to_name}</div>
          </div>
          <div className={clsx(styles.confirmDetailRow, 'confirmDetailRow')}>
            <div>Amount:</div>
            <div>
              {operationToReceive?.amount_from_str}&nbsp;
              {chainSettings?.nativeCurrency?.symbol}
            </div>
          </div>
          <div className={clsx(styles.confirmDetailRow, 'confirmDetailRow')}>
            <div className={styles.centerRow}>
              Commission CaFFee:
              <div
                style={{
                  width: 22,
                  cursor: 'pointer',
                }}
                onClick={() => showAboutCaFFee()}
              >
                <HelpIcon style={{ width: 20, height: 20, flexShrink: 0 }} />
              </div>
            </div>
            <div>
              {operationToReceive?.fee_to_str}&nbsp;
              {chainSettings?.nativeCurrency?.symbol}
            </div>
          </div>
          <div className={`${styles.confirmDetailRow} ${styles.bold}`}>
            <div>Total:</div>
            <div>
              {operationToReceive?.amount_to_full_str}&nbsp;
              {chainSettings?.nativeCurrency?.symbol}
            </div>
          </div>
        </div>
        <div className={clsx(styles.sendToRow2, 'sendToRow2')}>
          <Button
            type="default"
            onClick={() => {
              setOperationToReceiveInd(0);
              setShowReceiveConfirm(false);
            }}
          >
            Back
          </Button>
          <Button type="primary" onClick={redeem}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ReceiveModal;
