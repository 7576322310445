import React, { useEffect, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import ErrorModal from 'components/Modals/ErrorModal';
import { useWindowSize } from 'utils/useWindowSize';
import styles from './PageContainer.module.scss';
import LoaderAfterDOM from '../LoaderAfterDOM';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { GET_USER } from 'api';
import { SEO } from './RolePageContainer/SEO';

const MIN_RATIO_APPARENT_HEIGHT_TO_FULL = 0.6;

const PageContainer = ({ children, keywords, title, isImgBg }) => {
  const location = useLocation();
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [getUser] = useLazyQuery(GET_USER);

  const windowSize = useWindowSize();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const loader = document.getElementById('loadingsScreen');
      if (loader) {
        setTimeout(() => {
          loader.style.opacity = 0;
          loader.style.visibility = 'hidden';
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    if (window) {
      if (windowSize.height / windowSize.availHeight <= MIN_RATIO_APPARENT_HEIGHT_TO_FULL) {
        setIsKeyboardOpen(true);
      }
    }
  }, [windowSize]);

  const changeMetaTitleAndDescription = (ogTitle, ogDescription) => {
    setMetaTitle(ogTitle);
    setMetaDescription(ogDescription);
  };

  useEffect(() => {
    (async () => {
      let userId = location.pathname.split('/dashboard/profile/');
      userId = userId[userId.length - 1];
      if (userId && location.pathname && location?.pathname.includes('/dashboard/profile/')) {
        const userValue = await getUser({ variables: { id: userId } });
        if (userValue?.data?.user) {
          const user = userValue?.data?.user;
          changeMetaTitleAndDescription(`${user.first_name} ${user.last_name}`, user.role_name);
        } else {
          changeMetaTitleAndDescription(
            'TransparenTerra| ESG Navigation| To Restore Trust',
            'Welcome to the cloud platform for direct impact investments that brings together experts, businesses, investors, and individuals to build transparency and ultimate trust.'
          );
        }
      } else {
        changeMetaTitleAndDescription(
          'TransparenTerra| ESG Navigation| To Restore Trust',
          'Welcome to the cloud platform for direct impact investments that brings together experts, businesses, investors, and individuals to build transparency and ultimate trust.'
        );
      }
    })();
  }, [location]);

  return (
    <>
      <SEO
        title={title || 'TransparenTerra'}
        ogTitle={metaTitle}
        ogDescription={metaDescription}
        description="Welcome to the cloud platform for direct impact investments that brings together experts, businesses, investors, and individuals to build transparency and ultimate trust."
        name="TransparenTerra"
        type="article"
      />
      {/* eslint-disable-next-line react/no-unknown-property */}
      <div className={`${styles.pageContainer} ${isImgBg && styles.imgBg}`} unselectable="on">
        {children}
      </div>
      {/*  <Loader /> */}
      <LoaderAfterDOM />
      <CookieConsent
        buttonText="Accept"
        containerClasses={styles.cookieWrapper}
        buttonClasses={styles.btn}
      >
        Welcome to Transparenterra! To provide a better experience for you, we use cookies. For more
        information, please review our{' '}
        <a href="/privacy-policy" target="_blank" className={styles.link} rel="noreferrer">
          Privacy Policy
        </a>
      </CookieConsent>
      <ErrorModal
        isModelOpen
        title="Please rotate your device"
        isKeyboardOpen={isKeyboardOpen}
        isOrientationModal
      />
    </>
  );
};

export default PageContainer;
