/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable no-shadow */
/* eslint-disable no-debugger */
/* eslint-disable react/no-array-index-key */

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import {
  Select,
  Button,
  Block,
  Collapse,
  Space,
  openNotification,
  ANTD_COMPONENTS,
} from 'tt-ui-lib/core';
import {
  AddIcon,
  WalletIcon,
  SettingsIcon,
  VisibleIcon,
  EditIcon,
  WarningIcon,
  RightIcon,
} from 'tt-ui-lib/icons';

import {
  fromAscii,
  useDigitalAssets,
  useDigitalAssetsBridge,
  useDigitalAssetsTTAPI,
  useDigitalAssetsWallet,
} from 'modules/tt-digital-assets-provider';

import { closeAppLoader, openAppLoader } from '../../../utils/appUtils';

import SettingsModal from './Components/SettingsModal';

import styles from './FceAdmin.module.scss';
import { DirectTTAPIRequest } from '../../../modules/tt-digital-assets-provider/utils/request';
import SelectContractVersionModal from './Modals/SelectContractVersionModal';
import ContractCompileResultModal from './Modals/ContractCompileResultModal';
import ContractCompileRequestModal from './Modals/ContractCompileRequestModal';
import ChangeNetworkModal from './Modals/ChangeNetworkModal';
import ContractFunctionResult from './Modals/ContractFunctionResult';
import ContractFunctionRequest from './Modals/ContractFunctionRequest';
import ServicesSettingsModal from './Modals/ServicesSettingsModal';

const { REACT_APP_NODE_ENV } = process.env;
const { Tabs } = ANTD_COMPONENTS;

const ExpandIcon = ({ isActive }) => (
  <RightIcon
    style={{
      display: 'block',
      marginTop: 10,
      width: 20,
      height: 20,
      transform: `rotate(${isActive ? 270 : 90}deg)`,
    }}
  />
);

const FceAdminPage = () => {
  const {
    chainSettings,
    showError,
    showSuccess,
    toBN,
    isAddress,
    toWei,
    dateTime2int,
    getContractByAddress,
    getContractAddress,
    deploy,
    callContractMethodWithAddressABI,
    sendContractMethodWithAddressABI,
    sendWithAddress,
    TTAPIRequest,
    getContractsDetail,
    switchChain,
    chainId,
    hex2num,
    obj2json,
    account,
    status,
    connect,
    strToHex,
  } = useDigitalAssets();

  const { getBalance } = useDigitalAssetsWallet();
  const { bridges, bridge } = useDigitalAssetsBridge();
  const {
    getContractListForAdmin,
    getSettingsFieldsListByAddress,
    getSettingsFieldsList,
    getFieldsList,
    getFieldsPropertiesList,
    getSettingsParamsListValues,
  } = useDigitalAssetsTTAPI();

  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const [settingsId, setSettingsId] = useState({ id: '', version: 0 });
  const [showParamsDialog, setShowParamsDialog] = useState(false);
  const [showResultDialog, setShowResultDialog] = useState(false);
  const [showSwitchNetDialog, setShowSwitchNetDialog] = useState(false);

  const [showCompileDialog, setShowCompileDialog] = useState(false);
  const [showServicesSettingsModal, setShowServicesSettingsModal] = useState(false);
  const [ignoreContracts, setIgnoreContracts] = useState('Audition.sol');
  const [showCompileResultDialog, setShowCompileResultDialog] = useState(false);
  const [compileResult, setCompileResult] = useState({ solc: '', errors: [], contracts: [] });
  const [showSelectContractVersionDialog, setShowSelectContractVersionDialog] = useState(false);
  const [contractNameToEdit, setContractNameToEdit] = useState('');
  const [contractName, setContractName] = useState('');

  const [netList, setNetList] = useState([]);
  const [currentNetID, setCurrentNetID] = useState(chainId);

  const [contractList, setContractList] = useState([]);
  const [contract, setContract] = useState({
    name: '',
    version: null,
    caption: '',
    balance: '-',
    abi: [],
    methodHash: '',
    func: '',
    funcType: '',
    address: '',
    inputs: [],
    bytecode: '',
    amount: 0n,
    params: {},
    errors: {},
    lists: {},
  });
  const [upgradeBtns, setUpgradeBtns] = useState([]);
  const [payableBtns, setPayableBtns] = useState([]);
  const [getterBtns, setGetterBtns] = useState([]);
  const [setterBtns, setSetterBtns] = useState([]);

  const [callContractResult, setCallContractResult] = useState(null);

  //----------------------------------------------------------------------

  const compile = async () => {
    let res;
    try {
      openAppLoader(0.5);

      res = await (REACT_APP_NODE_ENV !== 'local'
        ? TTAPIRequest(
            '/tt-block-chain/admin/compile_contracts',
            { ignore: ignoreContracts?.split(',') || [] },
            true,
            true
          )
        : DirectTTAPIRequest(
            'compileContracts',
            { ignore: ignoreContracts?.split(',') || [] },
            true
          ));
    } finally {
      closeAppLoader();
    }
    console.dir(res);

    setCompileResult({
      solc: res?.solc?.version,
      errors: res?.errors?.map((el) => ({
        message: el?.formattedMessage,
        type: el?.type,
        file: el?.sourceLocation?.file,
      })),
      contracts: res?.updateContracts,
    });

    setShowCompileResultDialog(true);
  };

  //----------------------------------------------------------------------

  /**
   * Подключение кошелька пользователя
   * @return {Promise<void>}
   */
  const walletConnect = async () => connect();

  /**
   * Обработчик переключения сети
   * @returns {Promise<void>}
   */
  const switchChainHandle = async () => {
    switchChain().then(() => {
      setCurrentNetID(chainId);
      setShowSwitchNetDialog(false);
    });
  };

  //----------------------------------------------------------------------

  /**
   * Полная очистка данные о выбранном контракте
   */
  const clearContractParamFull = () => {
    setContract((prev) => ({
      ...prev,
      name: '',
      version: null,
      caption: '',
      balance: '-',
      abi: [],
      methodHash: '',
      func: '',
      funcType: '',
      address: '',
      inputs: [],
      bytecode: '',
      amount: 0n,
    }));
  };

  /**
   * Частичная очистка данных о контракте (при переключении метода)
   */
  const clearContractParamFunc = () => {
    setContract((prev) => ({
      ...prev,
      methodHash: '',
      func: '',
      funcType: '',
      inputs: [],
      bytecode: '',
      amount: 0n,
      params: {},
      errors: {},
      lists: {},
    }));
  };

  /**
   * Получение списка контрактов верхнего уровня
   * @returns {Promise<void>}
   */
  const getContractListHandler = async () => {
    openAppLoader(0.5);

    clearContractParamFull();
    setContractList([]); // Очищаем список контрактов на время выполнения запроса

    try {
      /** @type {ContractListForAdminType[]} */
      const res = (await getContractListForAdmin(hex2num(chainId), false)) ?? [];
      const arr = [];
      res.forEach((el) => {
        arr.push({
          id: el?.name,
          ord: el?.ord ?? 0,
          name: el?.name || '',
          version: parseInt(el?.version, 10),
          caption: el?.caption || '',
          description: el?.description || '',
          versions: el?.versions ?? [],
          addresses: el?.addresses ?? [],
          bByteCode: !!el?.b_bytecode,
        });
      });

      setContractList(arr);
    } finally {
      closeAppLoader();
    }
  };

  /**
   * Обработчик смены адреса контракта
   * @param {Event} evnt
   * @returns {Promise<void>}
   */
  const contractAddressChangeHandler = async (addr) => {
    setContract((prev) => ({ ...prev, address: addr }));

    if (isAddress(addr)) {
      openAppLoader(0.5);
      try {
        // Получаем все данные в параллели
        const arr = await Promise.all([
          getBalance(`0x${addr}`), // Баланс контракта
          getSettingsFieldsListByAddress(addr, hex2num(chainId)), // Методы контракта
          getContractByAddress(addr, chainId, true),
        ]);

        // Обработка полученных данных
        let balance = parseFloat(arr[0]);
        balance = Number.isNaN(balance) ? '-' : balance.toString();
        const contractDetail = arr[1] ?? [];
        const abi = arr[2]?.abi ?? [];

        setContract((prev) => ({
          ...prev,
          abi: abi,
          balance: parseFloat(balance ?? 0).toString(),
          methods: contractDetail,
        }));

        setPayableBtns(contractDetail.filter((el) => el.tt_type === 3 && !!el.visible));
        setUpgradeBtns(contractDetail.filter((el) => el.tt_type === 2 && !!el.visible));
        setSetterBtns(contractDetail.filter((el) => el.tt_type === 4 && !!el.visible));
        setGetterBtns(contractDetail.filter((el) => el.tt_type === 5 && !!el.visible));
      } finally {
        closeAppLoader();
      }
    } else {
      setContract((prev) => ({ ...prev, address: addr, balance: '-' }));
      setUpgradeBtns([]);
      setPayableBtns([]);
      setSetterBtns([]);
      setGetterBtns([]);
    }
  };

  //-----------------------------

  const strValidation = (val, field) => val !== null && val !== undefined;
  const boolValidation = (val, field) => val !== null && val !== undefined;
  const addressValidation = (val, field) => isAddress(val);
  const intValidation = (val, field) => {
    try {
      if (val !== '') {
        toBN(val);
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  };
  const uintValidation = (val, field) => {
    try {
      if (val !== '') {
        val = toBN(val);
        return val >= 0;
      }
      return false;
    } catch (err) {
      return false;
    }
  };
  const floatValidation = (val, field) => /^\d+(?:\.\d*)?\s*$/.test(String(val));
  const hexValidation = (val, field) => {
    switch (field.type) {
      case 'bytes8':
        return /^0x(?:[a-fA-F0-9]{2}){8}$/gim.test(val);
      case 'bytes16':
        return /^0x(?:[a-fA-F0-9]{2}){16}$/gim.test(val);
      case 'bytes32':
        return /^0x(?:[a-fA-F0-9]{2}){32}$/gim.test(val);
      default:
        return /^0x([a-fA-F0-9]{2})*$/gim.test(val);
    }
  };
  const dateValidation = (val, field) => !Number.isNaN(new Date(val).getTime());
  const timeValidation = (val, field) => /^[0-2]?\d:[0-5]\d$/gim.test(val);
  const dateTimeValidation = (val, field) => !Number.isNaN(new Date(val).getTime());

  /**
   * Валидатор
   * @param {Object} param
   * @param {*} val
   * @returns {String}
   */
  const fieldValidation = (param, val) => {
    const type = param?.type ?? '';
    const ttType = param?.tt_type ?? '';

    switch (ttType) {
      case 'address':
      case 'address_list':
        if (type.substring(type.length - 2) === '[]') {
          const arr = (val ?? '')?.split(',') || [];
          for (let i = 0; i < arr?.length; i++) {
            if (!addressValidation(arr[i])) {
              return 'Invalid value. Must be address in format "0x0000...00" or "0000...00"';
            }
          }
        } else if (!addressValidation(val, param)) {
          return 'Invalid value. Must be address in format "0x0000...00" or "0000...00"';
        }
        break;

      case 'int':
      case 'int_list':
        if (type.substring(type.length - 2) === '[]') {
          const arr = (val ?? '')?.split(',') || [];
          for (let i = 0; i < arr?.length; i++) {
            if (!intValidation(arr[i], param)) {
              return 'Invalid value. Must be integer.';
            }
          }
        } else if (!intValidation(val, param)) {
          return 'Invalid value. Must be a integer';
        }
        break;

      case 'uint':
      case 'uint_list':
        if (type.substring(type.length - 2) === '[]') {
          const arr = (val ?? '')?.split(',') || [];
          for (let i = 0; i < arr?.length; i++) {
            if (!uintValidation(arr[i], param)) {
              return 'Invalid value. Must be address in format "0x0000...00" or "0000...00"';
            }
          }
        } else if (!uintValidation(val, param)) {
          return 'Invalid value. Must be an integer greater than or equal to 0.';
        }
        break;

      case 'payment':
      case 'eth':
        if (type.substring(type.length - 2) === '[]') {
          const arr = (val ?? '')?.split(',') || [];
          for (let i = 0; i < arr?.length; i++) {
            if (!floatValidation(arr[i], param)) {
              return 'Invalid value. Must be an number greater than or equal to 0.';
            }
          }
        } else if (!floatValidation(val, param)) {
          return 'Invalid value. Must be a number and greater or equals 0';
        }
        break;

      case 'hex':
        if (type.substring(type.length - 2) === '[]') {
          const arr = (val ?? '')?.split(',') || [];
          for (let i = 0; i < arr?.length; i++) {
            if (!hexValidation(arr[i], param)) {
              return 'Invalid value. Must be a HEX string in format "0x01234...FF".';
            }
          }
        } else if (!hexValidation(val, param)) {
          return 'Invalid value. Must be a HEX string in format "0x01234...FF".';
        }
        break;

      case 'string':
      case 'string_list':
        if (type.substring(type.length - 2) === '[]') {
          const arr = (val ?? '')?.split(',') || [];
          for (let i = 0; i < arr?.length; i++) {
            if (!strValidation(arr[i], param)) {
              return 'Invalid value.';
            }
          }
        } else if (!strValidation(val, param)) {
          return 'Invalid value.';
        }
        break;

      case 'bool':
        if (type.substring(type.length - 2) === '[]') {
          const arr = (val ?? '')?.split(',') || [];
          for (let i = 0; i < arr?.length; i++) {
            if (!boolValidation(arr[i], param)) {
              return 'Invalid value. Must be address in format "0x0000...00" or "0000...00"';
            }
          }
        } else if (!boolValidation(val, param)) {
          return 'Invalid value.';
        }
        break;

      case 'date':
        if (type.substring(type.length - 2) === '[]') {
          const arr = (val ?? '')?.split(',') || [];
          for (let i = 0; i < arr?.length; i++) {
            if (!dateValidation(arr[i], param)) {
              return 'Invalid value. Must be address in format "0x0000...00" or "0000...00"';
            }
          }
        } else if (!dateValidation(val, param)) {
          return 'Invalid value. Must be a date in format DD.MM.YYYY';
        }
        break;

      case 'time':
        if (type.substring(type.length - 2) === '[]') {
          const arr = (val ?? '')?.split(',') || [];
          for (let i = 0; i < arr?.length; i++) {
            if (!timeValidation(arr[i], param)) {
              return 'Invalid value. Must be address in format "0x0000...00" or "0000...00"';
            }
          }
        } else if (!timeValidation(val, param)) {
          return 'Invalid value. Must be a time format HH:MI:SS';
        }
        break;

      case 'date_time':
        if (type.substring(type.length - 2) === '[]') {
          const arr = (val ?? '')?.split(',') || [];
          for (let i = 0; i < arr?.length; i++) {
            if (!dateTimeValidation(arr[i], param)) {
              return 'Invalid value. Must be address in format "0x0000...00" or "0000...00"';
            }
          }
        } else if (!dateTimeValidation(val, param)) {
          return 'Invalid value. Must be a date/time in format DD.MM.YYYY HH:MI:SS';
        }
        break;

      case 'tuple':
        const err = {};
        param?.tuple?.forEach((param) => {
          err[param.param] = fieldValidation(param, val[param.param]);
        });
        // TODO: Сделать рекурсивный обход объекта с проверкой пропертей
        return err;

      default:
        return `unknown type ${type}`;
    }

    return '';
  };

  /**
   * Трансформер значений
   * @param {Object} param
   * @param {*} val
   * @returns {*}
   */
  const fieldTransform = (param, val) => {
    const type = param?.type ?? '';
    const ttType = param?.tt_type ?? '';

    switch (ttType) {
      case 'address':
      case 'address_list':
        if (type.substring(type.length - 2) === '[]') {
          return (val ?? '')?.split(',') || [];
        }
        return val;

      // eslint-disable-next-line no-fallthrough
      case 'int':
      case 'int_list':
      case 'uint':
      case 'uint_list':
        if (type.substring(type.length - 2) === '[]') {
          return (val ?? '')?.split(',') || []; // .map((el) => toBN(el)); // TODO: невозможно заранее из-за ошибки сериализации в JSON на стороне React'а
        }
        return val; // toBN(val);  // TODO: невозможно заранее из-за ошибки сериализации в JSON на стороне React'а

      case 'payment':
      case 'eth':
        if (type.substring(type.length - 2) === '[]') {
          return ((val ?? '')?.split(',') || []).map((el) => toWei(el));
        }
        return toWei(val);

      case 'hex':
        if (type.substring(type.length - 2) === '[]') {
          return val.map((el) => (param.tt_type === 'string' ? strToHex(el) : el))?.join(',');
        }
        return param.tt_type === 'string' ? strToHex(val) : val;

      case 'string':
      case 'string_list':
        if (type.substring(type.length - 2) === '[]') {
          return ((val ?? '').split(',') || []).map((el) =>
            type.startsWith('bytes') ? (fromAscii(el) ?? '') : (el ?? '')
          );
        }

        return type.startsWith('bytes') ? (fromAscii(val) ?? '') : (val ?? '');

      case 'bool':
        if (type.substring(type.length - 2) === '[]') {
          return ((val ?? '')?.split(',') || []).map((el) => !!el);
        }
        return !!val;

      case 'date':
        if (type.substring(type.length - 2) === '[]') {
          return ((val ?? '')?.split(',') || []).map((el) => dateTime2int(el));
        }
        return dateTime2int(val);

      case 'time':
        if (type.substring(type.length - 2) === '[]') {
          return ((val ?? '')?.split(',') || []).map((el) => dateTime2int(el));
        }
        return dateTime2int(val);

      case 'date_time':
        if (type.substring(type.length - 2) === '[]') {
          return ((val ?? '')?.split(',') || []).map((el) => dateTime2int(el));
        }
        return dateTime2int(val);

      case 'tuple':
        // TODO: Сделать рекурсивный обход объекта с проверкой пропертей
        return val;

      default:
        console.warn(`fieldTransform: unknown type ${type}`);
        return null;
    }
  };

  /**
   * Проверка наличие ошибок на форме
   * @param {Object} err
   * @returns {Boolean}
   */
  const checkHasErrors = (err) => {
    err = err ?? contract?.errors ?? {};
    let res = false;
    Object.keys(err).forEach((key) => {
      if (err[key]) {
        if (typeof err[key] === 'object') {
          res = res || checkHasErrors(err[key]);
        } else {
          res = res || !!err[key];
        }
      }
    });
    return res;
  };

  //-------------------------------

  /**
   * Диалог показа результата вызова метода
   * @param {*} res
   */
  const showResult = (res) => {
    if (typeof res === 'object') {
      // Получаем из ABI контракта вызываемый метод
      const method = contract?.abi?.find((el) => el?.name === contract?.func);
      if (method) {
        const parseAnswer = (el, abi, name) => {
          if (abi) {
            const type = abi?.type;
            if (type.substring(type.length - 2, type.length) === '[]') {
              // Массив, проходимся по каждому элементу массива и рекурсивно вызываем для него обработку
              return el.map((item, ind) => ({
                ...parseAnswer(
                  item,
                  {
                    name: abi?.name,
                    type: type.substring(0, type.length - 2),
                    components: abi?.components,
                    internalType: abi?.internalType,
                  },
                  ind
                ),
              }));
            }

            if (type === 'tuple') {
              // Структура. Получаем её свойства и выводим их
              if (abi?.components) {
                const res = [];
                abi.components.forEach((item) => {
                  res.push({ ...parseAnswer(item?.name ? el[item?.name] : el, item) });
                });
                return res;
              }
              return { [`${abi?.name}`]: obj2json(el) };
            }

            if (typeof el === 'object') {
              return { [`${abi?.name}`]: String(el[abi?.name]) || obj2json(el) };
            }

            return { [`${abi?.name}`]: el?.toString() || String(el) };
          }
          return { [`${name || 'element'}`]: el?.toString() || String(el) };
        };

        let ans = {};
        // Метод найден. Получаем формат ответа

        (method?.outputs || [])?.forEach((param) => {
          ans = { ...ans, ...parseAnswer(param?.name ? res[param?.name] || res : res, param) };
        });

        setCallContractResult(ans);
      } else {
        setCallContractResult(
          JSON.parse(res, (key, value) => (typeof value === 'bigint' ? value.toString() : value), 2)
        );
      }
    } else {
      let tempRes;

      try {
        if (typeof res === 'bigint') {
          tempRes = JSON.stringify({ result: String(res) });
        } else if (typeof res === 'boolean') {
          tempRes = JSON.stringify({ result: res });
        } else {
          tempRes = JSON.stringify(JSON.parse(res));
        }
      } catch (e) {
        tempRes = JSON.stringify({ result: res });
      }

      setCallContractResult(
        JSON.parse(
          tempRes,
          (key, value) => (typeof value === 'bigint' ? value.toString() : value),
          2
        )
      );
    }

    setShowResultDialog(true);
  };

  /**
   * Подготовка и настройка поля
   * @param {ContractMethodParamsForAdminType} field
   * @param {Object}                           params
   * @param {Object}                           lists
   * @param {String}                           path
   * @returns {Promise<ContractMethodParamsForAdminType>}
   */
  const contractPrepareField = async (field, params, lists, path) => {
    const promises = [];
    field.path = path;
    const paramName = field?.property ?? field?.param;
    if (field) {
      switch (field.tt_type) {
        case 'address':
          if (field?.has_default_value) {
            if (field.default_value === 'account') {
              params[paramName] = account;
              // eslint-disable-next-line no-unsafe-optional-chaining
            } else if (field.default_value?.substring(field.default_value?.length - 4) === '.sol') {
              // Получаем адрес контракта
              const prms = getContractAddress(field.default_value).then(
                ((paramName) => (addr) => {
                  params[paramName] = addr ?? '';
                })(paramName)
              );
              promises.push(prms);
            } else {
              params[paramName] = field.default_value;
            }
          }
          break;

        case 'uint_list':
        case 'int_list':
        case 'string_list':
        case 'address_list':
          if (field.list_values) {
            if (!Object.prototype.hasOwnProperty.call(lists, field.list_values)) {
              lists[field.list_values] = []; // Пустой массив, как отметка, что уже запрашиваем справочник. В дальнейшем перезапишется реальным массивом значений
              const prms = getSettingsParamsListValues(field.list_values, false).then(
                ((field) => (res) => {
                  lists[field.list_values] = res ?? [];
                })(field)
              );
              promises.push(prms);
            }
          }
          if (field?.has_default_value) {
            params[paramName] = field.default_value;
          }
          break;

        case 'date':
          if (field?.has_default_value) {
            if (field.use_current_timestamp) {
              const d = new Date(new Date().toISOString().slice(0, 10));
              params[paramName] = dateTime2int(d);
            } else {
              params[paramName] = field.default_value;
            }
          } else {
            params[paramName] = null;
          }
          break;

        case 'time':
          if (field?.has_default_value) {
            if (field.use_current_timestamp) {
              let d = new Date();
              d = new Date(d.toTimeString());
              params[paramName] = dateTime2int(d);
            } else {
              params[paramName] = field.default_value;
            }
          } else {
            params[paramName] = null;
          }
          break;

        case 'date_time':
          if (field?.has_default_value) {
            if (field.use_current_timestamp) {
              const d = new Date();
              params[paramName] = dateTime2int(d);
            } else {
              params[paramName] = field.default_value;
            }
          } else {
            params[paramName] = null;
          }
          break;

        case 'tuple':
          params[field.param] = {};
          const prms = getFieldsPropertiesList(field.param, field.method_hash, false).then(
            ((field) => async (res) => {
              const promises = [];
              const fields = [];
              (res ?? []).forEach((field) => {
                promises.push(
                  contractPrepareField(
                    field,
                    params[paramName],
                    lists,
                    path ? `${path}.${paramName}` : paramName
                  ).then((field) => fields.push(field))
                );
              });
              await Promise.all(promises);
              field.tuple = fields.map((field) => {
                field.param = field?.property;
                return field;
              });
            })(field)
          );
          promises.push(prms);
          break;

        default:
          if (field?.has_default_value) {
            params[paramName] = field.default_value;
          } else {
            params[paramName] = null;
          }
          break;
      }
    }

    await Promise.all(promises);

    return field;
  };

  const prepareParametersForDialog = async (inputs, methodHash, method, type) => {
    const par = {};
    const err = {};
    const lists = {};

    if (inputs) {
      // Проходимся по всем input'ам и добираем необходимые значения справочников или адресов контрактов
      const arr = []; // Массив для хранения промисов что бы было понятно чего и когда ждать
      inputs.forEach((field) => {
        arr.push(contractPrepareField(field, par, lists, ''));
      });
      await Promise.all(arr);

      if (inputs.length > 0) {
        // Предварительно валидируем все поля
        inputs.forEach((el) => {
          err[el?.param] = fieldValidation(el, par[el?.param]);
        });
      }
    }

    setContract((prev) => ({
      ...prev,
      methodHash: methodHash,
      func: method,
      funcType: type,
      inputs: inputs,
      params: par,
      errors: err,
      lists: lists,
    }));

    return inputs;
  };

  /**
   * Построение и показ формы вызова метода
   * @param {ContractMethodsForAdminType} methodSettings
   * @returns {(function(*): Promise<void|undefined>)|*}
   */
  const contractFunc = (methodSettings) => async (evnt) => {
    if (isAddress(contract?.address)) {
      if (methodSettings?.method_hash) {
        if (contract?.abi) {
          // Получаем настройки вызываемых параметров
          /** @type {ContractMethodParamsForAdminType[]} */
          const inputs = await prepareParametersForDialog(
            (await getFieldsList(methodSettings?.method_hash)) ?? [],
            methodSettings?.method_hash,
            methodSettings?.method,
            methodSettings?.type
          );

          //          if (inputs?.length > 0) {
          // Есть параметры - показываем форму с ними
          setShowParamsDialog(true);
          // } else {
          //   // Нет параметров - сразу вызываем метод контракта
          //   // eslint-disable-next-line no-use-before-define
          //   window.setTimeout(onSubmitHandler, 100);
          // }
        } else {
          showError('Not fount method in contract ABI');
        }
      } else {
        showError('Not selected contract method from call');
      }
    } else {
      showError('Not selected contract address from call');
    }
  };

  const deployFunc = (contractName, contractVersion) => async (evnt) => {
    openAppLoader(0.5);
    contractName = contractName || contract?.name;
    // contractVersion = contractVersion || contract?.version;
    if (contractName /* && contractVersion > 0 && chainId */) {
      let bytecode;
      let abi;
      let version;
      try {
        const res = await getContractsDetail(contractName, null, null, true, true);
        bytecode = res?.bytecode;
        abi = res?.abi;
        version = res?.version;
      } catch (err) {
        showError(err.message ?? err);
        return null;
      } finally {
        closeAppLoader();
      }

      if (bytecode && abi && version) {
        // Получаем все методы контракта
        const methods = await getSettingsFieldsList(contractName, contractVersion, true);
        // Ищем среди методов контракта метод "constructor"
        const constructorMethod = (methods || []).find((el) => el?.tt_type === 1);
        if (constructorMethod) {
          // Получаем настройки вызываемых параметров
          /** @type {ContractMethodParamsForAdminType[]} */
          const inputs = await prepareParametersForDialog(
            (await getFieldsList(constructorMethod?.method_hash)) ?? [],
            constructorMethod?.method_hash,
            constructorMethod?.method,
            constructorMethod?.type
          );

          setContract((prev) => ({
            ...prev,
            bytecode: bytecode,
            abi: abi,
            version: version,
            func: 'constructor',
          }));

          // if (inputs?.length > 0) {
          //   // Есть параметры - показываем форму с ними
          setShowParamsDialog(true);
          // } else {
          //   // Нет параметров - сразу вызываем метод контракта
          //   // eslint-disable-next-line no-use-before-define
          //   window.setTimeout(onSubmitHandler, 100);
          // }
        } else {
          showError('Not fount "consctructor" method in contract ABI');
        }
      } else {
        showError(`Contract "${contract?.name}" is not compiled. (1)`);
      }
    } else {
      showError(`Contract for deploy not selected.`);
    }
  };

  /**
   * Отправка запроса в блокчейн
   * @returns {Promise<void>}
   */
  const onSubmitHandler = async () => {
    // получаем тип вызываемого метода
    setShowParamsDialog(false);

    // Собираем массив параметров
    const param = [];
    contract?.inputs
      // eslint-disable-next-line no-unsafe-optional-chaining
      .sort((a, b) => a?.ord - b?.ord)
      .forEach((el) => {
        param.push(contract?.params[el.param]);
      });

    openAppLoader(0.5);

    try {
      let res;

      if (contract?.func === 'constructor') {
        let transactionPayload = 0n;
        if (contract?.inputs?.find((el) => el?.param === 'transactionPayload')) {
          transactionPayload = param.pop();
        }

        const res = await deploy(
          contract?.abi,
          contract?.bytecode,
          param,
          true,
          transactionPayload || 0n
        );

        if (res) {
          showSuccess('Completed');

          if (res?.contractAddress) {
            const ans = await (REACT_APP_NODE_ENV !== 'local'
              ? TTAPIRequest(
                  '/tt-block-chain/admin/set_contract_detail',
                  {
                    name: contract?.name,
                    address: res?.contractAddress,
                    ver: contract?.version,
                    net: hex2num(chainId).toString(),
                    receipt: res,
                  },
                  false,
                  true
                )
              : DirectTTAPIRequest(
                  'setContractDetail',
                  {
                    name: contract?.name,
                    address: res?.contractAddress,
                    ver: contract?.version,
                    net: hex2num(chainId).toString(),
                    receipt: res,
                  },
                  false
                ));

            getContractListHandler().then();
          }
        }
      } else if (contract?.func === 'directTransfer') {
        await sendWithAddress(`0x${contract?.params?.address}`, contract?.params?.amount, true);
      } else {
        // eslint-disable-next-line default-case
        switch (contract?.funcType) {
          case 'view':
          case 'pure':
            // Собираем массив параметров
            res = await callContractMethodWithAddressABI(
              contract?.address,
              contract?.abi,
              contract?.func,
              param,
              false
            );
            console.log('result');
            console.dir(res);
            return res !== undefined ? showResult(res) : false;

          case 'nonpayable':
            res = await sendContractMethodWithAddressABI(
              contract?.address,
              contract?.abi,
              contract?.func,
              param,
              false,
              0
            );

            console.log('result');
            console.dir(res);
            if (res) {
              showSuccess('Completed');
            }
            return;

          case 'payable':
            const transactionPayload = param.pop();
            res = await sendContractMethodWithAddressABI(
              contract?.address,
              contract?.abi,
              contract?.func,
              param,
              false,
              transactionPayload ?? 0n
            );

            console.log('result');
            console.dir(res);
            if (res) {
              showSuccess('Completed');
            }
            break;
        }
      }
    } finally {
      closeAppLoader();
    }
  };

  const contracts = contractList.map((item, ind) => ({
    contract: item,
    key: ind,
    label: <div className={styles.accrdionTitle}>{item.caption || item.name}</div>,
    extra: (
      <Button
        type="icon"
        onClick={(evnt) => {
          evnt.stopPropagation();
          setContractNameToEdit(item?.id);
          setShowSelectContractVersionDialog(true);
          setContractName(item.caption || item.name || '');
        }}
      >
        <SettingsIcon style={{ width: 20, height: 20 }} />
      </Button>
    ),
    children: (
      <div className={clsx(styles.accordionContent, 'accordionContent')}>
        {/* Описание контракта */}
        <div className={clsx(styles.contentDescription, 'contentDescription')}>
          {item.description}
        </div>

        {/* Выбор адреса, баланс, кнопка деплоя */}
        <div className={clsx(styles.contentInfoContainer, 'contentInfoContainer')}>
          {/* Выбор адреса */}
          <div className={clsx(styles.contentInfoItem, 'contentInfoItem')}>
            <Select
              label="Select contract address"
              value={contract?.address || undefined}
              onChange={contractAddressChangeHandler}
              style={{ maxWidth: '500px', width: '100%' }}
              options={item.addresses
                .filter((el) => el)
                .map((el, ind) => ({
                  value: el,
                  label: `(${item?.versions[ind]}) - ${el}`,
                }))}
            />
          </div>

          {/* баланс */}
          <div className={clsx(styles.contentInfoItem, 'contentInfoItem')}>
            <div className={clsx(styles.contentInfoTitle, 'contentInfoTitle')}>Balance:</div>
            <div className={clsx(styles.contentInfoDescription, 'contentInfoDescription')}>
              {contract?.balance} {bridge?.symbol}
            </div>
          </div>

          {/* кнопка деплоя */}
          <div
            className={clsx(styles.contentInfoItem, 'contentInfoItem')}
            style={{ textAlign: 'right', width: 'unset' }}
          >
            {item?.bByteCode ? (
              <Button
                type="primary"
                key={`${item?.id}_deploy`}
                className={clsx(styles.contentButton, 'contentButton')}
                onClick={deployFunc(item?.name, item?.version)}
              >
                Deploy
              </Button>
            ) : (
              ''
            )}
          </div>
        </div>

        {/* Кнопки апгрейда */}
        {upgradeBtns?.length > 0 ? (
          <div className={clsx(styles.outlineButtonGroups, 'outlineButtonGroups')}>
            {upgradeBtns.map((button) => (
              <Button
                key={`${item?.id}_btn_${button.method}`}
                disabled={!isAddress(contract?.address)}
                style={{ borderColor: '#d32f2f !important' }}
                className={clsx(styles.warningButton, 'warningButton')}
                onClick={contractFunc(button)}
              >
                <WarningIcon
                  className="icon-red-fill"
                  style={{ width: '20px', height: '20px', flexShrink: 0 }}
                />
                <div className={clsx(styles.contentButtonText, 'contentButtonText')}>
                  {button.caption}
                </div>
              </Button>
            ))}
          </div>
        ) : (
          ''
        )}

        {/* Платёжные кнопки */}
        {payableBtns?.length > 0 ? (
          <div
            className={clsx(styles.outlineButtonGroups, 'outlineButtonGroups')}
            style={{ display: payableBtns?.length > 0 ? '' : 'none' }}
          >
            {payableBtns.map((button) => (
              <Button
                key={`${item?.id}_btn_${button.method}`}
                disabled={!isAddress(contract?.address)}
                className={clsx(styles.warningButton, 'warningButton')}
                onClick={contractFunc(button)}
              >
                <WalletIcon
                  className="icon-red-fill"
                  style={{ width: '20px', height: '20px', flexShrink: 0 }}
                />
                <div className={clsx(styles.contentButtonText, 'contentButtonText')}>
                  {button.caption}
                </div>
              </Button>
            ))}
          </div>
        ) : (
          ''
        )}

        {/* Сеттеры */}
        {setterBtns?.length > 0 ? (
          <div
            className={clsx(styles.buttonGroups, 'buttonGroups')}
            style={{ display: setterBtns?.length > 0 ? '' : 'none' }}
          >
            {setterBtns.map((button) => (
              <Button
                key={`${item?.id}_btn_${button.method}`}
                disabled={!isAddress(contract?.address)}
                className={clsx(styles.contentButton, 'contentButton')}
                onClick={contractFunc(button)}
              >
                <EditIcon
                  className="icon-blue-fill"
                  style={{ width: '20px', height: '20px', flexShrink: 0 }}
                />
                <div className={clsx(styles.warningButtonText, 'warningButtonText')}>
                  {button.caption}
                </div>
              </Button>
            ))}
          </div>
        ) : (
          ''
        )}

        {/* Геттеры */}
        {getterBtns?.length > 0 ? (
          <div
            className={clsx(styles.buttonGroups, 'buttonGroups')}
            style={{ display: getterBtns?.length > 0 ? '' : 'none' }}
          >
            {getterBtns.map((button) => (
              <Button
                key={`${item?.id}_btn_${button.method}`}
                disabled={!isAddress(contract?.address)}
                className={clsx(styles.contentButton, 'contentButton')}
                onClick={contractFunc(button)}
              >
                <VisibleIcon
                  className="icon-blue-fill"
                  style={{ width: '20px', height: '20px', flexShrink: 0 }}
                />
                <div className={clsx(styles.warningButtonText, 'warningButtonText')}>
                  {button.caption}
                </div>
              </Button>
            ))}
          </div>
        ) : (
          ''
        )}
      </div>
    ),
  }));

  /**
   * Обработчик выбора конкретного контракта в аккордеоне
   * @param {number} key
   * @returns {(function(*, *): void)|*}
   */
  const selectedContractChangeHandler = (key) => {
    clearContractParamFull();
    const item = contracts.find((item) => `${item.key}` === `${key[0] || ''}`);

    setContract((prev) => ({
      ...prev,
      name: item?.contract?.name,
      version: item?.contract?.version,
      caption: item?.contract?.caption || item?.contract?.name || '',
    }));
    setUpgradeBtns([]);
    setPayableBtns([]);
    setSetterBtns([]);
    setGetterBtns([]);
  };

  /**
   * Отмена запроса
   * @returns {Promise<void>}
   */
  const onCancelHandler = async (evnt) => {
    clearContractParamFunc();
    setShowParamsDialog(false);
  };

  /**
   * Закрытие диалогового окна
   * @returns {Promise<void>}
   */
  const onCloseHandler = async (evnt) => {
    setShowResultDialog(false);
    clearContractParamFunc();
  };

  //-------------------------------

  useEffect(() => {
    if (status === 'connected' && account) {
      const arr = [];
      if (bridges && bridges.length > 0) {
        bridges.forEach((el) => {
          arr.push({
            bridge: el,
            id: `0x${el.chain_id.toString(16)}`,
            caption: el.name,
          });
        });
      } else {
        arr.push({
          bridge: chainSettings,
          id: `0x${chainSettings.chainId.toString(16)}`,
          caption: chainSettings.chainName,
        });
      }
      setNetList(arr);
    }
  }, [status, bridges]);

  useEffect(() => {
    if (status === 'connected' && account) {
      // Меняем выбранную сеть в меню
      setCurrentNetID(chainId);
      // Перезапрашиваем список контрактов
      getContractListHandler().then();
    }
  }, [status, chainId]);

  useEffect(() => {
    if (!showSettingsDialog) {
      setSettingsId({ id: '', version: 0 });
    }
  }, [status, showSettingsDialog]);

  useEffect(() => {
    if (status === 'connected' && account) {
      if (chainId && chainId !== chainSettings.chainId) {
        setShowSwitchNetDialog(true);
      }
    }
  }, [status, currentNetID]);
  console.log(5, netList);
  return (
    <Block
      className="adminPageContentBox"
      style={{ background: 'none', boxShadow: 'none', padding: 0 }}
    >
      {status === 'unavailable' ? (
        <div className={styles.connectContainer}>
          <div>
            To proceed, please install the MetaMask plugin in your browser and connect your wallet
            to TransparenTerra.
          </div>
          <div>
            <Button type="default" onClick={() => window.open('https://metamask.io')}>
              Install MetaMask Wallet
            </Button>
          </div>
        </div>
      ) : status === 'initializing' ? ( // Устанавливаем соединение с MetaMask
        <div className={styles.connectContainer}>
          <div>Synchronisation with MetaMask ongoing...</div>
        </div>
      ) : status === 'connecting' ? ( // Соединение с MetaMask'ом
        <div className={styles.connectContainer}>
          <div>Connecting to MetaMask...</div>
        </div>
      ) : status === 'connected' ? (
        account ? (
          <>
            {/* Кошелёк подключен */}
            <div className={clsx(styles.pageContentBox, 'pageContentBox')}>
              {/* Меню выбора сети (получаем из активных мостов) */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  className={clsx(styles.selectChainTitle)}
                  style={{ fontWeight: 'bold', width: '250px' }}
                >
                  Select blockchain NET:
                </div>

                <Tabs
                  className={styles.tabs}
                  items={netList.map((item) => ({
                    bridge: item.bridge,
                    key: item.id,
                    label: item.caption,
                    children: null,
                  }))}
                  activeKey={chainId}
                  onChange={(key) => {
                    const brdg = netList.find((item) => item.id === key)?.bridge;
                    if (!brdg) {
                      openNotification({ message: `Can't change network now`, type: 'warning' });
                      return;
                    }

                    const settings = {
                      chainId: `0x${brdg?.chain_id?.toString(16)}`,
                      chainName: brdg?.name,
                      rpcUrls: [
                        `http${brdg?.ssl ? 's' : ''}://${brdg?.host}${brdg?.port > 0 ? `:${brdg?.port}` : ''}${
                          brdg?.path
                        }`,
                      ],
                      nativeCurrency: {
                        name: brdg?.symbol,
                        symbol: brdg?.symbol,
                        decimals: brdg?.digits,
                      },
                    };

                    switchChain(settings);
                  }}
                  animated={false}
                />
              </div>

              {/* Текущий адрес и кнопка компиляции */}
              <div className={clsx(styles.rowMain)}>
                <div className={clsx(styles.contentInfoContainer, 'contentInfoContainer')}>
                  <div className={clsx(styles.row)}>
                    <div className={clsx(styles.contentInfoTitle, 'contentInfoTitle')}>
                      Current Address:
                    </div>
                    <div className={clsx(styles.contentInfoDescription, 'contentInfoDescription')}>
                      {account || '-'}
                    </div>
                  </div>
                </div>

                <Space>
                  <Button type="default" onClick={() => setShowServicesSettingsModal(true)}>
                    <SettingsIcon style={{ width: 20, height: 20 }} />
                  </Button>
                  <Button
                    type="primary"
                    className={clsx(styles.compileButton, 'compileButton')}
                    style={{ display: chainId !== chainSettings.chainId ? 'none' : '' }}
                    onClick={() => setShowCompileDialog(true)}
                  >
                    Compile
                  </Button>
                </Space>
                {/*
                <Button
                  type="primary"
                  className={clsx(styles.compileButton, 'compileButton')}
                  style={{ display: chainId !== chainSettings.chainId ? 'none' : '' }}
                  onClick={directTransferFunc}
                >
                  Direct transfer
                </Button>
                */}
              </div>

              {/* Аккордеон с контрактами */}
              <div className={clsx(styles.contentContainer, 'contentContainer')}>
                <Collapse
                  onChange={(key) => selectedContractChangeHandler(key)}
                  items={contracts}
                  appearance="expanded"
                  expandIcon={ExpandIcon}
                  accordion
                />
              </div>
            </div>

            <ServicesSettingsModal
              open={showServicesSettingsModal}
              setOpen={setShowServicesSettingsModal}
            />

            {/* Форма запроса параметров вызова функции контракта */}
            <ContractFunctionRequest
              showParamsDialog={showParamsDialog}
              onCancelHandler={onCancelHandler}
              contract={contract}
              onSubmitHandler={onSubmitHandler}
              checkHasErrors={checkHasErrors}
              setContract={setContract}
              fieldValidation={fieldValidation}
              fieldTransform={fieldTransform}
            />

            {/* Форма отображения результата запроса функции контракта */}
            <ContractFunctionResult
              showResultDialog={showResultDialog}
              onCloseHandler={onCloseHandler}
              contract={contract}
              callContractResult={callContractResult}
            />

            <ChangeNetworkModal
              showSwitchNetDialog={showSwitchNetDialog}
              setShowSwitchNetDialog={setShowSwitchNetDialog}
              chainId={chainId}
              setCurrentNetID={setCurrentNetID}
              switchChainHandle={switchChainHandle}
            />

            {/* Форма запроса на компиляцию контрактов */}
            <ContractCompileRequestModal
              showCompileDialog={showCompileDialog}
              setShowCompileDialog={setShowCompileDialog}
              ignoreContracts={ignoreContracts}
              compile={compile}
            />

            {/* Форма показа результатов компиляцию контрактов */}
            <ContractCompileResultModal
              showCompileResultDialog={showCompileResultDialog}
              setShowCompileResultDialog={setShowCompileResultDialog}
              compileResult={compileResult}
            />

            {/* Окно выбора версии контракта перед настройкой */}
            <SelectContractVersionModal
              showSelectContractVersionDialog={showSelectContractVersionDialog}
              setShowSelectContractVersionDialog={setShowSelectContractVersionDialog}
              contractName={contractName}
              contractNameToEdit={contractNameToEdit}
              setSettingsId={setSettingsId}
              setShowSettingsDialog={setShowSettingsDialog}
              contractList={contractList}
            />

            <SettingsModal
              open={showSettingsDialog}
              setOpen={setShowSettingsDialog}
              onClose={contractAddressChangeHandler}
              id={settingsId}
              name={contractName}
            />
          </>
        ) : (
          <>
            {/* Необходимо разблокировать МетаМаск или подключить кошелёк */}
            <div className={styles.connectContainer}>
              <div>
                <p className={clsx(styles.p, 'p')}>
                  To move forward, please connect or create your MetaMask wallet
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type="primary"
                  startIcon={
                    <AddIcon
                      className="icon-white-fill"
                      style={{ width: 22, height: 22, display: 'flex' }}
                    />
                  }
                  onClick={walletConnect}
                >
                  Connect wallet
                </Button>
              </div>
            </div>
          </>
        )
      ) : (
        // Неизвестное состояние
        <div className={styles.connectContainer}>
          <div>To move forward, please connect or create your MetaMask wallet</div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              startIcon={
                <AddIcon
                  className="icon-white-fill"
                  style={{ width: 22, height: 22, display: 'flex' }}
                />
              }
              onClick={walletConnect}
            >
              Connect wallet
            </Button>
          </div>
        </div>
      )}
    </Block>
  );
};

export default FceAdminPage;
