import React, { useEffect } from 'react';
import clsx from 'clsx';
import TransactionHistoryTable from './TransactionHistoryTable';
// import TransactionHistoryChart from './TransactionHistoryChart';

import styles from './styles.module.scss';

const FCEExplorer = (props) => {
  const { className, style, forceReload, setForceReload } = props;

  useEffect(() => {
    console.log(`FCEExplorer: forceReload: ${forceReload}`);
  }, [forceReload]);

  return (
    <div className={clsx(styles.Explorer, 'Explorer', className)} style={{ ...style }}>
      <div className={clsx(styles.row3, 'row3')}>
        <div style={{ overflow: 'auto' }}>
          <TransactionHistoryTable forceReload={forceReload} setForceReload={setForceReload} />
        </div>
      </div>
    </div>
  );
};

export default FCEExplorer;
