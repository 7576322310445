import { openNotification } from 'tt-ui-lib/core';

const {
  REACT_APP_CHAIN_ID,
  REACT_APP_CHAIN_NAME,
  REACT_APP_CHAIN_TOKEN,
  REACT_APP_CHAIN_RPC_URL,
  REACT_APP_CHAIN_BLOCKSCAN_URL,
} = process.env;

export const chainSettings = {
  chainId: REACT_APP_CHAIN_ID,
  chainName: REACT_APP_CHAIN_NAME,
  rpcUrls: [REACT_APP_CHAIN_RPC_URL],
  nativeCurrency: {
    name: REACT_APP_CHAIN_TOKEN,
    symbol: REACT_APP_CHAIN_TOKEN,
    decimals: 18,
  },
  blockExplorerUrls: REACT_APP_CHAIN_BLOCKSCAN_URL ? [REACT_APP_CHAIN_BLOCKSCAN_URL] : null,
  iconUrls: [`${REACT_APP_CHAIN_RPC_URL}/img/logo.svg`],
};

//-----------------------------

let standByCounter = 0;
export const showStandby = () => {
  try {
    standByCounter++;

    const metamaskComponentLoader = document.getElementById('MetamaskComponentLoader');
    const loader = document.getElementById('DALoader');

    if (metamaskComponentLoader) {
      metamaskComponentLoader.style.opacity = '0.5';
      metamaskComponentLoader.style.visibility = '';
      metamaskComponentLoader.style.display = '';
    } else if (loader) {
      loader.style.opacity = '0.5';
      loader.style.visibility = '';
      loader.style.display = '';
    }
  } catch (err) {
    // hide error
  }
};

export const hideStandby = (force) => {
  try {
    standByCounter--;
    if (force) {
      standByCounter = 0;
    }

    const metamaskComponentLoader = document.getElementById('MetamaskComponentLoader');
    const loader = document.getElementById('DALoader');

    if (metamaskComponentLoader && !standByCounter) {
      metamaskComponentLoader.style.opacity = '0';
      metamaskComponentLoader.style.visibility = 'none';
      metamaskComponentLoader.style.display = 'none';
    } else if (loader && !standByCounter) {
      loader.style.opacity = '0';
      loader.style.visibility = 'none';
      loader.style.display = 'none';
    }
  } catch (err) {
    // hide error
  }
};

//-------------------

export const showError = (msg) =>
  openNotification({
    message: msg,
    type: 'error',
  });

export const showWarn = (msg) =>
  openNotification({
    message: msg,
    type: 'warning',
  });

export const showInfo = (msg) =>
  openNotification({
    message: msg,
    type: 'info',
  });

export const showSuccess = (msg) =>
  openNotification({
    message: msg,
    type: 'success',
  });
