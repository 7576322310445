import React, { useEffect } from 'react';
import clsx from 'clsx';
import FCEExplorer from '../FCEExplorer';
import { useDigitalAssets, useDigitalAssetsWallet } from 'modules/tt-digital-assets-provider';
import Holders from '../Holders';
import TokenizationAssets from '../TokenizationAssets';
import Bridge from '../Bridge';
import { useSelectedContext } from '../../../context/contextSelector';

const CommonBlock = (props) => {
  const {
    showedTab,
    setShowedTab,
    setShowSwitchDialog,
    setToNet,
    forceReload,
    setForceReload,
    className,
    style,
  } = props;

  const { user, isIndividual } = useSelectedContext((state) => state.user);
  const { chainSettings, status, chainId } = useDigitalAssets();
  const { registerWalletState } = useDigitalAssetsWallet();

  const renderBlockBridgeContent = () => {
    if (showedTab.startsWith('bridge')) {
      return (
        <Bridge
          className={clsx(className)}
          style={style}
          chainSettings={chainSettings}
          showedTab={showedTab}
          setShowedTab={setShowedTab}
          setShowSwitchDialog={setShowSwitchDialog}
          setToNet={setToNet}
          forceReload={forceReload}
          setForceReload={setForceReload}
        />
      );
    }

    switch (showedTab) {
      case 'explorer.transfer':
        return (
          <FCEExplorer
            chainSettings={chainSettings}
            forceReload={forceReload}
            setForceReload={setForceReload}
            className={clsx(className)}
            style={style}
          />
        );
      case 'explorer.holders':
        return (
          <Holders
            chainSettings={chainSettings}
            forceReload={forceReload}
            setForceReload={setForceReload}
            className={clsx(className)}
            style={style}
          />
        );
      default:
        return `Unknown auth menu item "${showedTab}"`;
    }
  };

  const renderOtherBlockContent = () => {
    switch (showedTab) {
      case 'tokens':
        return (
          <TokenizationAssets
            chainSettings={chainSettings}
            forceReload={forceReload}
            setForceReload={setForceReload}
            className={clsx(className)}
            style={style}
          />
        );
      case 'explorer.transfer':
        return (
          <FCEExplorer chainSettings={chainSettings} className={clsx(className)} style={style} />
        );
      case 'explorer.holders':
        return <Holders chainSettings={chainSettings} className={clsx(className)} style={style} />;
      default:
        return `Unknown wo auth menu item "${showedTab}"`;
    }
  };

  useEffect(() => {
    console.log(`CommonBlock: forceReload: ${forceReload}`);
  }, [forceReload]);

  return (isIndividual &&
    status === 'connected' &&
    user?.kyc === 'success' &&
    chainId === chainSettings?.chainId &&
    registerWalletState === 2) ||
    showedTab.startsWith('bridge') ? (
    <>{renderBlockBridgeContent()}</>
  ) : (
    <>{renderOtherBlockContent()}</>
  );
};

export default CommonBlock;
