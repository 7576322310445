import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { Block, Input, Select, Table } from 'tt-ui-lib/core';
import { SearchIcon } from 'tt-ui-lib/icons';

import { useDigitalAssetsTTAPI } from 'modules/tt-digital-assets-provider';

import styles from './FceContractsPage.module.scss';

const FceContractsPage = () => {
  const { getContractList } = useDigitalAssetsTTAPI();

  const [loading, setLoading] = useState(false);
  const [dataRows, setDataRows] = useState([]);
  const [totalItems, setTotalItems] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Contract name',
      width: 250,
    },
    {
      key: 'version',
      dataIndex: 'version',
      title: 'Version',
      width: 70,
    },
    {
      key: 'net_name',
      dataIndex: 'net_name',
      title: 'Net name',
      width: 260,
    },
    {
      key: 'address',
      dataIndex: 'address',
      title: 'Address',
      width: 400,
    },
    {
      key: 'balance',
      dataIndex: 'balance',
      title: 'Balance',
      width: 198,
    },
    {
      key: 'deploy_time',
      dataIndex: 'deploy_time',
      title: 'Deploy time',
      width: 177,
    },
  ];

  const [filter, setFilter] = useState({
    search: '',
    netID: 0,
    orderBy: 0,
  });
  const [netsNameForFilter, setNetsNameForFilter] = useState([]);

  const [orderByList, setOrderByList] = useState([
    {
      value: 0,
      name: 'Contract name, Version, Deploy time',
    },
  ]);

  const getContracts = async () => {
    const res = await getContractList(undefined, true);

    // Получаем список уникальных сетей для возможной фильтрации
    const nets = [{ value: 0, name: 'All networks' }];
    for (let i = 0; i < res.length; i++) {
      if (!nets.find((item) => item?.value === res[i].net_id)) {
        nets.push({ value: res[i].net_id, name: res[i].net_name });
      }
    }
    setNetsNameForFilter(nets);

    return res;
  };

  const filterContracts = async () => {
    setLoading(true);
    try {
      const data =
        ((await getContracts()) ?? [])?.filter(
          (el) =>
            (!filter?.search ||
              el?.name?.toUpperCase().indexOf(filter?.search.toUpperCase()) > -1) &&
            (filter?.netID === 0 || el?.net_id === filter?.netID)
        ) ?? [];

      const pgCnt = Math.ceil((data ?? []).length / pageSize);
      setTotalItems(Math.ceil((data ?? []).length));

      let pgN = currentPage;

      if (pgN > pgCnt) {
        setCurrentPage(pgCnt);
        pgN = pgCnt;
      }
      if (pgN < 1) {
        setCurrentPage(1);
        pgN = 1;
      }

      const res = [];
      for (let i = pageSize * (pgN - 1); i < pageSize * pgN && i < data.length; i++) {
        res.push(data[i]);
      }

      setDataRows(res);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loading) {
      filterContracts().then();
    }
  }, [filter, currentPage]);

  return (
    <Block className="adminPageContentBox">
      <div style={{ width: '100%' }}>
        <div className={styles.filters}>
          {/* Фильтр по имени контракта */}
          <div style={{ width: '300px' }}>
            <Input
              value={filter?.search}
              label="Search"
              onChange={(evnt) => setFilter({ ...filter, search: evnt?.target?.value })}
              prefix={<SearchIcon style={{ width: 20, height: 20, flexShrink: 0 }} />}
            />
          </div>

          {/* Фильтр по Сети развёртывания контракта */}
          <div style={{ width: '300px' }}>
            <Select
              style={{ width: '100%' }}
              label="Net Name"
              value={filter?.netID}
              onChange={(value) => setFilter({ ...filter, netID: value })}
              options={netsNameForFilter.map((el) => ({ value: el.value, label: el.name }))}
            />
          </div>

          {/* Выбор полей для сортировки */}
          <div style={{ width: '300px' }}>
            <Select
              style={{ width: '100%' }}
              label="Sort by"
              value={filter?.orderBy}
              onChange={(value) => setFilter({ ...filter, orderBy: value })}
              options={orderByList.map((el) => ({ value: el.value, label: el.name }))}
            />
          </div>
        </div>

        <div className={clsx(styles.tableContent)}>
          <Table
            columns={columns}
            data={dataRows || []}
            loading={loading}
            scroll={{ x: 870, y: '48vh' }}
            pagination={{
              position: ['bottomCenter'],
              current: currentPage,
              pageSize: pageSize,
              total: totalItems,
              responsive: true,
              showSizeChanger: false,
              hideOnSinglePage: true,
              onChange: (page) => setCurrentPage(parseInt(page, 10)),
            }}
          />
        </div>
      </div>
    </Block>
  );
};

export default FceContractsPage;
