/* eslint-disable react/no-unknown-property */
/* eslint-disable no-underscore-dangle */

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Block, Table } from 'tt-ui-lib/core';
import {
  useDigitalAssets,
  useDigitalAssetsAPI,
  useDigitalAssetsWallet,
} from 'modules/tt-digital-assets-provider';

import WalletIconSVG from 'assets/images/wallet.svg';

import styles from './styles.module.scss';
import { CopyIcon, DownloadIcon, ReloadIcon, CloseCrossIcon, SearchIcon } from 'tt-ui-lib/icons';

const HoldersTable = (props) => {
  const { className, style } = props;

  const { chainSettings, copyToClipboard, downloadArrAsCSVFile, getterValue, fromWei, getterHash } =
    useDigitalAssets();
  const { getHolders } = useDigitalAssetsAPI();
  const { getRegisterWalletStateIcon } = useDigitalAssetsWallet();

  const [holders, setHolders] = useState(null);
  const [page, setPage] = useState(1);
  const [tableLoading, setTableLoading] = useState(false);
  const [searchString, setSearchString] = useState('');

  const _getHolders = async (wallet = undefined) => {
    setTableLoading(true);
    let data = (await getHolders(wallet)) || [];
    data = data.map((el) => {
      if (el) {
        const amnt = el.balance !== null ? parseFloat(fromWei(el.balance || 0)) : '-';
        // eslint-disable-next-line no-param-reassign
        el.value = Number.isNaN(amnt) ? '-' : String(amnt);
      }
      return el;
    });
    setTableLoading(false);

    return data;
  };

  const getHoldersData = async (wallet) => {
    setPage(1);
    setHolders(await _getHolders(wallet));
  };

  const getCSVHolders = async () => downloadArrAsCSVFile(await _getHolders(), 'holders.csv');

  const searchHolders = (e, search) => {
    if (e.key !== 'Enter') {
      return;
    }

    if (search) {
      getHoldersData(search).then();
    } else {
      getHoldersData().then();
    }
  };

  const columns = [
    {
      key: 'wallet',
      dataIndex: 'wallet',
      title: 'Wallet',
      align: 'left',
      width: 250,
      render: (value, row) => (
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <CopyIcon
            style={{
              width: 20,
              height: 20,
              cursor: 'pointer',
            }}
            onClick={async () => copyToClipboard(value)}
          />
          <span style={{ marginLeft: '10px', marginRight: '5px' }}>0x{getterHash(value)}</span>
          {row.state && getRegisterWalletStateIcon(row.state)}
        </div>
      ),
    },
    {
      key: 'value',
      dataIndex: 'value',
      title: `Value ${chainSettings.nativeCurrency.symbol}`,
      align: 'left',
      width: 340,
      render: (value) => <>{getterValue(value)}</>,
    },
    {
      key: 'icon',
      dataIndex: 'icon',
      title: '',
      align: 'center',
      width: 30,
      render: () => <img alt="" src={WalletIconSVG} style={{ width: 20, height: 20 }} />,
    },
  ];

  useEffect(() => {
    getHoldersData().then();
  }, []);

  return (
    <div className={`${styles.HoldersTable} ${className} HoldersTable`} style={{ ...style }}>
      <div className={clsx(styles.row1, 'row1')}>
        <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
          <span className={clsx(styles.h2, 'h2', styles.header)} style={{ marginBottom: 0 }}>
            Holders
          </span>

          <ReloadIcon
            style={{
              width: 20,
              height: 20,
              marginLeft: '5px',
              cursor: 'pointer',
            }}
            onClick={() => getHoldersData()}
          />

          <DownloadIcon
            style={{
              width: 20,
              height: 20,
              marginLeft: '5px',
              cursor: 'pointer',
            }}
            onClick={() => getCSVHolders()}
          />
        </div>

        {/* <TextInput */}
        {/*  className={styles.search} */}
        {/*  placeholder="Search" */}
        {/*  onChange={(e) => setSearchString(e.target.value)} */}
        {/*  onKeyDown={(e) => searchHolders(e, searchString)} */}
        {/* /> */}
        <Block
          className={clsx(styles.transactionFind, 'transactionFind')}
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#F9F9F9',
            margin: '10px 5px',
          }}
        >
          <SearchIcon style={{ width: 22, height: 22, marginLeft: '10px' }} />
          <input
            type="text"
            placeholder="Search..."
            value={searchString}
            className={clsx(styles.transactionFind, 'transactionFind')}
            onChange={(e) => setSearchString(e.target.value)}
            onKeyDown={(e) => searchHolders(e, searchString)}
          />
          <div
            onClick={() => {
              setSearchString('');
              getHoldersData().then();
            }}
          >
            <CloseCrossIcon style={{ width: 20, height: 20, cursor: 'pointer' }} />
          </div>
        </Block>
      </div>

      <div style={{ width: '100%', height: '100%', minHeight: 450, overflowX: 'auto' }}>
        <Table
          loading={tableLoading}
          columns={columns}
          data={holders || []}
          scroll={{ x: 870, y: '40vh' }}
          pagination={{
            position: ['bottomCenter'],
            current: page,
            pageSize: 10,
            responsive: true,
            showSizeChanger: false,
            hideOnSinglePage: true,
            onChange: (newPage) => setPage(newPage),
          }}
        />
      </div>
    </div>
  );
};

export default HoldersTable;
