import React, { useState } from 'react';
import clsx from 'clsx';
import { Button, Input, CheckBox } from 'tt-ui-lib/core';

import styles from './EditWalletStatusModal.module.scss';
import { useDigitalAssetsWallet } from 'modules/tt-digital-assets-provider';
import { showError } from '../../../../utils/common';

const EditWalletStatusModal = ({ handleClose, wallet, walletState, walletsUpdate }) => {
  const { doWalletBlock, doWalletUnblock } = useDigitalAssetsWallet();

  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);

  const onWalletBlock = async () => {
    setLoading(true);

    try {
      await doWalletBlock(wallet, reason);
      await walletsUpdate();
    } catch (err) {
      showError(err);
    } finally {
      setLoading(false);
      handleClose(false);
    }
  };

  const onWalletUnBlock = async () => {
    setLoading(true);

    try {
      await doWalletUnblock(wallet);
      await walletsUpdate();
    } catch (err) {
      showError(err);
    } finally {
      setLoading(false);
      handleClose(false);
    }
  };

  const changeReason = (evnt) => {
    const val = evnt.target.value || '';
    setReason(val);
  };

  return (
    <div className={clsx(styles.editWalletStateModal, 'editWalletStateModal')}>
      {walletState === -1 ? (
        <div className={clsx(styles.modalContent, 'modalContent')}>
          <CheckBox />
        </div>
      ) : (
        ''
      )}
      {walletState === 2 ? (
        <div className={clsx(styles.modalContent, 'modalContent')}>
          <Input label="Reason" value={reason} onChange={changeReason} />
        </div>
      ) : (
        ''
      )}
      <div className={clsx(styles.modalActions, 'modalActions')}>
        <Button
          type="default"
          onClick={() => {
            handleClose();
          }}
        >
          CANCEL
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={walletState === 2 ? onWalletBlock : onWalletUnBlock}
        >
          {walletState === 2 ? 'Block' : 'Unblock'}
        </Button>
      </div>
    </div>
  );
};

export default EditWalletStatusModal;
