import React from 'react';
import { Modal, Select } from 'tt-ui-lib/core';

const SelectContractVersionModal = ({
  showSelectContractVersionDialog,
  setShowSelectContractVersionDialog,
  contractName,
  contractNameToEdit,
  setSettingsId,
  setShowSettingsDialog,
  contractList,
}) => (
  <>
    {showSelectContractVersionDialog ? (
      <Modal
        open={showSelectContractVersionDialog}
        onClose={() => setShowSelectContractVersionDialog(false)}
        title={`Select ${contractName || ''} contract version to edit`}
        destroyOnClose
      >
        <div>
          <Select
            label="Select contract version"
            onChange={(value) => {
              setShowSelectContractVersionDialog(false);
              setSettingsId({
                id: contractNameToEdit,
                version: parseInt(value, 10) || 0,
              });
              setShowSettingsDialog(true);
            }}
            style={{ width: '100%', margin: '20px 0' }}
            options={contractList
              ?.find((el) => el.id === contractNameToEdit)
              ?.versions?.map((el) => ({ value: `${el}`, label: `${el}` }))}
          />
        </div>
      </Modal>
    ) : (
      ''
    )}
  </>
);

export default SelectContractVersionModal;
