import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, Modal, Radio } from 'tt-ui-lib/core';
import { useMutation } from '@apollo/client';
import RemoveUserModal from './RemoveUserModal';
import { UPDATE_USER_STATUS } from 'api';

import styles from './EditStatusModal.module.scss';

const statuses = [
  { value: 'deleted', label: 'Deleted' },
  { value: 'blocked', label: 'Blocked' },
  { value: 'not_blocked', label: 'Not Blocked' },
  { value: 'recovered', label: 'Recover' },
];
const EditStatusModal = ({
  open,
  handleClose,
  userFullName,
  userId,
  userStatus,
  updateUserList,
}) => {
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [checkedStatus, setCheckedStatus] = useState('');
  const [updateUserStatus] = useMutation(UPDATE_USER_STATUS, {
    variables: { input: {} },
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCheckedStatus(userStatus);
  }, [open]);

  const handleChangeStatus = async (status) => {
    if (status === 'deleted') {
      setRemoveUserModal(true);
      return;
    }

    setLoading(true);

    try {
      await updateUserStatus({
        variables: {
          input: {
            user_id: userId,
            status: status,
          },
        },
      }).then(() => {
        if (updateUserList) updateUserList();

        setRemoveUserModal(false);
        handleClose();
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className={clsx(styles.editStatusModal, 'editStatusModal')}>
        <div className={clsx(styles.modalContent, 'modalContent')}>
          <Radio
            value={checkedStatus}
            options={statuses.filter(
              (item) =>
                (userStatus !== 'deleted' && item.value !== 'recovered') || userStatus === 'deleted'
            )}
            onChange={(e) => setCheckedStatus(e.target.value)}
            style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '10px 0' }}
          />
        </div>
        <div className={clsx(styles.modalActions, 'modalActions')}>
          <Button
            type="default"
            onClick={() => {
              setCheckedStatus('');
              handleClose();
            }}
          >
            CANCEL
          </Button>
          <Button
            type="primary"
            loading={loading}
            onClick={() => handleChangeStatus(checkedStatus)}
          >
            CONFIRM
          </Button>
        </div>
      </div>

      <Modal
        open={removeUserModal}
        onClose={() => setRemoveUserModal(false)}
        title={`Do you really want to remove ${userFullName} from Transparenterra?`}
      >
        <RemoveUserModal
          loading={loading}
          deleteUser={() => handleChangeStatus('deleted')}
          handleClose={() => {
            setRemoveUserModal(false);
          }}
        />
      </Modal>
    </>
  );
};

export default EditStatusModal;
