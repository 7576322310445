import React, { useMemo, useCallback, useEffect, useState, useContext } from 'react';
import io from 'socket.io-client';
import VideoCallModal from 'components/VideoCallModal/VideoCallModal';
import CallTimeoutModal from 'components/CallTimeoutModal/CallTimeoutModal';
import {
  changeCallStatus,
  endDirectCall,
  getChatFolders,
  deleteChatFolder,
  createChatCategory,
  appendChatCategoryChat,
} from '../../api/chat/endpoints/chat';
import userContext from 'context/User/userContext';
import MessengerContext from './messengerContext';
import { GET_USER } from 'api';
import { useLazyQuery } from '@apollo/client';

const MessengerState = ({ children }) => {
  const timeoutCallDuration = 60000;
  const [typingData, setTypingData] = useState(null);
  const [newMessage, setNewMessage] = useState(null);
  const [directCallData, setDirectCallData] = useState({});
  const [showVideoCallModal, setShowVideoCallModal] = useState(false);
  const [getUser, { userResponse }] = useLazyQuery(GET_USER);
  const [callId, setCallId] = useState('');
  const [twilioRoomId, setTwilioRoomId] = useState('');
  const [twilioToken, setTwilioToken] = useState('');
  const [callStatus, setCallStatus] = useState('');
  const [chatFolders, setChatFolders] = useState([]);
  const { user } = useContext(userContext);
  const [socket, setSocket] = useState(null);
  const [userId, setUserId] = useState(null);
  const [audio, setAudio] = useState(new Audio('/audio/call-tone.mp3'));
  const [showCallTimeoutModal, setShowCallTimeoutModal] = useState(false);

  const resetState = async () => {
    setDirectCallData({});
    setTwilioRoomId('');
    setTwilioToken('');
    setCallId('');
    setShowVideoCallModal(false);
    await audio.pause();
  };

  const getChatFoldersList = async (id = userId) => {
    const folders = await getChatFolders({
      auth_user_id: id,
    });

    if (folders.data.success) {
      setChatFolders(folders.data.chats);
    }
  };

  const handleConnectSocket = (id = userId) => {
    if (id) {
      setUserId(id);
      getChatFoldersList(id).then();
      const socketIo = io(process.env.REACT_APP_WS, {
        reconnectionDelayMax: 10000,
        extraHeaders: {
          user_id: id,
        },
        withCredentials: true,
        transports: ['websocket'],
      });
      if (socketIo) {
        setSocket(socketIo);
        socketIo.on('private typing stop', (data) => {
          setTypingData(null);
        });

        socketIo.on('private typing', (data) => {
          setTypingData(data);
        });

        socketIo.on('private message', (data) => {
          setNewMessage(data);
        });

        socketIo.on('private call', async (data) => {
          if (data.sender_id) {
            const userData = await getUser({ variables: { id: data.sender_id } });
            setDirectCallData({ ...data, user: userData.data.user });
            setCallStatus('incoming');
            setShowVideoCallModal(true);
            audio.loop = true;
            await audio.play();
          }
        });

        socketIo.on('private call ended', (data) => {
          setCallStatus('ended');
          resetState().then();
        });

        socketIo.on('private call status', async (data) => {
          if (data.status === 'answered') {
            const userData = await getUser({ variables: { id: data.receiver_id } });
            setDirectCallData({ ...data, user: userData.data.user });
            setTwilioRoomId(data.twilio_room_id);
            setTwilioToken(data.token);
            setCallId(data.id);
            setCallStatus('answered');
          }
          if (data.status === 'rejected') {
            setCallStatus('rejected');
            resetState().then();
          }
          if (data.status === 'cancelled') {
            setCallStatus('cancelled');
            await audio.pause();
            resetState().then();
          }
        });
      }
    }
  };

  const disconnectSocket = useCallback(
    (id) => {
      if (socket && socket.connected) {
        socket.off('private typing stop');
        socket.off('private typing');
        socket.off('private call');
        socket.off('private message');
        socket.off('private call status');
        setSocket(null);
      }
    },
    [socket]
  );

  const connectSocket = useCallback((id) => {
    disconnectSocket();
    handleConnectSocket(id);
  }, []);

  useEffect(() => {
    if (user?.id) {
      handleConnectSocket(user?.id);
    }

    return () => {
      if (socket) {
        disconnectSocket();
      }
    };
  }, []);

  const startTyping = useCallback(
    (data) => {
      if (data) {
        socket.emit('private typing', data);
      }
    },
    [socket]
  );

  const stopTyping = useCallback(
    (data) => {
      if (data) {
        socket.emit('private typing stop', data);
      }
    },
    [socket]
  );

  const startDirectCall = useCallback(
    (data) => {
      if (data) {
        socket.emit('private typing stop', data);
      }
    },
    [socket]
  );

  const startNewCall = useCallback((data) => {
    setCallStatus('outgoing');
    setDirectCallData(data);
    setShowVideoCallModal(true);
  }, []);

  const rejectCall = useCallback(
    async (id) => {
      if (id) {
        const params = {
          status: 'rejected',
          auth_user_id: userId,
          call_id: id,
        };
        const response = await changeCallStatus(params);
        if (response.data.success) {
          setCallStatus('rejected');
          setShowVideoCallModal(false);
          await audio.pause();
        }
      }
    },
    [userId, audio]
  );

  const cancelCall = useCallback(
    async (id) => {
      if (id) {
        const params = {
          status: 'cancelled',
          auth_user_id: userId,
          call_id: id,
        };
        const response = await changeCallStatus(params);
        if (response.data.success) {
          setCallStatus('cancelled');
          setShowVideoCallModal(false);
          await audio.pause();
        }
      }
    },
    [userId, audio]
  );

  useEffect(() => {
    let timeoutID;
    if (showVideoCallModal && callStatus === 'outgoing') {
      timeoutID = setTimeout(async () => {
        setShowVideoCallModal((prevValue) => {
          if (prevValue) {
            setCallStatus((value) => {
              if (value !== 'answered') {
                setShowCallTimeoutModal(() => true);
              }
              return value;
            });
          } else {
            setCallStatus((value) => {
              if (
                value !== 'rejected' &&
                value !== 'ended' &&
                value !== '' &&
                value !== 'cancelled'
              ) {
                setShowCallTimeoutModal(() => true);
              }
              return value;
            });
          }
          return prevValue;
        });
        await cancelCall(directCallData.id);
      }, timeoutCallDuration);
    }
    return () => {
      clearTimeout(timeoutID);
    };
  }, [showVideoCallModal]);

  const endCall = useCallback(
    async (id) => {
      if (id) {
        const params = {
          auth_user_id: userId,
          call_id: id,
        };
        const response = await endDirectCall(params);
        if (response.data.success) {
          setCallStatus('');
          setShowVideoCallModal(false);
        }
      }
    },
    [userId]
  );

  const answerCall = useCallback(
    async (id) => {
      if (id) {
        await audio.pause();
        const params = {
          status: 'answered',
          auth_user_id: userId,
          call_id: id,
        };
        const response = await changeCallStatus(params);
        if (response.data.success) {
          setTwilioRoomId(response.data.call.twilio_room_id);
          setTwilioToken(response.data.call.token);
          setCallId(response.data.call.id);
          setCallStatus('answered');
        }
      }
    },
    [userId]
  );

  const handleDeleteFolder = useCallback(
    async (id) => {
      const deleteChatFolderResource = await deleteChatFolder({
        id: id,
        auth_user_id: userId,
      });
      if (deleteChatFolderResource.data.success) {
        const folders = chatFolders.filter((folder) => folder.id !== id);
        setChatFolders(folders);
      }
    },
    [chatFolders, userId]
  );

  const handleAppendChatsCategory = async (chats, chatCategoryId) => {
    await appendChatCategoryChat({
      auth_user_id: userId,
      chat_category_id: chatCategoryId,
      chat_id: chats,
    });
  };

  const handleCreateFolder = useCallback(
    async (data) => {
      const response = await createChatCategory({
        name: data.name,
        color: data.color,
        auth_user_id: userId,
      });
      if (response.data.success) {
        const chatCategory = response.data.chat_category;
        setChatFolders((current) => [...current, chatCategory]);
        await handleAppendChatsCategory(data.chats, chatCategory.id);
      }
    },
    [chatFolders, userId]
  );

  const appendChatsChatCategory = useCallback(
    async (chats, id) => {
      await handleAppendChatsCategory(chats, id);
    },
    [chatFolders]
  );

  const contextValue = useMemo(
    () => ({
      newMessage,
      typingData,
      stopTyping,
      startTyping,
      startNewCall,
      startDirectCall,
      answerCall,
      rejectCall,
      appendChatsChatCategory,
      handleCreateFolder,
      handleDeleteFolder,
      chatFolders,
      connectSocket,
      disconnectSocket,
    }),
    [typingData, newMessage, chatFolders, socket, userId]
  );

  return (
    <MessengerContext.Provider value={contextValue}>
      <VideoCallModal
        callId={callId}
        twilioRoomId={twilioRoomId}
        twilioToken={twilioToken}
        callStatus={callStatus}
        userId={userId}
        open={showVideoCallModal}
        directCallData={directCallData}
        handleClose={() => setShowVideoCallModal(false)}
        answerCall={(id) => answerCall(id)}
        rejectCall={(id) => rejectCall(id)}
        endCall={(id) => endCall(id)}
        cancelCall={(id) => cancelCall(id)}
      />
      <CallTimeoutModal
        handleClose={() => setShowCallTimeoutModal(false)}
        open={showCallTimeoutModal}
      />
      {children}
    </MessengerContext.Provider>
  );
};

export default MessengerState;
