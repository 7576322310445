import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Loader } from 'tt-ui-lib/core';
import HoldersTable from './HoldersTable';
import { useDigitalAssets, useDigitalAssetsAPI } from 'modules/tt-digital-assets-provider';

import styles from './styles.module.scss';

const Holders = (props) => {
  const { className, style } = props;
  const { chainSettings, blockNumber, fromWei, isNaN } = useDigitalAssets();
  const { getCurrentSupply } = useDigitalAssetsAPI();
  const [currentSupply, setCurrentSupply] = useState(NaN);

  // eslint-disable-next-line no-underscore-dangle
  const _getCurrentSupply = async () => {
    setCurrentSupply(fromWei((await getCurrentSupply()) || '0'));
  };

  useEffect(() => {
    _getCurrentSupply().then();
  }, []);

  return (
    <div className={`Holders ${styles.Holders} ${className || ''}`} style={{ ...style }}>
      {window.innerWidth > 375 ? (
        <div
          className={clsx(styles.row1, 'row1')}
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <div className={clsx(styles.bold, 'bold')}>
            Total supply: 5 300 000 000 {chainSettings.nativeCurrency.symbol}
          </div>
          <div className={clsx(styles.bold, 'bold')}>
            Current supply:{' '}
            {!isNaN(currentSupply) ? (
              `${currentSupply} ${chainSettings.nativeCurrency.symbol}`
            ) : (
              <Loader style={{ position: 'relative', top: '6px' }} />
            )}
          </div>
          <div className={clsx(styles.bold, 'bold')}>
            Block:{' '}
            {!isNaN(blockNumber) ? (
              String(blockNumber)
            ) : (
              <Loader style={{ position: 'relative', top: '6px' }} />
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className={clsx(styles.row1, 'row1')}>
            <div className={clsx(styles.bold, 'bold')}>
              Total supply: 5 300 000 {chainSettings.nativeCurrency.symbol}
            </div>
            <div className={clsx(styles.bold, 'bold')}>
              Current supply:{' '}
              {currentSupply !== null ? (
                currentSupply
              ) : (
                <Loader style={{ position: 'relative', top: '6px' }} />
              )}{' '}
              {chainSettings.nativeCurrency.symbol}
            </div>
            <div className={clsx(styles.bold, 'bold')}>
              Block:{' '}
              {blockNumber !== null ? (
                blockNumber
              ) : (
                <Loader style={{ position: 'relative', top: '6px' }} />
              )}
            </div>
          </div>
        </div>
      )}

      <div className={clsx(styles.row3, 'row3')}>
        <div style={{ overflow: 'auto' }}>
          <HoldersTable />
        </div>
      </div>
    </div>
  );
};

export default Holders;
