import React from 'react';
import clsx from 'clsx';
import styles from '../FceAdmin.module.scss';
import { Button, Modal } from 'tt-ui-lib/core';

const ChangeNetworkModal = ({
  showSwitchNetDialog,
  setShowSwitchNetDialog,
  chainId,
  setCurrentNetID,
  switchChainHandle,
}) => (
  <Modal
    open={showSwitchNetDialog}
    onClose={() => {
      setCurrentNetID(chainId);
      setShowSwitchNetDialog(false);
    }}
    title="Change network"
  >
    <div>To continue, change the network in metamask</div>

    <div className={clsx(styles.formButtonGroup, 'formButtonGroup')}>
      <Button type="primary" onClick={switchChainHandle}>
        Change
      </Button>
      <Button
        type="default"
        onClick={() => {
          setCurrentNetID(chainId);
          setShowSwitchNetDialog(false);
        }}
      >
        Cancel
      </Button>
    </div>
  </Modal>
);

export default ChangeNetworkModal;
