import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Loader, Modal } from 'tt-ui-lib/core';
import { useDigitalAssets, useDigitalAssetsAPI } from 'modules/tt-digital-assets-provider';
import { showError } from 'utils/common';

import styles from './styles.module.scss';
import { useWindowSize } from '../../../../utils/useWindowSize';
import { CopyIcon } from 'tt-ui-lib/icons';

const initialDataState = {
  blockHash: '',
  blockNumber: NaN,
  from: '',
  gas: NaN,
  gasPrice: '',
  hash: '',
  input: '',
  nonce: NaN,
  r: '',
  s: '',
  to: '',
  transactionIndex: NaN,
  type: NaN,
  v: '',
  value: '',
  value_all: '',
  ts: '',
  CaFFee: '',
  tokens: '',
  method: '',
};

const TransactionDetailModal = (props) => {
  const { className, style, showTransactionDetail, setShowTransactionDetail, transactionHash } =
    props;

  const { width } = useWindowSize();
  const { chainSettings, copyToClipboard, fromWei } = useDigitalAssets();
  const { findTransactionByHash } = useDigitalAssetsAPI();

  const [loading, setLoading] = useState(false);
  const [transactionInfo, setTransactionInfo] = useState(initialDataState);

  const getterHash = (val) =>
    val && val.length > 10 ? `${val.substring(0, 5)}...${val.substring(val.length - 3)}` : '-';

  const findTransaction = async () => {
    if (transactionHash) {
      try {
        const res = await findTransactionByHash(transactionHash);

        /** @type {[Object]} */
        if (res?.hash) {
          setTransactionInfo(res);
          setShowTransactionDetail(true);
        } else {
          showError('Transaction not found');
          setShowTransactionDetail(false);
        }
      } catch (err) {
        showError('Transaction not found');
        setShowTransactionDetail(false);
      } finally {
        setLoading(false);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (transactionHash && !transactionInfo?.hash && showTransactionDetail) {
      setLoading(true);
      const handler = setTimeout(findTransaction, 100);
      return () => clearInterval(handler);
    }
  }, [transactionHash, showTransactionDetail]);

  return (
    <Modal // Transaction detail
      className={className}
      style={{ ...style }}
      open={showTransactionDetail}
      onClose={() => {
        setShowTransactionDetail(false);
        setTransactionInfo(initialDataState);
      }}
      fullWidth
      title="Transaction"
    >
      {loading ? (
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      ) : transactionInfo.hash ? (
        <div className={clsx(styles.context, 'context')}>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>Hash:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {width > 375 ? transactionInfo.hash : getterHash(transactionInfo.hash)}
            </div>

            <CopyIcon
              style={{ width: 20, height: 20, cursor: 'pointer' }}
              onClick={async () => copyToClipboard(transactionInfo.hash)}
            />
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>Date/time:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {(transactionInfo.ts || '').replace('T', ' ')}
            </div>
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>From:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {width > 375 ? transactionInfo.from : getterHash(transactionInfo.from)}
            </div>

            <CopyIcon
              style={{ width: 20, height: 20, cursor: 'pointer' }}
              onClick={async () => copyToClipboard(transactionInfo.from)}
            />
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>To:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {width > 375 ? transactionInfo.to : getterHash(transactionInfo.to)}
            </div>

            <CopyIcon
              style={{ width: 20, height: 20, cursor: 'pointer' }}
              onClick={async () => copyToClipboard(transactionInfo.to)}
            />
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>Value:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {fromWei(transactionInfo.value > 0 ? transactionInfo.value : 0)}{' '}
              {width > 375 ? chainSettings.nativeCurrency.symbol : ''}
            </div>
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>Value (total):</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {transactionInfo.value_all} {width > 375 ? chainSettings.nativeCurrency.symbol : ''}
            </div>
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>CaFFee:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {transactionInfo.CaFFee && fromWei(transactionInfo.CaFFee || 0)}{' '}
              {width > 375 ? chainSettings.nativeCurrency.symbol : ''}
            </div>
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>Block number:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {transactionInfo.blockNumber}
            </div>
          </div>
        </div>
      ) : transactionInfo.hash === '' ? (
        <div className={clsx(styles.h1, 'h1')}>The transaction is requested...</div>
      ) : (
        <div>
          <div className={clsx(styles.h1, 'h1')}>Transaction is not found</div>
        </div>
      )}
    </Modal>
  );
};

export default TransactionDetailModal;
