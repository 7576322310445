/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unknown-property */

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Modal, Table } from 'tt-ui-lib/core';
import { CopyIcon, DownloadIcon, ShareIcon } from 'tt-ui-lib/icons';
import TransactionDetailModal from '../../../FCEExplorer/TransactionDetailModal';
import { useDigitalAssets, useDigitalAssetsAPI } from 'modules/tt-digital-assets-provider';

import MainCertificateIMG from '../../assets/co2_certificate_16714506896614.png';

import styles from './styles.module.scss';

const CarbonMainCertificateDetailModal = (props) => {
  const {
    className,
    style,
    showCarbonMainCertificateDetail,
    setShowCarbonMainCertificateDetail,
    certificate,
  } = props;

  const {
    status,
    chainId,
    chainSettings,
    copyToClipboard,
    downloadArrAsCSVFile,
    getterHash,
    getterDate,
    getterValue,
    fromWei,
    json2obj,
  } = useDigitalAssets();
  const { getLatestEvents } = useDigitalAssetsAPI();

  const certInfo = certificate || {};

  const initTokens = parseInt(certInfo?.initTokens, 10) || 0;
  const freeTokens = parseInt(certInfo?.freeTokens, 10) || 0;

  const tonns = parseInt(certInfo?.value, 10) || 0;
  const offTonns = (initTokens - freeTokens) * 0.000000841775642;
  const freeTonns = tonns - offTonns;

  const [currentTonns, setCurrentTonns] = useState(freeTonns.toFixed(6));
  const [currentTokens, setCurrentTokens] = useState(freeTokens.toFixed(0));
  const [offsettedTonns, setOffsettedTonns] = useState(offTonns.toFixed(6));
  const [offsettedTokens, setOffsettedTokens] = useState((initTokens - freeTokens).toFixed(0));

  const [eventHistory, setEventHistory] = useState(null);
  const [tableCurrentPage, setTableCurrentPage] = useState(1);

  const [transactionHash, setTransactionHash] = useState('');
  const [showTransactionDetail, setShowTransactionDetail] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  /** @type {{addr: String, fee: String, number: String, URL: string, note: String, brand: String, value: String, date: String, initTokens: String, freeTokens: String}} */
  const downloadCertHandle = () => window.open(certInfo?.URL);

  const shareHandle = () => window.open(`/certificate/${certInfo.addr}`);

  const _findTransactionByHash = async (evnt) => {
    if (status === 'connected' && chainId === chainSettings?.chainId) {
      // eslint-disable-next-line no-restricted-globals
      const data = json2obj(evnt.target.parentElement.getAttribute('data')) || {};
      const field = evnt.target.parentElement.getAttribute('field');
      const hash = data[field];

      if (hash) {
        // Поиск транзакции
        setTransactionHash(hash);
        setShowTransactionDetail(true);
      }
    }
  };

  const getterTransactionHash = (val) =>
    val ? (
      <span className={clsx(styles.link, 'link')} hash={val} onClick={_findTransactionByHash}>
        {getterHash(val)}
      </span>
    ) : (
      '-'
    );

  const getterCarbonValue = (val) => (val ? `${val} CaFFee` : '');

  const getEventHistory = async () => {
    if (!certificate?.addr) return;
    setTableLoading(true);

    const res = await getLatestEvents(certificate.addr);

    const data = res || [];
    data.map(
      /**
       * @param {{hash: string, to: string, ts: string, cost: string, value: number|string}} el
       */
      (el) => {
        if (el) {
          // eslint-disable-next-line no-param-reassign
          el.cost = fromWei(el.cost || 0);
        }
        return el;
      }
    );

    setEventHistory(data);
    setTableLoading(false);
  };

  const getCSVEventsHistory = async () => {
    if (!certificate?.addr) return;

    const res = await getLatestEvents(certificate.addr);

    const data = res || [];
    data.map(
      /**
       * @param {{hash: string, to: string, ts: string, cost: string, value: number|string}} el
       */
      (el) => {
        if (el) {
          // eslint-disable-next-line no-param-reassign
          el.cost = fromWei(el.cost || 0);
        }
        return el;
      }
    );

    return downloadArrAsCSVFile(data, 'eventsHistory.csv');
  };

  const columns = [
    {
      key: 'hash',
      dataIndex: 'hash',
      title: 'Txn Hash',
      align: 'left',
      width: 110,
      render: (value) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <CopyIcon
            style={{ width: 20, height: 20, cursor: 'pointer' }}
            onClick={async () => {
              if (navigator.clipboard) {
                copyToClipboard(value);
              }
            }}
          />

          {getterTransactionHash(value)}
        </div>
      ),
    },
    {
      key: 'ts',
      dataIndex: 'ts',
      title: 'Date',
      align: 'left',
      width: 120,
      render: (value) => <span>{getterDate(value)}</span>,
    },
    {
      key: 'to',
      dataIndex: 'to',
      title: 'To',
      align: 'left',
      width: 120,
      render: (value) => <span>{getterHash(value)}</span>,
    },
    {
      key: 'value',
      dataIndex: 'value',
      title: `Value ${chainSettings.nativeCurrency.symbol}`,
      align: 'left',
      width: 140,
      render: (value) => <span>{getterValue(value)}</span>,
    },
    {
      key: 'cost',
      dataIndex: 'cost',
      title: 'FCEM',
      align: 'left',
      width: 100,
      render: (value, row) => <span style={{ wordBreak: 'break-all' }}>{value}</span>,
    },
  ];

  useEffect(() => {
    if (!eventHistory && showCarbonMainCertificateDetail) {
      const handler = setTimeout(getEventHistory, 500);
      return () => clearInterval(handler);
    }
  }, [showCarbonMainCertificateDetail]);

  return (
    <Modal // Certificate
      className={className}
      style={{ ...style }}
      open={showCarbonMainCertificateDetail}
      onClose={() => setShowCarbonMainCertificateDetail(false)}
      title="Certificate"
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <DownloadIcon
          style={{
            width: 20,
            height: 20,
            marginRight: '15px',
            cursor: 'pointer',
          }}
          onClick={() => downloadCertHandle()}
        />

        <ShareIcon
          style={{
            width: 20,
            height: 20,
            cursor: 'pointer',
          }}
          onClick={() => shareHandle()}
        />
      </div>

      <div
        className={clsx(styles.col1_1, 'col1_1')}
        style={{ fontWeight: 'bold', marginBottom: '10px' }}
      >
        Current balance: {currentTonns} tonne / {currentTokens} tokens
      </div>

      <div
        className={clsx(styles.col1_1, 'col1_1')}
        style={{ fontWeight: 'bold', marginBottom: '10px' }}
      >
        Offsetted: {offsettedTonns} tonne / {offsettedTokens} tokens
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
        <img
          src={MainCertificateIMG}
          alt=""
          style={{
            borderRadius: 12,
            width: window.innerWidth > 875 ? 564 : 300,
            height: window.innerWidth > 875 ? 500 : 173,
          }}
        />
      </div>

      <div style={{ marginTop: '10px' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <div className={clsx(styles.h1, 'h1')} style={{ marginBottom: 0 }}>
            Offsetting history
          </div>

          <DownloadIcon
            style={{
              width: 20,
              height: 20,
              marginLeft: '24px',
              cursor: 'pointer',
            }}
            onClick={() => getCSVEventsHistory()}
          />
        </div>

        <div>
          <Table
            loading={tableLoading}
            columns={columns}
            data={eventHistory}
            pagination={{
              position: ['bottomCenter'],
              current: tableCurrentPage,
              pageSize: 10,
              total: eventHistory?.length,
              responsive: true,
              showSizeChanger: false,
              hideOnSinglePage: true,
              onChange: (page) => setTableCurrentPage(parseInt(page, 10)),
            }}
          />
        </div>
      </div>

      {showTransactionDetail ? (
        <TransactionDetailModal
          showTransactionDetail={showTransactionDetail}
          setShowTransactionDetail={setShowTransactionDetail}
          transactionHash={transactionHash}
        />
      ) : (
        ''
      )}
    </Modal>
  );
};
export default CarbonMainCertificateDetailModal;
