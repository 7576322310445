import React from 'react';
import { Input, DatePicker, CheckBox, Select } from 'tt-ui-lib/core';
import styles from '../FceAdmin.module.scss';
import { useDigitalAssets } from 'modules/tt-digital-assets-provider';
import { convertBlockchainTimestampToDate, convertDateToBlockchainTimestamp } from 'utils/data';

const TypedFieldset = ({ type, data, changeField, list, lists, actions, fields, datePatterns }) => {
  const { chainSettings } = useDigitalAssets();

  const switchSectionByType = (blockType) => {
    switch (blockType) {
      case 'uint':
      case 'int':
      case 'string':
      case 'address':
      case 'hex':
      case 'bool':
        return (
          <>
            <div className={styles.fieldModalAccordionRow}>
              <CheckBox
                label="Set default value"
                value={data?.has_default_value}
                onChange={(e) => changeField('has_default_value', e.target.checked)}
                className={styles.rowCheckbox}
              />
              {data?.has_default_value && (
                <Input
                  label="Default value"
                  value={data?.default_value}
                  onChange={(e) => changeField('default_value', e.target.value)}
                />
              )}
            </div>
            <div className={styles.fieldModalAccordionRow}>
              <CheckBox
                label="Read only"
                value={data?.readonly}
                onChange={(e) => changeField('readonly', e.target.checked)}
                className={styles.rowCheckbox}
              />
            </div>
          </>
        );

      case 'payment':
        return (
          <>
            <div className={styles.fieldModalAccordionRow}>
              <Select
                label="Field"
                value={data?.default_value}
                onChange={(value) => changeField('default_value', value)}
                options={[...fields.map((item) => ({ value: item.param, label: item.caption }))]}
                allowClear
              />
              <Select
                label="Type"
                value={data?.action}
                onChange={(value) => changeField('action', value)}
                options={[...actions]}
                allowClear
              />
              <Input
                label="Value"
                value={data?.pay_multiplier_value}
                onChange={(e) => {
                  if (data?.use_caffee_like_value) return;
                  changeField('pay_multiplier_value', e.target.value);
                }}
                disabled={data?.use_caffee_like_value}
              />
            </div>
            <div className={styles.fieldModalAccordionRow}>
              <CheckBox
                label="Use CAFFEE like a value"
                value={data?.use_caffee_like_value}
                onChange={(e) => {
                  changeField('use_caffee_like_value', e.target.checked);

                  if (e.target.checked) {
                    changeField('pay_multiplier_value', '');
                  }
                }}
                className={styles.rowCheckbox}
              />
            </div>
            <div className={styles.fieldModalAccordionRow}>
              <CheckBox
                label="Add CAFFEE cost"
                value={data?.add_caffee_to_payment}
                onChange={(e) => changeField('add_caffee_to_payment', e.target.checked)}
                className={styles.rowCheckbox}
              />
            </div>
          </>
        );

      case 'date_time':
      case 'date':
      case 'time':
        return (
          <>
            <div className={styles.fieldModalAccordionSelectRow}>
              <Select
                label="Pattern"
                value={data?.date_time_pattern}
                onChange={(value) => changeField('date_time_pattern', value)}
                options={datePatterns || []}
              />
            </div>
            <div className={styles.fieldModalAccordionRow}>
              <CheckBox
                label="Set default value"
                value={data?.has_default_value}
                onChange={(e) => changeField('has_default_value', e.target.checked)}
                className={styles.rowCheckbox}
              />
              {data?.has_default_value && (
                <>
                  <DatePicker
                    label={data?.date_time_pattern || 'DD.MM.YYYY - HH.MM.SS.'}
                    value={convertBlockchainTimestampToDate(data?.default_value)}
                    onChange={(e) =>
                      changeField('default_value', convertDateToBlockchainTimestamp(e.target.value))
                    }
                    disabled={data?.use_current_timestamp}
                  />
                  <CheckBox
                    label="Use current date/time"
                    className={styles.rowCheckbox}
                    value={data?.use_current_timestamp}
                    onChange={(e) => changeField('use_current_timestamp', e.target.checked)}
                  />
                </>
              )}
            </div>
            <div className={styles.fieldModalAccordionRow}>
              <CheckBox
                label="Read only"
                value={data?.readonly}
                onChange={(e) => changeField('readonly', e.target.checked)}
                className={styles.rowCheckbox}
              />
            </div>
          </>
        );

      case 'eth':
        return (
          <>
            <div className={styles.fieldModalAccordionRow}>
              <Input
                value={
                  data?.use_native_symbol
                    ? chainSettings?.nativeCurrency?.symbol || 'FCEM'
                    : data?.value_symbol
                }
                onChange={(e) => changeField('value_symbol', e.target.value)}
                disabled={data?.use_native_symbol}
              />
              <CheckBox
                label="Use native symbol"
                value={data?.use_native_symbol}
                onChange={(e) => changeField('use_native_symbol', e.target.checked)}
                className={styles.rowCheckbox}
              />
            </div>
            <div className={styles.fieldModalAccordionRow}>
              <CheckBox
                label="Set default value"
                value={data?.has_default_value}
                onChange={(e) => changeField('has_default_value', e.target.checked)}
                className={styles.rowCheckbox}
              />
              {data?.has_default_value && (
                <Input
                  label="Default value"
                  value={data?.default_value}
                  onChange={(e) => changeField('default_value', e.target.value)}
                />
              )}
            </div>
            <div className={styles.fieldModalAccordionRow}>
              <CheckBox
                label="Read only"
                value={data?.readonly}
                onChange={(e) => changeField('readonly', e.target.checked)}
                className={styles.rowCheckbox}
              />
            </div>
          </>
        );

      case 'string_list':
      case 'int_list':
      case 'uint_list':
      case 'address_list':
        return (
          <>
            <div className={styles.fieldModalAccordionSelectRow}>
              <Select
                label="List type"
                value={data?.list_values}
                options={lists}
                onChange={(value) => changeField('list_values', value)}
              />
            </div>
            <div className={styles.fieldModalAccordionRow}>
              <CheckBox
                label="Set default value"
                value={data?.has_default_value}
                onChange={(e) => changeField('has_default_value', e.target.checked)}
                className={styles.rowCheckbox}
              />
              {data?.has_default_value && (
                <Select
                  label="Default value"
                  value={
                    data?.default_value || data?.default_value === 0
                      ? `${data?.default_value}`
                      : null
                  }
                  onChange={(value) =>
                    changeField('default_value', value ? parseInt(value, 10) : null)
                  }
                  options={[...list]}
                  allowClear
                />
              )}
            </div>
            <div className={styles.fieldModalAccordionRow}>
              <CheckBox
                label="Read only"
                value={data?.readonly}
                onChange={(e) => changeField('readonly', e.target.checked)}
                className={styles.rowCheckbox}
              />
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return <>{switchSectionByType(type)}</>;
};

export default TypedFieldset;
