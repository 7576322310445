import React from 'react';
import clsx from 'clsx';
import styles from '../FceAdmin.module.scss';
import { Button, CodeView, Modal } from 'tt-ui-lib/core';

const ContractFunctionResult = ({
  showResultDialog,
  onCloseHandler,
  contract,
  callContractResult,
}) => (
  <Modal
    open={showResultDialog}
    onClose={onCloseHandler}
    title={`${contract?.caption}.${contract?.func}: Result`}
    destroyOnClose
  >
    <CodeView
      code={callContractResult}
      style={{ width: '100%', maxWidth: '100%', breakWord: 'break-all' }}
    />

    <div className={clsx(styles.formButtonGroup, 'formButtonGroup')}>
      <Button type="default" onClick={onCloseHandler}>
        Close
      </Button>
    </div>
  </Modal>
);

export default ContractFunctionResult;
