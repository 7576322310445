import React from 'react';
import clsx from 'clsx';
import styles from '../styles.module.scss';
import { Button, Input, Select } from 'tt-ui-lib/core';
import { useWindowSize } from '../../../../utils/useWindowSize';
import { useDigitalAssetsBridge } from 'modules/tt-digital-assets-provider';

const TransferBlock = ({
  bridgeTo,
  sendAmountError,
  sendAmount,
  changeSentAmount,
  transferBridgesTo,
  sentBntActive,
  netToInd,
  selectTransferNetToHandler,
  swapConfirm,
}) => {
  const { width } = useWindowSize();
  const { bridge } = useDigitalAssetsBridge();

  return (
    <div className={clsx(styles.row3, 'row3')}>
      <div>
        {`To transfer your FCEMs from ${bridge?.name} to ${
          bridgeTo?.name || 'selected net'
        }, specify the desired amount
            and click the Transmit button. Please note that you need to have enough ${
              bridgeTo?.name || 'selected net'
            } to pay for the gas when receiving funds.`}
      </div>

      <div className={styles.sendBlock}>
        <div className={styles.sendRow}>
          <Input
            label="Amount"
            error={!!sendAmountError && sendAmount}
            // helperText={sendAmount && sendAmountError}
            onChange={changeSentAmount}
            value={sendAmount}
            style={{ width: transferBridgesTo?.length > 1 ? '200px' : '300px' }}
            suffix={width > 400 && bridge?.symbol}
          />

          {transferBridgesTo?.length > 1 && (
            <div style={{ marginLeft: '10px' }}>
              <Select
                value={netToInd}
                label="Destination Net"
                onChange={selectTransferNetToHandler}
                options={Object.keys(transferBridgesTo).map((bId) => {
                  const tm = transferBridgesTo[bId];
                  return {
                    value: bId,
                    label: tm?.name,
                  };
                })}
              />
            </div>
          )}
        </div>
        <div>
          <Button type="primary" onClick={swapConfirm} disabled={!sentBntActive}>
            Transmit
          </Button>
        </div>
      </div>

      <div>
        To receive the transferred funds, navigate to the &apos;receive&apos; tab in the
        corresponding network or click on the &apos;receive&apos; button for the relevant
        transaction in the table below. The appearance of the transaction in the table may take some
        time. You can use the table refresh button to update information about your cross-network
        transactions and their statuses.
      </div>
    </div>
  );
};

export default TransferBlock;
