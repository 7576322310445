/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from 'context';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import styles from './Header.module.scss';
import HeaderNavItem from './HeaderNavItem';
import HeaderRolesMenu from './HeaderRolesMenu';
import { ROLES_LIST } from 'constants/joinPage';
import Cookies from 'js-cookie';

import ExitIconSVG from 'assets/images/exit.svg';
import MenuIconSVG from 'assets/images/menu-icon.svg';

const Header = ({
  isHeaderNav,
  isLogoHidden,
  isRolePage,
  isSignUpPage,
  isSignInPage,
  isSdgPage,
  redirectDomain,
}) => {
  const location = useLocation();
  const [selectedRote, setSelectedRote] = useState(null);
  const [isPageScrolled, setIsPageScrolled] = useState(false);

  const { isError } = useContext(AppContext);
  const idUser = Cookies.get('userId');

  const onClose = (router, isSdg) => {
    if (isSdg) {
      const routeArr = location.pathname.split('/');
      if (routeArr.length > 2) {
        router('/sdg');
      } else {
        router('/menu');
      }
    } else {
      router('/join');
    }
  };

  useEffect(() => {
    if (location && (isRolePage || isSignUpPage)) {
      const roleUrl = location.pathname.split('/')[2];
      const roteFromList = ROLES_LIST.find((role) => role.url === roleUrl);
      setSelectedRote(roteFromList);
    }
  }, [location, isRolePage, isSignUpPage]);

  useEffect(() => {
    if (isSignUpPage || isSignInPage || isSdgPage) {
      const container = document.querySelector('#imageBg');
      if (container) {
        const handler = (e) => {
          setIsPageScrolled((isScrolled) => {
            if (
              !isScrolled &&
              (e.target.scrollTop > 20 || document.documentElement.scrollTop > 20)
            ) {
              return true;
            }

            if (isScrolled && e.target.scrollTop < 4 && document.documentElement.scrollTop < 4) {
              return false;
            }

            return isScrolled;
          });
        };

        container.addEventListener('scroll', handler);
        return () => container.removeEventListener('scroll', handler);
      }
    }
  }, [isSignUpPage]);

  return (
    <header
      className={clsx(
        styles.headerContainer,
        isLogoHidden ? styles.hidden : '',
        isSignUpPage || isSignInPage || isSdgPage ? styles.signHeader : '',
        isPageScrolled ? styles.scrolled : '',
        isError ? styles.withoutZIndex : ''
      )}
    >
      <div className={styles.logo}>
        <Link to="/">
          <img
            src="/images/templates/logo-transparenterra-big.svg"
            alt=""
            className={styles.logoImg}
          />
        </Link>
      </div>
      {(isRolePage || isSignUpPage) && selectedRote && (
        <HeaderRolesMenu
          roles={ROLES_LIST}
          selectedRole={selectedRote}
          isSignUpPage={isSignUpPage}
        />
      )}
      {isHeaderNav && (
        <nav className={styles.headerNav}>
          {idUser ? (
            <HeaderNavItem
              href={redirectDomain ? `${redirectDomain}/dashboard/feed` : '/dashboard/feed'}
              imageSrc={ExitIconSVG}
              text="Home"
            />
          ) : (
            <HeaderNavItem href="/sign_in" imageSrc={ExitIconSVG} text="Sign In/Up" />
          )}
          <HeaderNavItem href="/menu" imageSrc={MenuIconSVG} text="MENU" />
        </nav>
      )}
      {isSdgPage && (
        <nav className={styles.headerNav}>
          <HeaderNavItem href="/menu" imageSrc={MenuIconSVG} text="MENU" />
        </nav>
      )}
      {isSignInPage && (
        <nav className={styles.headerNav}>
          <HeaderNavItem
            imageSrc="/images/templates/close-icon.svg"
            text="CLOSE"
            onClose={(router) => onClose(router, isSdgPage)}
          />
        </nav>
      )}
    </header>
  );
};

export default Header;
