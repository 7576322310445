import React, { useState } from 'react';

import styles from './styles.module.scss';
import { Tabs } from 'tt-ui-lib/core';

const MainMenuWOAuth = (props) => {
  const { showedTab, setShowedTab } = props;

  const [menu, setMenu] = useState([
    {
      id: `explorer`,
      name: 'FCE.Explorer',
      child: [
        { id: 'transfer', name: 'Transfer' },
        { id: 'holders', name: 'Holders' },
      ],
    },
  ]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedChildTab, setSelectedChildTab] = useState(0);
  const [selectedChildTabId, setSelectedChildTabId] = useState(null);
  const [currentSubmenu, setCurrentSubmenu] = useState([]);

  const onChangeTab = (value) => {
    if (!value && value !== 0) return;

    setSelectedTab(value);

    const menuItem = menu[value];
    if (menuItem?.child) {
      setCurrentSubmenu(menuItem.child);
      setSelectedChildTab(0);
      setSelectedChildTabId(menuItem.child[0].id || '');
      setShowedTab(`${menuItem.id || ''}.${menuItem.child[0].id || ''}`);
    } else {
      setCurrentSubmenu([]);
      setSelectedChildTab(null);
      setSelectedChildTabId(null);
      setShowedTab(`${menuItem.id || ''}`);
    }
  };

  const onChangeChildTab = (value) => {
    if (!value && value !== 0) return;

    setSelectedChildTab(value);

    const menuItem = menu[selectedTab];
    const childItem = menuItem?.child[value] || undefined;

    if (menuItem && childItem) {
      setSelectedChildTabId(childItem.id || null);
      setShowedTab(`${menuItem.id || ''}.${childItem.id || ''}`);
    }
  };

  return (
    <Tabs
      containerClassName={`${styles.MainMenu}`}
      tabs={menu}
      selectedTab={selectedTab}
      selectedChildTab={selectedChildTab}
      onChangeTab={onChangeTab}
      onChangeChildTab={onChangeChildTab}
    />
  );
};

export default MainMenuWOAuth;
