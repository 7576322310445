/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Loader, ANTD_COMPONENTS } from 'tt-ui-lib/core';
import BridgeHistoryTable from './BridgeHistoryTable';

import {
  useDigitalAssets,
  useDigitalAssetsBridge,
  useDigitalAssetsCaFFee,
} from 'modules/tt-digital-assets-provider';
import { showError } from 'utils/common';

import styles from './styles.module.scss';
import ReceiveModal from './ReceiveModal';
import TransferModal from './TransferModal';
import TransferBlock from './TransferBlock';
import ReceiveBlock from './ReceiveBlock';

const { Tabs } = ANTD_COMPONENTS;

const Bridge = (props) => {
  const { className, showedTab, setShowedTab, setShowSwitchDialog, setToNet } = props;

  const { chainId, chainSettings, toBN, isBN, isNaN, isAddress, fromWei, toWei } =
    useDigitalAssets();
  const { bridges, bridge, getBridge, bridgeSwap, bridgeRedeem, getBridgeOperationsToReceive } =
    useDigitalAssetsBridge();
  const { getCO2TokenCost } = useDigitalAssetsCaFFee();

  const [operation, setOperation] = useState('send');
  const [netToInd, setNetToInd] = useState('');
  const [bridgeTo, setBridgeTo] = useState(null);
  const [localBridge, setLocalBridge] = useState(null);
  const [transferBridgesTo, setTransferBridgesTo] = useState([]);
  const [co2TokenCost, setCO2TokenCost] = useState(null);
  const [sendAmount, setSendAmount] = useState('');
  const [sendAmountError, setSendAmountError] = useState('required field');
  const [sendTotalAmount, setSendTotalAmount] = useState(toBN(0));
  const [sendTotalAmountStr, setSendTotalAmountStr] = useState('-');

  const [sentBntActive, setSentBntActive] = useState(false);
  const [getBntActive, setGetBntActive] = useState(false);

  const [showSendConfirm, setShowSendConfirm] = useState(false);
  const [showReceiveConfirm, setShowReceiveConfirm] = useState(false);

  const [operationsToReceive, setOperationsToReceive] = useState([]);
  const [operationToReceive, setOperationToReceive] = useState(null);
  const [operationToReceiveInd, setOperationToReceiveInd] = useState(null);

  const [tableForceUpdate, setTableForceUpdate] = useState(0);
  const [menu, setMenu] = useState([
    { id: 'send', caption: 'Transmit' },
    { id: 'get', caption: 'Receive', disabled: true },
  ]);

  const [retrievedRequest, setRetrievedRequest] = useState({});
  const [render, setRender] = useState(false);

  const changeSentAmount = async (evnt) => {
    let val = String(evnt.target.value);

    if (parseFloat(val) > 5300000000) return;

    setSendAmount(val);

    if (/^(0\.\d{1,18}|[1-9]\d{0,9}(?:\.\d{1,18})?)$/gim.test(val)) {
      val = toBN(toWei(val || '0'));
      if (!isBN(val)) {
        setSendAmountError('is not valid amount');
        setSentBntActive(false);
      } else {
        setSendAmountError('');

        setSentBntActive(parseInt(netToInd, 10) >= 0);
      }
    } else {
      setSendAmountError('is not valid amount');
      setSentBntActive(false);
    }
  };

  const checkAmount = (amount) => /^\d+(?:\.\d{1,18})?$/gim.test(amount);

  /**
   * Обработчик селекта смены сети назначения
   * @param evnt
   */
  const selectTransferNetToHandler = (value) => {
    const ind = parseInt(value, 10);
    setNetToInd(!isNaN(ind) ? String(ind) : '');
    setBridgeTo(transferBridgesTo[ind]);
    setSentBntActive(ind > 0 && checkAmount(sendAmount));
  };

  const swapConfirm = async () => {
    let fee = 0;
    if (chainId === chainSettings.chainId) {
      try {
        fee = await getCO2TokenCost();
      } catch (err) {
        console.error(err);
      }
    }

    setCO2TokenCost(fee);
    const ttl = fromWei(toWei(sendAmount || 0) + toBN(fee || 0));
    setSendTotalAmount(ttl);
    setSendTotalAmountStr(ttl.toString());
    setShowSendConfirm(true);
  };

  const redeemConfirm = async () => {
    if (operationToReceive) {
      let fee = 0;
      if (chainId === chainSettings.chainId) {
        try {
          fee = await getCO2TokenCost();
        } catch (err) {
          console.error(err);
        }
      }

      setCO2TokenCost(fee);
      const ttl = toBN(operationToReceive.amount_from) - toBN(fee || 0);
      setOperationToReceive((op) => {
        op.amount_to_full = ttl;
        op.amount_to_full_str = fromWei(ttl || '0');
        op.fee_to = fee;
        op.fee_to_str = fromWei(fee || '0');

        return op;
      });
      setShowReceiveConfirm(true);
    }
  };

  /**
   * Отправка перевода
   * @return {Promise<*|void>}
   */
  const swap = async () => {
    try {
      setShowSendConfirm(false);
      setSendAmount('');
      setSendAmountError('is not valid amount');
      setSentBntActive(false);

      const _bFrom = bridges.find((el) => el?.chain_id === parseInt(chainId, 16))?.id;
      const _bTo = bridgeTo?.id;

      const _bridgeFrom = await getBridge(_bFrom);
      const _bridgeTo = await getBridge(_bTo);

      const tokenFrom = _bridgeFrom.wrap_addr;
      const tokenTo = _bridgeTo.wrap_addr;

      if (tokenFrom && tokenTo) {
        console.log('swap:');
        console.dir({
          chainIdTo: _bridgeTo.chain_id,
          tokenFrom: tokenFrom,
          tokenTo: tokenTo,
          amount: toWei(sendAmount || 0),
          amount_full: toWei(sendTotalAmount || 0),
        });

        return bridgeSwap({
          chainIdTo: _bridgeTo.chain_id,
          tokenFrom: tokenFrom,
          tokenTo: tokenTo,
          amount: toWei(sendAmount || 0),
          amount_full: toWei(sendTotalAmount || 0),
        }).then(() => {
          setTableForceUpdate((el) => ++el);
          getBridgeOperationsToReceive().then((data) => {
            setOperationsToReceive(data);
            setMenu((m) => {
              const mm = m?.find((el) => el?.id === 'get');
              if (mm) {
                mm.disabled = !data || data.length === 0;
              }
              return m;
            });
          });
        });
      }

      return showError(`Error!!! tokenFrom: ${tokenFrom}; tokenTo: ${tokenTo}`);
    } catch (e) {
      return showError(`Error!!! ${e}}`);
    }
  };

  /**
   * Подтверждение получения перевода
   * @return {Promise<*>}
   */
  const redeem = async () => {
    setOperationToReceiveInd(null);
    setShowReceiveConfirm(false);

    if (
      operationToReceive?.net_from >= 0 &&
      isAddress(operationToReceive?.addr_from) &&
      isAddress(operationToReceive?.addr_to) &&
      isBN(operationToReceive?.amount_from) &&
      operationToReceive?.nonce >= 0 &&
      operationToReceive?.signature
    ) {
      return bridgeRedeem({
        amount: operationToReceive?.amount_from,
        chainIdFrom: operationToReceive?.net_from,
        tokenFrom: operationToReceive?.addr_from,
        tokenTo: operationToReceive?.addr_to,
        nonce: operationToReceive?.nonce,
        signature: operationToReceive?.signature,
      })
        .then(() => {
          setTableForceUpdate((el) => ++el);
          getBridgeOperationsToReceive().then((data) => {
            setOperationsToReceive(data);
            setMenu((m) => {
              const mm = m?.find((el) => el?.id === 'get');
              if (mm) {
                mm.disabled = !data || data.length === 0;
              }
              return m;
            });

            if (data.length === 0) {
              setOperation('send');
            }
          });
          setOperationToReceive(null);
        })
        .catch(() => setOperationToReceive(null));
    }
    showError(
      `Error!!! net_from: ${operationToReceive?.net_from}; addr_from: ${operationToReceive?.addr_from}; addr_to: ${operationToReceive?.addr_to}; amount_from: ${operationToReceive?.amount_from}; nonce: ${operationToReceive?.nonce}; signature: ${operationToReceive?.signature}`
    );
  };

  const receiveOperationHandle = async (value) => {
    const ind = parseInt(value, 10);
    setOperationToReceiveInd(!isNaN(ind) ? String(ind) : null);
    const oper = operationsToReceive[ind];

    if (oper) {
      let fee = toBN(0);
      if (chainId === chainSettings.chainId) {
        try {
          fee = toBN(await getCO2TokenCost());
        } catch (err) {
          console.error(err);
        }
      }

      oper.amount_from = toBN(oper.amount_from);
      oper.amount_from_str = fromWei(oper.amount_from);

      oper.fee_from = toBN(oper.fee_from);
      oper.fee_from_str = fromWei(oper.fee_from);

      oper.amount_from_full = oper.amount_from + oper.fee_from; // Для отправителя комиссия всегда сверху
      oper.amount_from_full_str = fromWei(oper.amount_from_full);

      oper.amount_to = oper.amount_from;
      oper.amount_to_str = fromWei(oper.amount_from);
      oper.fee_to = fee;
      oper.fee_to_str = fromWei(fee);

      oper.amount_to_full =
        parseInt(chainSettings.chainId, 16) === oper.net_to
          ? oper.amount_to - oper.fee_to // Для получателя в локальной сети,Ю комиссия внутри, для глобальной
          : // - отдельная. Сверху
            oper.amount_to;
      oper.amount_to_full_str = fromWei(oper.amount_to_full);
    }

    setOperationToReceive(oper);
    setGetBntActive(!!oper);
  };

  const receiveOperationTableClick = async (data) => {
    data = data || {};
    data.net_from = parseInt(data.net_from, 10);
    data.net_to = parseInt(data.net_to, 10);
    data.nonce = parseInt(data.nonce, 10);
    data.amount_from = toWei(data?.amount_from);
    data.amount_from_full = toWei(data?.amount_from_full);
    data.fee_from = toBN(data?.fee_from);

    if (
      !isNaN(data.net_from) &&
      !isNaN(data.net_to) &&
      !isNaN(data.nonce) &&
      isAddress(data.addr_from) &&
      isAddress(data.addr_to) &&
      isBN(data.amount_from) &&
      data.signature
    ) {
      let fee = toBN(0);
      if (chainId === chainSettings.chainId) {
        try {
          fee = toBN(await getCO2TokenCost());
        } catch (err) {
          console.error(err);
        }
      }

      data.amount_from_str = fromWei(data.amount_from);
      data.amount_from_full = data.amount_from + toBN(data.fee_from || 0); // Для отправителя комиссия всегда
      // сверху
      data.amount_from_full_str = fromWei(data.amount_from_full);
      data.fee_from_str = fromWei(data.fee_from);

      data.amount_to = data.amount_from;
      data.amount_to_str = fromWei(data.amount_from);
      data.fee_to = fee;
      data.fee_to_str = fromWei(fee);

      data.amount_to_full =
        parseInt(chainSettings.chainId, 16) === data.net_to
          ? data.amount_to - data.fee_to // Для получателя в локальной сети,Ю комиссия внутри, для
          : // глобальной - отдельная. Сверху
            data.amount_to;
      data.amount_to_full_str = fromWei(data.amount_to_full);

      setOperationToReceive(data);

      if (data.net_to !== parseInt(chainId, 16)) {
        // Требуем переключение сети, если это надо
        const toID = (bridges || []).find((el) => el.chain_id === data.net_to)?.id;
        setToNet(toID);
        setShowSwitchDialog(true);
      } else {
        // Сразу показываем подтверждение операции
        setShowReceiveConfirm(true);
      }
    } else {
      showError('Not have required field to recieeve');
      console.table(data);
    }
  };

  const setRecieveBridge = () => {
    const currChainId = parseInt(chainId, 16);

    const locChId = parseInt(chainSettings.chainId, 16);
    setLocalBridge(bridges.find((el) => el?.chain_id === locChId));

    const bbb = bridges.filter((el) => el?.chain_id !== currChainId);
    setTransferBridgesTo(bridges.filter((el) => el?.chain_id !== currChainId));
    if (bbb.length < 2) {
      // Два моста, скрываем выбор моста назначения
      setNetToInd('0');
      setBridgeTo(bbb[0]);
    }

    // Получаем список операций на получение
    getBridgeOperationsToReceive().then((data) => {
      setOperationsToReceive(data);
      setMenu((m) => {
        const mm = m?.find((el) => el?.id === 'get');
        if (mm) {
          mm.disabled = !data || data.length === 0;
        }
        return m;
      });

      if (data.length === 0) {
        setOperation('send'); // Принудительно переключаем вкладку
      }
    });

    setShowedTab(`bridge.${bridge?.id}`);
  };

  useEffect(() => {
    (async () => {
      const locChId = parseInt(chainSettings.chainId, 16);
      setLocalBridge((bridges || []).find((el) => el?.chain_id === locChId));

      getBridgeOperationsToReceive().then((data) => {
        setOperationsToReceive(data);
        setMenu((m) => {
          const mm = m?.find((el) => el?.id === 'get');
          if (mm) {
            mm.disabled = !data || data.length === 0;
          }
          return m;
        });
      });
    })();
  }, [bridges, bridge]);

  useEffect(() => {
    setRecieveBridge();
  }, [chainId, bridges]);

  useEffect(() => {
    if (operation === 'get') {
      getBridgeOperationsToReceive().then((data) => {
        setOperationsToReceive(data);
        setMenu((m) => {
          const mm = m?.find((el) => el?.id === 'get');
          if (mm) {
            mm.disabled = !data || data.length === 0;
          }
          return m;
        });
      });
    } else {
      setOperationToReceiveInd(null);
    }
  }, [operation, chainId]);

  useEffect(() => {
    if (operation === 'get') {
      if (
        retrievedRequest?.netFrom &&
        retrievedRequest?.netTo &&
        retrievedRequest?.addrFrom &&
        retrievedRequest?.addrFrom &&
        retrievedRequest?.nonce
      ) {
        if (retrievedRequest.netTo === parseInt(chainSettings.chainId, 16)) {
          // Ищем нужную операцию
          const i = operationsToReceive.indexOf(
            (el) =>
              el?.netFrom === retrievedRequest.netFrom &&
              el?.netTo === retrievedRequest.netTo &&
              el?.addrFrom === retrievedRequest.addrFrom &&
              el?.addrTo === retrievedRequest.addrTo &&
              el?.nonce === retrievedRequest.nonce
          );
          if (i >= 0) {
            setOperationToReceiveInd(String(i));
            setOperationsToReceive(operationsToReceive[i]);
          } else {
            operationToReceiveInd('');
          }
        }
      }

      getBridgeOperationsToReceive().then((data) => {
        setOperationsToReceive(data);
        setMenu((m) => {
          m.get.disabled = data.length === 0;
          return m;
        });
      });
    }
  }, [retrievedRequest]);

  useEffect(() => {
    setTimeout(() => {
      setRender(true);
    }, 10);
  }, []);

  return !render ? (
    <div className={styles.loaderWrapper}>
      <Loader />
    </div>
  ) : (
    <div className={`Bridge ${styles.Bridge} ${className || ''}`}>
      <div className={clsx(styles.row1, 'row1')}>
        <Tabs
          className={styles.tabs}
          items={menu.map((item) => ({
            key: item.id,
            label: item.caption,
            disabled: item.disabled,
            children: null,
          }))}
          activeKey={operation}
          onChange={(key) => {
            setOperation(key);
          }}
          animated={false}
        />
      </div>

      {operation === 'send' ? (
        <TransferBlock
          bridgeTo={bridgeTo}
          sendAmountError={sendAmountError}
          sendAmount={sendAmount}
          changeSentAmount={changeSentAmount}
          transferBridgesTo={transferBridgesTo}
          sentBntActive={sentBntActive}
          netToInd={netToInd}
          selectTransferNetToHandler={selectTransferNetToHandler}
          swapConfirm={swapConfirm}
        />
      ) : (
        <ReceiveBlock
          operationToReceive={operationToReceive}
          localBridge={localBridge}
          operationToReceiveInd={operationToReceiveInd}
          receiveOperationHandle={receiveOperationHandle}
          operationsToReceive={operationsToReceive}
          redeemConfirm={redeemConfirm}
          getBntActive={getBntActive}
        />
      )}

      {/* History */}
      <div className={clsx(styles.row3, 'row3')} style={{ marginTop: '10px' }}>
        <div style={{ overflow: 'auto' }}>
          <BridgeHistoryTable
            forceUpdate={tableForceUpdate}
            showedTab={showedTab}
            setShowedTab={setShowedTab}
            receiveOperationHandle={receiveOperationTableClick}
          />
        </div>
      </div>

      <TransferModal
        showSendConfirm={showSendConfirm}
        setSendAmount={setSendAmount}
        setSendAmountError={setSendAmountError}
        setSentBntActive={setSentBntActive}
        setShowSendConfirm={setShowSendConfirm}
        bridgeTo={bridgeTo}
        bridges={bridges}
        sendAmount={sendAmount}
        co2TokenCost={co2TokenCost}
        sendTotalAmountStr={sendTotalAmountStr}
        swap={swap}
      />

      <ReceiveModal
        showReceiveConfirm={showReceiveConfirm}
        operationToReceive={operationToReceive}
        setOperationToReceiveInd={setOperationToReceiveInd}
        setShowReceiveConfirm={setShowReceiveConfirm}
        setOperationToReceive={setOperationToReceive}
        chainSettings={chainSettings}
        redeem={redeem}
      />
    </div>
  );
};

export default Bridge;
