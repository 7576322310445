export const convertBlockchainTimestampToDate = (timestamp) => {
  if (!timestamp || typeof timestamp !== 'number') return null;
  const convertedTimestamp = timestamp * 1000;

  return new Date(convertedTimestamp) || null;
};

export const convertDateToBlockchainTimestamp = (date) => {
  if (!date || !date?.getTime()) return null;
  const timestamp = date.getTime();

  return timestamp / 1000 || null;
};
