import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Input, Select, openNotification, Tooltip } from 'tt-ui-lib/core';
import { SearchIcon, SortAscIcon, SortDescIcon, CopyIcon } from 'tt-ui-lib/icons';

import styles from './styles.module.scss';
import { AssetsTab } from 'modules/tt-tokenized-assets/index';
import { useNavigate } from 'react-router-dom';
import { sendBlockchainApiRequest } from '../../../api/blockchain/endpoints/blockcahin-da-api';
import { useDigitalAssets } from 'modules/tt-digital-assets-provider';
import FCEMLogo from './FCEMIcon';

const TokenizationAssets = (props) => {
  const { className, style } = props;

  const navigate = useNavigate();
  const { account } = useDigitalAssets();

  const [first, setFirst] = useState('old');
  const [loading, setLoading] = useState(false);
  const [CaFFees, setCaFFees] = useState([]);

  const getTableData = () => {
    setLoading(true);

    const payload = {
      id: 'getTokenizationAssetsData',
      method: 'getCarbonContracts',
      wallet: account,
    };

    sendBlockchainApiRequest(payload)
      .then((response) => {
        setCaFFees(response?.data?.result || []);
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: 'Error while getting credit data',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onAssetsRowClick = (row) => {
    navigate(`/dashboard/certificate/${row.id}`);
  };

  const getterHash = (val) =>
    val && val.length > 10
      ? `${val.substring(0, 5)}...${val.substring(val.length - 3)}`
      : val || '-';

  const copyToClipboard = (test) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(test).then(
        () => openNotification({ message: 'Success copied to clipboard', type: 'success' }),
        (err) =>
          openNotification({ message: `Could not copy text: ${err?.message}`, type: 'error' })
      );
    } else {
      openNotification({ message: "Can't copy to clipboard", type: 'error' });
    }
  };

  const columns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'Certificate ID',
      align: 'left',
      width: 50,
      render: (value, row) => (
        <div className={styles.idCell}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            <CopyIcon
              style={{ width: 16, height: 16, cursor: 'pointer', zIndex: 10 }}
              onClick={(e) => {
                if (navigator.clipboard) {
                  e.stopPropagation();
                  copyToClipboard(value);
                }
              }}
            />

            <span>{getterHash(value) || '-'}</span>

            {row.deposit && row.deposit !== '0' && (
              <Tooltip title="With FCEM deposit" placement="top" trigger="hover">
                <span>
                  <FCEMLogo />
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      ),
    },
    {
      key: 'ts',
      dataIndex: 'ts',
      title: 'Purchasing date',
      align: 'left',
      width: 50,
      render: (val) => <span>{`${val}`.split('T')[0] || '-'}</span>,
    },
    {
      key: 'carbon_balance',
      dataIndex: 'carbon_balance',
      title: 'Credit Balance',
      align: 'left',
      width: 50,
      render: (val) => <span>{val || '-'}</span>,
    },
    {
      key: 'offset_balance',
      dataIndex: 'offset_balance',
      title: 'Offset Balance',
      align: 'left',
      width: 50,
      render: (val) => <span>{val || '-'}</span>,
    },
  ];

  useEffect(() => {
    if (account) {
      getTableData();
    }
  }, [account]);

  return account ? (
    <>
      <div className={styles.filters}>
        <Input
          label="Search by Certificate ID"
          className={styles.searchFilter}
          prefix={<SearchIcon style={{ width: 20, height: 20 }} />}
        />

        <Select
          label="Date"
          name="date"
          value={null}
          className={styles.topSelect}
          options={[{ value: '1', label: 'Date' }]}
        />

        <div className={styles.sort}>
          {first === 'new' ? (
            <div onClick={() => setFirst('old')}>
              <SortAscIcon style={{ width: 24, height: 24 }} />
              <span>New first</span>
            </div>
          ) : (
            <div onClick={() => setFirst('new')}>
              <SortDescIcon style={{ width: 24, height: 24 }} />
              <span>Old first</span>
            </div>
          )}
        </div>
      </div>

      <div
        className={`Holders ${styles.TokenizationAssets} ${className || ''}`}
        style={{ ...style }}
      >
        <div className={clsx(styles.row3, 'row3')}>
          <div style={{ overflow: 'auto' }}>
            <AssetsTab
              data={CaFFees.filter(
                (item, index, self) => index === self.findIndex((t) => t.id === item.id)
              )}
              onRowClick={onAssetsRowClick}
              isLoading={loading}
              customColumns={columns}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className={styles.emptyContainer}>
      To view your assets, please connect your MetaMask wallet.
    </div>
  );
};

export default TokenizationAssets;
