import { Modal } from 'tt-ui-lib/core';
import ModalContainer from 'components/ModalContainer';
import styles from './VerifyEmailModal.module.scss';

const VerifyEmailModal = ({
  isModelOpen,
  setIsOpen,
  handleClose,
  title,
  text,
  subText,
  isDashboard = false,
}) =>
  isDashboard ? (
    <Modal open={isModelOpen} onClose={() => handleClose(setIsOpen)} title={title}>
      <div className={styles.dashboardModalBody}>
        <div className={styles.text}>{text}</div>
        <div className={styles.text}>{subText}</div>
      </div>
    </Modal>
  ) : (
    <Modal
      open={isModelOpen}
      onClose={() => handleClose(setIsOpen)}
      BackdropProps={{
        onClick: (event, reason) => {
          if (reason === 'click') {
            event.stopPropagation();
          }
        },
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer
        background="rgba(255, 255, 255, 0.4)"
        handleClose={() => handleClose(setIsOpen)}
      >
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
        <div className={styles.text}>{subText}</div>
      </ModalContainer>
    </Modal>
  );

export default VerifyEmailModal;
