import React from 'react';
import styles from './styles.module.scss';
import { HelpTooltip, Table, Tooltip, openNotification } from 'tt-ui-lib/core';
import { CopyIcon } from 'tt-ui-lib/icons';
import FCEMLogo from '../CertificatePage/Components/FcemLogo';

const Assets = ({
  className,
  style,
  data,
  onRowClick,
  customColumns = null,
  isLoading = false,
  description = null,
}) => {
  const getterHash = (val) =>
    val && val.length > 10
      ? `${val.substring(0, 5)}...${val.substring(val.length - 3)}`
      : val || '-';

  const copyToClipboard = (test) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(test).then(
        () => openNotification({ message: 'Success copied to clipboard', type: 'success' }),
        (err) =>
          openNotification({ message: `Could not copy text: ${err?.message}`, type: 'error' })
      );
    } else {
      openNotification({ message: "Can't copy to clipboard", type: 'error' });
    }
  };

  const columns = [
    {
      key: 'credit_id',
      dataIndex: 'credit_id',
      title: 'Certificate ID',
      align: 'left',
      width: 50,
      render: (value, row) => (
        <div className={styles.idCell}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            <CopyIcon
              style={{ width: 16, height: 16, cursor: 'pointer', zIndex: 10 }}
              onClick={(e) => {
                if (navigator.clipboard) {
                  e.stopPropagation();
                  copyToClipboard(value);
                }
              }}
            />
            <span>{getterHash(value) || '-'}</span>
          </div>

          {row.deposit && row.deposit !== '0' && (
            <Tooltip title="With FCEM deposit" placement="top" trigger="hover">
              <span>
                <FCEMLogo />
              </span>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: 'Purchasing date',
      align: 'left',
      width: 50,
      render: (val) => <span>{val || '-'}</span>,
    },
    {
      key: 'quantity',
      dataIndex: 'quantity',
      title: 'Credit Balance',
      align: 'left',
      width: 50,
      render: (val) => <span>{val || '-'}</span>,
    },
    {
      key: 'offset',
      dataIndex: 'offset',
      title: 'Offset Balance',
      align: 'left',
      width: 50,
      render: (val) => <span>{val || '-'}</span>,
    },
  ];

  return (
    <div className={`${styles.container} ${className} HoldersTable`} style={{ ...style }}>
      <div className={styles.row1}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span className={`${styles.h1} ${styles.header}`} style={{ marginBottom: 0 }}>
            CAFFEE
          </span>
          <HelpTooltip tooltipText="All your digitized carbon credits and certificates you can find in this section. Clicking on the certificate, you get access to detailed information and more options." />
        </div>
      </div>

      {description && <div className={styles.desc}>{description}</div>}

      <div style={{ width: '100%', overflowX: 'auto' }}>
        <Table
          loading={isLoading}
          columns={customColumns || columns}
          data={data}
          onRow={(row) => ({
            onClick: () => onRowClick(row),
          })}
          scroll={{ x: 870, y: '42vh' }}
          pagination={{
            position: ['bottomCenter'],
            responsive: true,
            showSizeChanger: false,
            hideOnSinglePage: true,
          }}
        />
      </div>
    </div>
  );
};

export default Assets;
