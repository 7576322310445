import React from 'react';
import clsx from 'clsx';
import styles from '../styles.module.scss';
import { Button, Select } from 'tt-ui-lib/core';
import { useDigitalAssets } from 'modules/tt-digital-assets-provider';

const ReceiveBlock = ({
  operationToReceive,
  localBridge,
  operationToReceiveInd,
  receiveOperationHandle,
  operationsToReceive,
  redeemConfirm,
  getBntActive,
}) => {
  const { fromWei } = useDigitalAssets();

  return (
    <div className={clsx(styles.row3, 'row3')} style={{ marginTop: '10px' }}>
      <div>
        {`To complete the transfer of your FCEMs from ${
          operationToReceive?.net_from_name || 'selected net'
        } to the ${localBridge?.name || 'local net'}, select
          the incomplete transaction in the Select Requests field and confirm it with the Receive
          button. After that, you need to sign this transaction via your Metamask wallet.Please
          remember that you need to have enough ETH on your balance to pay for the gas and complete
          this transaction.`}
      </div>
      <div style={{ fontWeight: 'bold', marginTop: '5px' }}>
        Stay up-to-date with the latest requests by simply clicking the refresh button next to the
        table name.
      </div>
      <div className={styles.receiveSelectBlock}>
        <Select
          label="Select request"
          value={operationToReceiveInd}
          onChange={receiveOperationHandle}
          options={Object.keys(operationsToReceive).map((id) => {
            const op = operationsToReceive[id];
            return {
              value: String(id),
              label: `${fromWei(op.amount_from || 0)} FCEMETH - ${op.date_from.substring(0, 19).replace('T', ' ')}`,
            };
          })}
          style={{ width: '100%' }}
          allowClear
        />
        <div>
          <Button type="primary" onClick={redeemConfirm} disabled={!getBntActive}>
            Receive
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReceiveBlock;
