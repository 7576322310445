import React, { useEffect, useState } from 'react';
import { Button, Input, Select, LoaderOn } from 'tt-ui-lib/core';
import { EditIcon, VisibleIcon, InvisibleIcon } from 'tt-ui-lib/icons';
import styles from '../FceAdmin.module.scss';
import { useDigitalAssetsTTAPI } from 'modules/tt-digital-assets-provider';

const SettingsItem = ({
  itemData,
  contract,
  version,
  ttTypes,
  setFieldId,
  setFieldModalOpen,
  setFetchData,
  setFieldName,
}) => {
  const { setSettingsField } = useDigitalAssetsTTAPI();

  const [data, setData] = useState(itemData);
  const [loadingAction, setLoadingAction] = useState(false);

  const saveSettings = () => {
    setLoadingAction(true);

    const parsedData = {
      ...data,
      method_name: data.method,
      contract: contract,
      version: version,
      method: undefined,
    };

    setSettingsField(parsedData, true)
      .then((res) => {
        if (res !== null) setFetchData(true);
      })
      .finally(() => {
        setLoadingAction(false);
      });
  };

  const changeField = (field, value) => {
    setData((prevData) => ({ ...prevData, [field]: value }));
  };

  useEffect(() => {
    setData(itemData);
  }, [itemData]);

  return (
    <div className={styles.settingsModalAccordionContent}>
      {loadingAction && <LoaderOn />}

      <div className={styles.settingsModalAccordionContentRow}>
        <Input
          label="Name"
          value={data?.caption || ''}
          onChange={(e) => {
            changeField('caption', e.target.value);
          }}
        />
        <Input
          label="Hint"
          value={data?.hint || ''}
          onChange={(e) => {
            changeField('hint', e.target.value);
          }}
        />
        <Select
          label="Type"
          value={data?.tt_type || ''}
          options={ttTypes || []}
          onChange={(value) => {
            changeField('tt_type', value);
          }}
        />

        <Button
          type="icon"
          onClick={() => {
            setFieldId(itemData?.method_hash);
            setFieldName(itemData?.caption);
            setFieldModalOpen(true);
          }}
        >
          <EditIcon className="icon-blue-fill" style={{ width: 24, height: 24 }} />
        </Button>
      </div>

      <div className={styles.settingsModalAccordionContentBtns}>
        <Button
          type="default"
          onClick={(e) => {
            e.stopPropagation();
            setData(itemData);
          }}
        >
          Reset
        </Button>

        <div className={styles.settingsModalAccordionContentBtnsRow}>
          <Button
            type="icon"
            onClick={(e) => {
              e.stopPropagation();
              setData((prevData) => ({ ...prevData, visible: !data.visible }));
            }}
          >
            {data?.visible ? (
              <VisibleIcon className="icon-blue-fill" style={{ width: 20, height: 20 }} />
            ) : (
              <InvisibleIcon className="icon-blue-fill" style={{ width: 20, height: 20 }} />
            )}
          </Button>
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              saveSettings();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SettingsItem;
