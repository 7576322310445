import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from 'components/Layout/PageContainer';
import { useWindowSize } from '../../../../../utils/useWindowSize';
import Header from 'components/Layout/Header';
import VideoBg from 'components/Layout/VideoBg';
import { Modal } from 'tt-ui-lib/core';
import { DownloadIcon } from 'tt-ui-lib/icons';
import { useDigitalAssetsCaFFee } from 'modules/tt-digital-assets-provider';

import CertificatePNG from '../../assets/co2_certificate_16714506896614.png';
import styles from '../../private/MainCertificate/styles.module.scss';

const { REACT_APP_SSO_API } = process.env;
const MAX_MOBILE_WIDTH = 678;

const Certificate = () => {
  const endpointTT = process.env.REACT_APP_TT || '';

  const windowSize = useWindowSize();
  const navigate = useNavigate();

  const { getCO2CertInfo } = useDigitalAssetsCaFFee();

  const [certJPGURL, setCertJPGURL] = useState('');
  const [certPDFURL, setCertPDFURL] = useState('');
  const [dialogOpen, setDialogOpen] = useState(true);

  const { uid } = useParams();

  const [currentTonns, setCurrentTonns] = useState('');
  const [currentTokens, setCurrentTokens] = useState('');
  const [offsettedTonns, setOffsettedTonns] = useState('');
  const [offsettedTokens, setOffsettedTokens] = useState('');

  useEffect(() => {
    const func = async () => {
      if (
        /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(uid)
      ) {
        // Персональный сертификат (2f03b363-f605-4232-8133-81e2dec81c40)
        setCertJPGURL(`${REACT_APP_SSO_API}/wallet/personal_certificate/jpg?id=${uid}`);
        setCertPDFURL(`${REACT_APP_SSO_API}/wallet/personal_certificate/pdf?id=${uid}`);
      } else if (/^0x[a-fA-F0-9]{40}$/.test(uid)) {
        setCertJPGURL('');

        // Базовый Сертификат (0x55456e0bd0e46ec4276eac51cfc281d39e2cd449)
        if (getCO2CertInfo) {
          const certInfo = await getCO2CertInfo(uid);
          setCertPDFURL(certInfo?.URL);

          const initTokens = parseInt(certInfo?.initTokens, 10) || 0;
          const freeTokens = parseInt(certInfo?.freeTokens, 10) || 0;

          const tonns = parseInt(certInfo?.value, 10) || 0;
          const offTonns = (initTokens - freeTokens) * 0.000000841775642;
          const freeTonns = tonns - offTonns;

          setCurrentTonns(freeTonns.toFixed(6));
          setCurrentTokens(freeTokens.toFixed(0));

          setOffsettedTonns(offTonns.toFixed(6));
          setOffsettedTokens((initTokens - freeTokens).toFixed(0));
        } else {
          setCertPDFURL(
            'https://dev-calc-tt.s3.eu-central-1.amazonaws.com/certificates/SouthPole-Certificate19122022.pdf'
          ); // TODO: пока хардкод. Надо будет думать как именно получать ЭТУ ссылку
        }
      } else {
        // Сертификат транзакции
        setCertJPGURL(`${REACT_APP_SSO_API}/wallet/current_certificate/jpg?transaction=${uid}`);
      }
    };

    func().then();
  }, []);

  return (
    <PageContainer>
      <Header redirectDomain={endpointTT} />
      <VideoBg
        src="/video/start-bg.mp4"
        srcImg="/images/start-body-bg.jpg"
        isAutoPlay={windowSize.width > MAX_MOBILE_WIDTH}
      />
      <Modal
        open={dialogOpen}
        onClose={() => {
          navigate(`/`);
        }}
        title="Certificate"
        closeOnlyByControls
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 5px' }}>
          <DownloadIcon
            style={{ width: 24, height: 24, cursor: 'pointer' }}
            onClick={() => window.open(certPDFURL)}
          />
        </div>
        {certJPGURL ? (
          <img
            src={certJPGURL}
            alt=""
            style={{ width: '100%', maxWidth: '700px', position: 'relative', top: 0, left: 0 }}
          />
        ) : (
          <>
            {currentTonns ? (
              <div
                className={clsx(styles.col1_1, 'col1_1')}
                style={{ fontWeight: 'bold', marginBottom: '10px' }}
              >
                Current balance: {currentTonns} tonne / {currentTokens} tokens
              </div>
            ) : (
              ''
            )}
            {offsettedTonns ? (
              <div
                className={clsx(styles.col1_1, 'col1_1')}
                style={{ fontWeight: 'bold', marginBottom: '10px' }}
              >
                Offsetted: {offsettedTonns} tonne / {offsettedTokens} tokens
              </div>
            ) : (
              ''
            )}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={CertificatePNG}
                alt=""
                style={{
                  width: window.innerWidth > 875 ? 564 : 300,
                  height: window.innerWidth > 875 ? 500 : 173,
                }}
              />
            </div>
          </>
        )}
      </Modal>
    </PageContainer>
  );
};

export default Certificate;
