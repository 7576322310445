import React, { useEffect, useState } from 'react';
import { Modal, Loader, LoaderOn, Collapse } from 'tt-ui-lib/core';
import { clsx } from 'clsx';
import styles from '../FceAdmin.module.scss';
import { useDigitalAssetsTTAPI } from 'modules/tt-digital-assets-provider';
import FieldItem from './FieldItem';
import { RightIcon } from 'tt-ui-lib/icons';

const ExpandIcon = ({ isActive }) => (
  <RightIcon
    style={{
      display: 'block',
      marginTop: 10,
      width: 20,
      height: 20,
      transform: `rotate(${isActive ? 270 : 90}deg)`,
    }}
  />
);

const FieldModal = ({ open, setOpen, id, name, setName, openTupleModal, isTuple = false }) => {
  const {
    getFieldsList,
    getSettingsParamsListValues,
    getSettingsParamsList,
    getFieldsPropertiesList,
  } = useDigitalAssetsTTAPI();

  const [fieldSections, setFieldSections] = useState([]);
  const [fieldTypes, setFieldTypes] = useState([]);
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [secondLoading, setSecondLoading] = useState(false);
  const [fetchData, setFetchData] = useState(false);

  const getFieldData = (withMainLoading = true) => {
    let loadSetter = setLoading;
    if (!withMainLoading) loadSetter = setSecondLoading;

    loadSetter(true);

    const fetchLists = () => {
      getSettingsParamsListValues('TT_TYPE_PARAM', true)
        .then((resList) => {
          setFieldTypes(resList?.map((item) => ({ label: item.name, value: item.id })) || []);

          getSettingsParamsList({}, true)
            .then((resLists) => {
              setLists(resLists?.map((item) => ({ label: item.name, value: item.id })) || []);
            })
            .finally(() => {
              loadSetter(false);
            });
        })
        .catch(() => {
          loadSetter(false);
        });
    };

    if (isTuple) {
      try {
        getFieldsPropertiesList(id?.param, id?.id, true)
          .then((res) => {
            setFieldSections([]);
            setFieldSections(res || []);

            fetchLists();
          })
          .catch(() => {
            loadSetter(false);
          });

        return;
      } catch (e) {
        loadSetter(false);
        setOpen(false);
      }
    }

    getFieldsList(id, true)
      .then((res) => {
        setFieldSections([]);
        setFieldSections(res || []);

        fetchLists();
      })
      .catch(() => {
        loadSetter(false);
        setOpen(false);
      });
  };

  useEffect(() => {
    if (open && id) {
      getFieldData();
    }
  }, [open]);

  useEffect(() => {
    if (fetchData) {
      getFieldData(false);
      setFetchData(false);
    }
  }, [fetchData]);

  return (
    <Modal
      title={isTuple ? `${name || ''} Param settings` : `${name || ''} Field settings`}
      open={open}
      onClose={() => setOpen(false)}
      closeOnlyByControls
      destroyOnClose
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignContent: 'space-between',
        }}
      >
        {secondLoading && <LoaderOn />}
        {loading ? (
          <div
            style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Loader />
          </div>
        ) : (
          <div
            className={styles.accordionContent}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              marginTop: 20,
              marginBottom: 30,
            }}
          >
            <Collapse
              items={fieldSections.map((item, index) => ({
                key: index,
                label: isTuple ? item?.property || '-' : item?.param || '-',
                children: (
                  <div className={clsx(styles.accordionContent)} style={{ paddingTop: 0 }}>
                    <FieldItem
                      itemData={item}
                      fieldTypes={fieldTypes}
                      setFetchData={setFetchData}
                      lists={lists}
                      setSecondLoading={setSecondLoading}
                      openTupleModal={openTupleModal}
                      isTuple={isTuple}
                      currentFields={fieldSections}
                      setName={setName}
                    />
                  </div>
                ),
              }))}
              expandIcon={ExpandIcon}
              appearance="compact"
              accordion
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default FieldModal;
