import { Breadcrumbs, Link, Stack, NavigateNextIcon } from 'tt-ui-kit'; // TODO: remove
import React from 'react';
import { useNavigate } from 'react-router-dom';

import BackgroundPNG from 'assets/images/breadimage.png';

import styles from './Breadcrumb.module.scss';

export const Breadcrumb = (props) => {
  const { data } = props;
  const navigate = useNavigate();
  const handleClick = (event, href) => {
    event.preventDefault();
    navigate(`${href}`);
  };
  const breadcrumbs = [
    ...data.map((breadcrumb) => (
      <Link
        className={styles.breadcrumb}
        underline="hover"
        color="black"
        key={breadcrumb.href}
        onClick={(e) => handleClick(e, breadcrumb.href)}
        {...breadcrumb}
      >
        {breadcrumb.name}
      </Link>
    )),
  ];

  return (
    <Stack
      spacing={2}
      className={styles.container}
      style={{
        backgroundImage: `url("${BackgroundPNG}")`,
      }}
    >
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};
