import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Modal } from 'tt-ui-lib/core';
import { CopyIcon } from 'tt-ui-lib/icons';

import { useDigitalAssets, useDigitalAssetsBridge } from 'modules/tt-digital-assets-provider';
import { hideStandby, showError } from 'utils/common';

import CopyIconSVG from 'assets/images/copy.svg';

import styles from './styles.module.scss';

const OperationDetailModal = (props) => {
  const { className, style, showOperationDetail, setShowOperationDetail, bridgeOperationID } =
    props;

  const { chainSettings, copyToClipboard, getterHash, fromWei } = useDigitalAssets();
  const { getBridgeOperation, findBridgeOperation } = useDigitalAssetsBridge();

  const [operationInfo, setOperationInfo] = useState({
    wallet: '',
    hash_from: '',
    block_from: NaN,
    addr_from: '',
    date_from: '',
    net_from: NaN,
    amount_from: '',
    amount_from_full: '',
    fee_from: '',
    status_from: NaN,
    hash_to: '',
    block_to: NaN,
    addr_to: '',
    date_to: '',
    net_to: NaN,
    amount_to: '',
    amount_to_full: '',
    fee_to: '',
    status_to: NaN,
    nonce: NaN,
    hash_to_sign: '',
    signature: '',
    error: null,
    net_from_name: '',
    net_to_name: '',
    net_from_symbol: '',
    net_to_symbol: '',
  });
  const [showDialog, setShowDialog] = useState(false);

  const findOperation = async () => {
    let res;
    if (
      !!bridgeOperationID?.netFrom &&
      !!bridgeOperationID?.netTo &&
      !!bridgeOperationID?.addrFrom &&
      !!bridgeOperationID?.addrTo &&
      !!bridgeOperationID?.nonce
    ) {
      try {
        res = await getBridgeOperation(bridgeOperationID);
      } catch (err) {
        showError('Operation not found');
        setShowOperationDetail(false);
      } finally {
        hideStandby(true);
      }
    } else if (bridgeOperationID?.signature) {
      try {
        res = await findBridgeOperation({
          signature: bridgeOperationID?.signature,
        });
      } catch (err) {
        showError('Operation not found');
        setShowOperationDetail(false);
      } finally {
        hideStandby(true);
      }
    }

    /** @type {[Object]} */
    if (res?.hash_from || res?.hash_to) {
      setOperationInfo(res);
      setShowOperationDetail(true);
      setShowDialog(true);
    } else {
      showError('Operation not found');
      setShowOperationDetail(false);
    }
  };

  const getterSignature = (val) => `0x${val?.substring(0, 40)}...${val?.substring(110, 130)}`;

  const getterLocalDate = (d) => {
    if (d) {
      const dd = new Date(d);
      if (!Number.isNaN(dd?.getTime())) {
        return `${dd.getDate().toString().padStart(2, '0')}.${(dd.getMonth() + 1)
          .toString()
          .padStart(2, '0')}.${dd.getFullYear()} ${dd.getHours().toString().padStart(2, '0')}:${dd
          .getMinutes()
          .toString()
          .padStart(2, '0')}:${dd.getSeconds().toString().padStart(2, '0')}`;
      }
      return '';
    }
    return '';
  };

  useEffect(() => {
    if (showOperationDetail) {
      findOperation().then();
    }
  }, [showOperationDetail]);

  return (
    <Modal // Bridge transaction
      className={className}
      style={{ ...style }}
      open={showDialog}
      onClose={() => {
        setShowDialog(false);
        setShowOperationDetail(false);
      }}
      fullWidth
      title="Bridge transaction"
    >
      {operationInfo.hash_from || operationInfo.hash_to ? (
        <div className={clsx(styles.context, 'context')}>
          <div className={clsx(styles.h2, 'h2')}>{operationInfo?.net_from_name}</div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>Hash:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {window.innerWidth > 375
                ? operationInfo.hash_from
                : getterHash(operationInfo.hash_from)}
            </div>
            {operationInfo.hash_from ? (
              <CopyIcon
                style={{ width: 16, height: 16, cursor: 'pointer', marginLeft: '5px' }}
                onClick={() => {
                  copyToClipboard(operationInfo.hash_from);
                }}
              />
            ) : (
              ''
            )}
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>Date/time:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {getterLocalDate(operationInfo.date_from)}
            </div>
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>From:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {window.innerWidth > 375 ? operationInfo.wallet : getterHash(operationInfo.wallet)}
            </div>
            {operationInfo.wallet ? (
              <CopyIcon
                style={{ width: 16, height: 16, cursor: 'pointer', marginLeft: '5px' }}
                onClick={() => {
                  copyToClipboard(operationInfo.wallet);
                }}
              />
            ) : (
              ''
            )}
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>To:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {window.innerWidth > 375
                ? operationInfo.addr_from
                : getterHash(operationInfo.addr_from)}
            </div>
            {operationInfo.addr_from ? (
              <CopyIcon
                style={{ width: 16, height: 16, cursor: 'pointer', marginLeft: '5px' }}
                onClick={() => {
                  copyToClipboard(operationInfo.addr_from);
                }}
              />
            ) : (
              ''
            )}
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>Value:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {fromWei(operationInfo.amount_from || 0)}{' '}
              {window.innerWidth > 375
                ? operationInfo.amount_from
                  ? operationInfo.net_from_symbol
                  : ''
                : ''}
            </div>
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>
              {operationInfo.net_from === parseInt(chainSettings.chainId, 16) ? 'CaFFee' : 'TxN'}:
            </div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {window.innerWidth > 375
                ? operationInfo.net_from === parseInt(chainSettings.chainId, 16)
                  ? '1 CaFFee'
                  : operationInfo.fee_from
                    ? `${fromWei(operationInfo.fee_from || 0)} ${operationInfo.net_from_symbol}`
                    : ''
                : ''}
            </div>
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>Block number:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {operationInfo.block_from}
            </div>
          </div>

          <div className={clsx(styles.h2, 'h2')}>{operationInfo?.net_to_name}</div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>Hash:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {window.innerWidth > 375 ? operationInfo.hash_to : getterHash(operationInfo.hash_to)}
            </div>
            {operationInfo.hash_to ? (
              <CopyIcon
                style={{ width: 16, height: 16, cursor: 'pointer', marginLeft: '5px' }}
                onClick={() => {
                  copyToClipboard(operationInfo.hash_to);
                }}
              />
            ) : (
              ''
            )}
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>Date/time:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {getterLocalDate(operationInfo.date_to || '')}
            </div>
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>From:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {window.innerWidth > 375 ? operationInfo.addr_to : getterHash(operationInfo.addr_to)}
            </div>
            {operationInfo.addr_to ? (
              <CopyIcon
                style={{ width: 16, height: 16, cursor: 'pointer', marginLeft: '5px' }}
                onClick={() => {
                  copyToClipboard(operationInfo.addr_to);
                }}
              />
            ) : (
              ''
            )}
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>To:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {window.innerWidth > 375 ? operationInfo.wallet : getterHash(operationInfo.wallet)}
            </div>
            {operationInfo.wallet ? (
              <CopyIcon
                style={{ width: 16, height: 16, cursor: 'pointer', marginLeft: '5px' }}
                onClick={() => {
                  copyToClipboard(operationInfo.wallet);
                }}
              />
            ) : (
              ''
            )}
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>Value:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {fromWei(operationInfo.amount_to || 0)}{' '}
              {window.innerWidth > 375
                ? operationInfo.amount_to
                  ? operationInfo.net_to_symbol
                  : ''
                : ''}
            </div>
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>
              {operationInfo.net_to === parseInt(chainSettings.chainId, 16) ? 'CaFFee' : 'TxN'}:
            </div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {window.innerWidth > 375
                ? operationInfo.net_to === parseInt(chainSettings.chainId, 16)
                  ? '1 CaFFee'
                  : operationInfo.fee_to
                    ? `${fromWei(operationInfo.fee_to || 0)} ${operationInfo.net_to_symbol}`
                    : ''
                : ''}
            </div>
          </div>
          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div className={clsx(styles.infoFieldName, 'infoFieldName')}>Block number:</div>
            <div className={clsx(styles.infoFieldValue, 'infoFieldValue')}>
              {operationInfo.block_to}
            </div>
          </div>

          <div className={clsx(styles.infoRow, 'infoRow')}>
            <div
              className={clsx(styles.infoFieldName, 'infoFieldName')}
              style={{ fontWeight: 'bold' }}
            >
              Signature:
            </div>
            <div
              className={clsx(styles.infoFieldValue, 'infoFieldValue')}
              style={{ fontWeight: 'bold' }}
            >
              {getterSignature(operationInfo.signature)}
            </div>
            {operationInfo.signature ? (
              <CopyIcon
                style={{ width: 16, height: 16, cursor: 'pointer', marginLeft: '5px' }}
                onClick={() => {
                  copyToClipboard(operationInfo.signature);
                }}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      ) : operationInfo.hash_from && operationInfo.hash_to === '' ? (
        <div className={clsx(styles.h1, 'h1')}>The operation is requested...</div>
      ) : (
        <div>
          <div className={clsx(styles.h1, 'h1')}>Operation is not found</div>
        </div>
      )}
    </Modal>
  );
};

export default OperationDetailModal;
