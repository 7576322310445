import React from 'react';
import { Modal } from 'tt-ui-lib/core';

const ContractCompileResultModal = ({
  showCompileResultDialog,
  setShowCompileResultDialog,
  compileResult,
}) => (
  <Modal
    open={showCompileResultDialog}
    onClose={() => setShowCompileResultDialog(false)}
    title="Compile result"
    destroyOnClose
  >
    <div>
      <b>SOLC version:</b> {compileResult?.solc}
    </div>
    <div>Messages</div>
    <div>
      {compileResult?.errors?.map((error) => (
        <div>
          <div>
            <b>{error?.type}</b>: {error?.file}
          </div>
          <div>
            <pre>{error?.message}</pre>
          </div>
        </div>
      ))}
    </div>
    <div>
      <b>Update contracts:</b>
    </div>
    <div>
      {compileResult?.contracts?.map((contract) => (
        <div>{contract}</div>
      ))}
    </div>
  </Modal>
);

export default ContractCompileResultModal;
