import React from 'react';

const FCEMLogo = ({ style }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g clipPath="url(#clip0_18_63045)">
      <circle cx="12" cy="12" r="12" fill="url(#paint0_radial_18_63045)" />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M22.673 12.3501L22.448 12.3397L22.4271 12.7912L22.2035 12.7808L22.2244 12.3293L21.8737 12.3131L21.8871 12.0237L22.91 12.0711L22.8728 12.8733L22.6492 12.863L22.673 12.3501Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M21.7423 13.4852C21.7584 13.3792 21.795 13.2871 21.8522 13.2089C21.9102 13.1319 21.9829 13.0753 22.0702 13.0392C22.1575 13.0031 22.2518 12.9928 22.3531 13.0081C22.4543 13.0234 22.5413 13.0612 22.614 13.1215C22.6867 13.1819 22.7389 13.2573 22.7706 13.3479C22.8031 13.4396 22.8113 13.5385 22.7952 13.6446C22.7812 13.7371 22.7522 13.818 22.7082 13.8873C22.6642 13.9566 22.6076 14.0112 22.5385 14.051L22.4012 13.8438C22.4901 13.7902 22.5417 13.7167 22.5559 13.6231C22.5642 13.5682 22.5596 13.5172 22.542 13.4701C22.5243 13.4241 22.4951 13.3856 22.4544 13.3548C22.4146 13.3251 22.3662 13.3059 22.3093 13.2973C22.2524 13.2887 22.2 13.2926 22.1522 13.3091C22.1051 13.3266 22.0663 13.3548 22.0358 13.3935C22.0052 13.4333 21.9857 13.4806 21.9773 13.5356C21.9632 13.6291 21.9907 13.7146 22.0598 13.7922L21.8674 13.9495C21.8131 13.8909 21.7752 13.8221 21.7537 13.7429C21.7321 13.6637 21.7283 13.5778 21.7423 13.4852Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M21.6662 14.9426L21.4497 14.8857L21.6587 14.0907L22.649 14.351L22.4449 15.1277L22.2284 15.0708L22.3597 14.5714L22.1928 14.5275L22.0771 14.9675L21.8677 14.9124L21.9834 14.4725L21.8023 14.4249L21.6662 14.9426Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M21.0776 15.8814L21.5831 16.0788L21.2695 15.6712L21.317 15.5515L21.8106 15.4657L21.3198 15.2709L21.4185 15.0221L22.3702 15.3997L22.2823 15.6213L21.6003 15.7379L22.0201 16.2821L21.9322 16.5037L20.9794 16.1288L21.0776 15.8814Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M19.9173 19.132L19.7518 18.9792L19.4452 19.3113L19.2807 19.1595L19.5873 18.8274L19.3294 18.5893L19.5258 18.3764L20.2782 19.071L19.7335 19.661L19.569 19.5092L19.9173 19.132Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M18.4675 19.4051C18.5481 19.3344 18.6355 19.2876 18.7296 19.2647C18.8236 19.2432 18.9156 19.2468 19.0057 19.2755C19.0957 19.3042 19.1745 19.3571 19.242 19.4341C19.3095 19.511 19.3516 19.596 19.3683 19.689C19.385 19.782 19.3763 19.8734 19.3421 19.963C19.3078 20.0541 19.2503 20.135 19.1697 20.2057C19.0993 20.2674 19.0249 20.3106 18.9466 20.3352C18.8683 20.3597 18.7899 20.365 18.7113 20.3508L18.7399 20.1039C18.8425 20.1203 18.9294 20.0974 19.0005 20.035C19.0423 19.9983 19.0716 19.9564 19.0885 19.9091C19.1047 19.8625 19.1072 19.8143 19.0959 19.7645C19.0846 19.7161 19.06 19.6702 19.022 19.627C18.9841 19.5837 18.9415 19.5529 18.8944 19.5346C18.8471 19.5177 18.7993 19.5142 18.751 19.5242C18.7019 19.5348 18.6565 19.5584 18.6147 19.5951C18.5436 19.6575 18.5094 19.7406 18.5123 19.8444L18.2638 19.8406C18.26 19.7609 18.2754 19.6838 18.31 19.6093C18.3446 19.5349 18.3971 19.4668 18.4675 19.4051Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M17.4774 20.4646L17.3484 20.2817L18.0202 19.8079L18.6104 20.6446L17.9541 21.1075L17.8251 20.9246L18.2471 20.627L18.1476 20.4859L17.7759 20.7481L17.6511 20.5712L18.0228 20.309L17.9149 20.156L17.4774 20.4646Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M16.4288 20.8021L16.69 21.2778L16.711 20.764L16.8241 20.7026L17.2579 20.9533L17.0062 20.4891L17.2416 20.3615L17.7295 21.2617L17.5199 21.3754L16.9219 21.0273L16.8949 21.7141L16.6853 21.8277L16.1948 20.9289L16.4288 20.8021Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M13.439 22.5455L13.4113 22.322L12.9628 22.3776L12.9352 22.1555L13.3838 22.0999L13.3406 21.7515L13.628 21.7158L13.754 22.732L12.957 22.8308L12.9295 22.6087L13.439 22.5455Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M12.1601 21.8187C12.2673 21.8166 12.3643 21.8372 12.451 21.8804C12.5367 21.9246 12.6047 21.9867 12.655 22.0667C12.7053 22.1466 12.7315 22.2378 12.7334 22.3402C12.7354 22.4426 12.7128 22.5347 12.6656 22.6166C12.6184 22.6984 12.5528 22.7626 12.4689 22.8091C12.384 22.8566 12.2879 22.8813 12.1806 22.8834C12.087 22.8852 12.0024 22.8703 11.9267 22.8386C11.851 22.8069 11.7876 22.7603 11.7367 22.6989L11.9178 22.5286C11.9856 22.6072 12.0668 22.6457 12.1614 22.6438C12.217 22.6428 12.2665 22.6296 12.3099 22.6044C12.3523 22.5792 12.3853 22.5439 12.4088 22.4986C12.4313 22.4543 12.442 22.4034 12.4409 22.3458C12.4398 22.2883 12.4271 22.2374 12.4029 22.193C12.3776 22.1496 12.3433 22.1161 12.3 22.0925C12.2556 22.069 12.2057 22.0578 12.1501 22.0588C12.0555 22.0607 11.9759 22.1022 11.9111 22.1834L11.7236 22.0203C11.7721 21.9569 11.8336 21.9079 11.908 21.8733C11.9825 21.8387 12.0665 21.8205 12.1601 21.8187Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M10.6837 21.9871L10.7035 21.7642L11.5223 21.837L11.4316 22.8569L10.6317 22.7857L10.6516 22.5628L11.1659 22.6086L11.1812 22.4366L10.728 22.3963L10.7472 22.1807L11.2003 22.221L11.2169 22.0345L10.6837 21.9871Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M9.66515 21.5662L9.55618 22.0979L9.90486 21.7198L10.0308 21.7464L10.199 22.2183L10.3078 21.7016L10.5698 21.7567L10.3588 22.7587L10.1254 22.7095L9.89512 22.0571L9.42981 22.563L9.1965 22.5139L9.40464 21.5114L9.66515 21.5662Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M6.26317 20.9662L6.38618 20.7775L6.00753 20.5307L6.12974 20.3432L6.5084 20.59L6.7001 20.2959L6.94273 20.4541L6.38359 21.3119L5.71084 20.8734L5.83306 20.6859L6.26317 20.9662Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M5.75554 19.5845C5.8387 19.6523 5.89939 19.7306 5.93759 19.8196C5.97442 19.9087 5.98618 20.0001 5.97286 20.0936C5.95954 20.1871 5.92055 20.2736 5.85588 20.353C5.79121 20.4324 5.71441 20.4881 5.62549 20.52C5.53657 20.552 5.44505 20.5586 5.35094 20.5397C5.25546 20.5211 5.16614 20.4779 5.08297 20.4101C5.01039 20.351 4.95546 20.2849 4.91819 20.2117C4.88091 20.1386 4.8627 20.0621 4.86356 19.9823L5.11178 19.9695C5.11268 20.0733 5.14981 20.1552 5.22314 20.2149C5.26624 20.25 5.3125 20.272 5.36192 20.2808C5.41058 20.289 5.45853 20.2834 5.50576 20.264C5.55162 20.2448 5.59272 20.2128 5.62906 20.1682C5.6654 20.1236 5.68866 20.0765 5.69885 20.027C5.70766 19.9776 5.70313 19.9298 5.68526 19.8838C5.66664 19.8372 5.63578 19.7964 5.59268 19.7613C5.51935 19.7015 5.43172 19.6817 5.3298 19.7018L5.29223 19.4561C5.3702 19.4392 5.44877 19.4415 5.52793 19.4632C5.60709 19.485 5.68295 19.5254 5.75554 19.5845Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M4.54665 18.7856L4.70547 18.628L5.28465 19.2114L4.55799 19.9328L3.99222 19.3628L4.15104 19.2052L4.51483 19.5716L4.63732 19.45L4.31682 19.1272L4.47045 18.9746L4.79096 19.2975L4.92383 19.1656L4.54665 18.7856Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M4.0419 17.8131L3.61679 18.1504L4.12685 18.0848L4.20632 18.1861L4.03195 18.6558L4.44737 18.3298L4.61262 18.5403L3.80711 19.1725L3.65992 18.9849L3.90266 18.337L3.22104 18.4256L3.07384 18.2381L3.87755 17.6037L4.0419 17.8131Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M1.82285 15.1786L2.03839 15.1131L1.90701 14.6807L2.12115 14.6156L2.25253 15.0481L2.58843 14.946L2.67262 15.2232L1.6929 15.5208L1.45947 14.7524L1.67361 14.6874L1.82285 15.1786Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M2.3209 13.7923C2.341 13.8977 2.33705 13.9967 2.30907 14.0894C2.27994 14.1814 2.23019 14.2589 2.15982 14.3219C2.08945 14.385 2.00398 14.4261 1.9034 14.4453C1.80282 14.4645 1.70821 14.4577 1.61957 14.425C1.53092 14.3922 1.45662 14.3384 1.39666 14.2635C1.33557 14.1878 1.29497 14.0972 1.27487 13.9919C1.25733 13.8999 1.25781 13.8139 1.27631 13.734C1.29481 13.654 1.33005 13.5837 1.38204 13.5232L1.58037 13.673C1.51425 13.7531 1.49005 13.8396 1.50777 13.9325C1.51819 13.9871 1.53948 14.0337 1.57165 14.0722C1.60363 14.1098 1.64394 14.1364 1.69257 14.1519C1.74006 14.1667 1.79207 14.1687 1.84858 14.1579C1.9051 14.1471 1.9532 14.126 1.99288 14.0947C2.03141 14.0625 2.05864 14.023 2.07455 13.9763C2.09027 13.9287 2.09293 13.8775 2.08251 13.8229C2.06479 13.73 2.01044 13.6585 1.91947 13.6083L2.04874 13.396C2.11936 13.4332 2.17799 13.4855 2.22463 13.5531C2.27127 13.6206 2.30336 13.7004 2.3209 13.7923Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M1.90946 12.4199L2.13246 12.4009L2.20213 13.22L1.18188 13.3068L1.11382 12.5066L1.33682 12.4877L1.38058 13.0022L1.55256 12.9875L1.51401 12.5342L1.72972 12.5159L1.76827 12.9692L1.95483 12.9533L1.90946 12.4199Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M2.15063 11.2839L1.60824 11.2659L2.03951 11.5461L2.03454 11.6747L1.59763 11.9198L2.12529 11.9402L2.11496 12.2077L1.0918 12.1682L1.101 11.9299L1.70539 11.5932L1.12843 11.2196L1.13762 10.9813L2.1609 11.0179L2.15063 11.2839Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M2.16877 7.81431L2.37525 7.90438L2.55597 7.49009L2.76111 7.57958L2.58038 7.99387L2.90216 8.13424L2.78636 8.3997L1.84784 7.9903L2.16893 7.25423L2.37406 7.34371L2.16877 7.81431Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M3.43366 7.10399C3.38081 7.19733 3.31373 7.2703 3.23242 7.32287C3.15073 7.37412 3.06266 7.40102 2.96821 7.40357C2.87375 7.40613 2.78197 7.38218 2.69287 7.33173C2.60376 7.28129 2.536 7.21491 2.48959 7.1326C2.44318 7.0503 2.42136 6.96118 2.42412 6.86525C2.42651 6.76799 2.45414 6.67269 2.50698 6.57934C2.55311 6.49788 2.60909 6.43264 2.67494 6.38363C2.74079 6.33462 2.81312 6.30385 2.89193 6.29132L2.94624 6.53387C2.84398 6.55218 2.76955 6.60249 2.72295 6.6848C2.69556 6.73317 2.68167 6.78246 2.68126 6.83265C2.68134 6.882 2.69489 6.92834 2.72191 6.97165C2.74857 7.01364 2.78693 7.04881 2.837 7.07715C2.88707 7.1055 2.93738 7.12054 2.98795 7.12227C3.03815 7.12268 3.08443 7.11022 3.12678 7.08489C3.16962 7.05871 3.20473 7.02144 3.23211 6.97307C3.27871 6.89075 3.28356 6.80105 3.24664 6.70394L3.48256 6.62571C3.51237 6.69974 3.5232 6.77759 3.51506 6.85927C3.50692 6.94095 3.47978 7.02252 3.43366 7.10399Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M4.03016 5.75935L4.21211 5.88967L3.73339 6.55798L2.90099 5.96172L3.36862 5.30887L3.55056 5.4392L3.24988 5.85897L3.3902 5.95948L3.65511 5.58965L3.8311 5.71572L3.56619 6.08555L3.71841 6.19458L4.03016 5.75935Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M4.889 5.11657L4.4856 4.75354L4.63519 5.24557L4.54857 5.34079L4.05643 5.24705L4.44704 5.60239L4.2669 5.8004L3.50949 5.11137L3.66994 4.935L4.34922 5.06649L4.14832 4.40914L4.30877 4.23277L5.06815 4.91964L4.889 5.11657Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M7.11396 2.47619L7.21465 2.6777L7.61897 2.47566L7.71901 2.67586L7.31469 2.8779L7.47161 3.19194L7.21253 3.3214L6.75484 2.40545L7.4732 2.0465L7.57324 2.24669L7.11396 2.47619Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M8.53961 2.74015C8.43918 2.77783 8.34092 2.79075 8.24481 2.7789C8.14928 2.76579 8.06447 2.72991 7.99038 2.67126C7.9163 2.61261 7.86127 2.53535 7.8253 2.43949C7.78933 2.34362 7.77995 2.24923 7.79717 2.15632C7.81438 2.06341 7.85483 1.98106 7.9185 1.90925C7.98274 1.83619 8.06508 1.78082 8.16551 1.74314C8.25316 1.71025 8.33796 1.69614 8.41991 1.7008C8.50186 1.70546 8.57708 1.72828 8.64557 1.76924L8.53159 1.99011C8.44142 1.93854 8.35205 1.92937 8.26348 1.9626C8.21144 1.98213 8.16916 2.01101 8.13664 2.04925C8.10503 2.08715 8.08569 2.13138 8.07863 2.18195C8.07213 2.23125 8.07899 2.28284 8.0992 2.33671C8.11942 2.39058 8.14836 2.4344 8.18603 2.46818C8.22427 2.5007 8.26776 2.52083 8.3165 2.52859C8.36615 2.53599 8.41699 2.52994 8.46903 2.51041C8.5576 2.47718 8.61887 2.41148 8.65287 2.31332L8.88401 2.40469C8.85937 2.4806 8.81772 2.54726 8.75907 2.60468C8.70042 2.66211 8.62726 2.70726 8.53961 2.74015Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M9.83421 2.09585L9.89029 2.31252L9.09444 2.51849L8.83789 1.52722L9.61533 1.32601L9.6714 1.54268L9.17152 1.67205L9.21477 1.83915L9.65517 1.72517L9.70942 1.93475L9.26901 2.04873L9.31592 2.22999L9.83421 2.09585Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M10.9899 2.14037L10.9161 1.60272L10.7128 2.07508L10.5852 2.09189L10.2698 1.70263L10.3388 2.22616L10.0734 2.26113L9.93963 1.24597L10.176 1.21483L10.61 1.75371L10.8808 1.12197L11.1172 1.09082L11.2539 2.10559L10.9899 2.14037Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M14.452 1.57403L14.398 1.79274L14.8368 1.90099L14.7832 2.11828L14.3444 2.01002L14.2603 2.35087L13.9791 2.2815L14.2244 1.28737L15.004 1.47971L14.9504 1.69699L14.452 1.57403Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M15.3887 2.70963C15.2879 2.67308 15.2048 2.61909 15.1394 2.54768C15.0752 2.47568 15.034 2.39333 15.0158 2.30062C14.9975 2.20791 15.0058 2.11342 15.0407 2.01716C15.0756 1.92089 15.1297 1.84302 15.2032 1.78354C15.2766 1.72405 15.3608 1.68768 15.4559 1.67441C15.5522 1.66056 15.6507 1.67191 15.7516 1.70847C15.8396 1.74036 15.9133 1.78469 15.9726 1.84145C16.0319 1.89821 16.0742 1.9644 16.0997 2.04002L15.8696 2.13399C15.8345 2.03622 15.7725 1.97122 15.6836 1.93899C15.6313 1.92005 15.5804 1.91456 15.5309 1.92253C15.4822 1.93083 15.4388 1.95191 15.4006 1.98578C15.3636 2.01906 15.3354 2.06275 15.3157 2.11684C15.2961 2.17093 15.2897 2.22305 15.2964 2.2732C15.3044 2.32276 15.3244 2.36632 15.3564 2.40386C15.3894 2.44173 15.432 2.47014 15.4842 2.48908C15.5732 2.52131 15.6624 2.51113 15.752 2.45855L15.8685 2.67812C15.8004 2.71985 15.7255 2.74351 15.6436 2.7491C15.5617 2.75468 15.4768 2.74153 15.3887 2.70963Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M16.7747 3.05518L16.6769 3.25648L15.9375 2.89723L16.385 1.97625L17.1073 2.32719L17.0095 2.52849L16.5451 2.30284L16.4696 2.45809L16.8788 2.65689L16.7842 2.85161L16.375 2.65281L16.2932 2.82122L16.7747 3.05518Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M17.5624 3.79938L17.8529 3.34101L17.3928 3.57069L17.2844 3.50119L17.2947 3.0003L17.0096 3.44478L16.7842 3.30025L17.3371 2.43839L17.5378 2.56712L17.5216 3.25882L18.1361 2.95094L18.3368 3.07967L17.7865 3.94311L17.5624 3.79938Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M20.8193 5.94333L20.6319 6.06841L20.8829 6.44432L20.6967 6.56859L20.4458 6.19267L20.1538 6.3876L19.993 6.14672L20.8446 5.57819L21.2905 6.24607L21.1043 6.37034L20.8193 5.94333Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M20.7319 7.38698C20.6818 7.29211 20.6566 7.19626 20.6562 7.09943C20.6572 7.003 20.6821 6.91434 20.7309 6.83344C20.7797 6.75254 20.8494 6.6882 20.9399 6.6404C21.0305 6.59261 21.1229 6.57139 21.2173 6.57673C21.3116 6.58207 21.3984 6.61179 21.4777 6.66588C21.5583 6.72038 21.6236 6.79507 21.6737 6.88993C21.7174 6.97273 21.7421 7.05506 21.7478 7.13695C21.7535 7.21883 21.7404 7.29633 21.7084 7.36944L21.4749 7.28428C21.5147 7.18831 21.5125 7.0985 21.4684 7.01484C21.4424 6.96568 21.4084 6.92739 21.3664 6.89996C21.3248 6.87339 21.2785 6.8598 21.2274 6.85917C21.1777 6.85896 21.1274 6.87228 21.0765 6.89913C21.0256 6.92599 20.9858 6.96023 20.957 7.00187C20.9296 7.04391 20.9151 7.0896 20.9136 7.13892C20.9125 7.18911 20.925 7.23878 20.9509 7.28794C20.9951 7.3716 21.068 7.42409 21.1696 7.44541L21.1082 7.68624C21.0298 7.67139 20.9584 7.63849 20.894 7.58756C20.8296 7.53663 20.7756 7.46977 20.7319 7.38698Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M21.5464 8.62684L21.3384 8.70938L21.0352 7.94525L21.9869 7.56764L22.2831 8.31408L22.0751 8.39662L21.8846 7.91666L21.7242 7.98032L21.892 8.40317L21.6907 8.48301L21.523 8.06016L21.3489 8.12921L21.5464 8.62684Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M21.6197 9.69026L22.1433 9.54747L21.6486 9.40701L21.6154 9.28264L21.9605 8.91951L21.4503 9.0557L21.3813 8.79707L22.3706 8.533L22.4321 8.76336L21.9539 9.26343L22.6154 9.45022L22.6769 9.68058L21.6884 9.94747L21.6197 9.69026Z"
        fill="white"
      />
      <g opacity="0.9">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.9"
          d="M11.3681 7.74243L13.3373 9.65355L10.082 10.1556L11.3681 7.74243Z"
          fill="white"
        />
      </g>
      <g opacity="0.9">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.9"
          d="M12.1613 12.119L13.3373 9.65356L10.082 10.1556L12.1613 12.119Z"
          fill="white"
        />
      </g>
      <g opacity="0.9">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.9"
          d="M14.8734 12.5454L13.3381 9.65356L12.1621 12.119L14.8734 12.5454Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.7"
          d="M9.74023 13.3135L12.5836 14.7271L12.1614 12.1189L9.74023 13.3135Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.7"
          d="M10.0823 10.1555L9.74044 13.3136L7.60156 11.4361L10.0823 10.1555Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.7"
          d="M8.45698 8.70508L10.0823 10.1555L7.60156 11.4361L8.45698 8.70508Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M6.31836 10.0703L7.60117 11.4361L8.45659 8.70508L6.31836 10.0703Z"
          fill="white"
        />
      </g>
      <g opacity="0.25">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.25"
          d="M6.36105 8.72754L6.31831 10.0701L5.46289 11.2654L6.36105 8.72754Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.4"
          d="M7.51569 7.85156L6.31836 10.0702L6.3611 8.72765L7.51569 7.85156Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.7"
          d="M7.51562 7.85159L9.82545 6.57104L8.45652 8.70507L7.51562 7.85159Z"
          fill="white"
        />
      </g>
      <g opacity="0.8">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.8"
          d="M11.3686 7.7423L9.82617 6.57095L12.307 6.14453L11.3686 7.7423Z"
          fill="white"
        />
      </g>
      <g opacity="0.8">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.8"
          d="M8.45703 8.70507L11.3684 7.7424L9.82596 6.57104L8.45703 8.70507Z"
          fill="white"
        />
      </g>
      <g opacity="0.8">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.8"
          d="M14.1873 7.76621L11.3672 7.7423L12.3055 6.14453L14.1873 7.76621Z"
          fill="white"
        />
      </g>
      <g opacity="0.9">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.9"
          d="M15.8996 9.98502L13.3379 9.65357L14.1888 7.76636L15.8996 9.98502Z"
          fill="white"
        />
      </g>
      <g opacity="0.8">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.8"
          d="M17.7802 10.5825L16.9209 12.8205L15.8984 9.98486L17.7802 10.5825Z"
          fill="white"
        />
      </g>
      <g opacity="0.8">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.8"
          d="M14.873 12.5453L16.9219 12.8205L15.8994 9.98486L14.873 12.5453Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.7"
          d="M16.9206 12.8206L15.2402 15.3048L17.1285 15.2828L16.9206 12.8206Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.4"
          d="M9.73906 13.3135L10.1969 16.195L7.70703 14.3375L9.73906 13.3135Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.4"
          d="M5.97656 12.6306L7.60128 11.436L7.70813 14.3376L5.97656 12.6306Z"
          fill="white"
        />
      </g>
      <g opacity="0.2">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.2"
          d="M5.46289 11.2654L5.9764 12.6306L5.54837 13.7399L5.46289 11.2654Z"
          fill="white"
        />
      </g>
      <g opacity="0.25">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.25"
          d="M6.31831 10.0703L5.9764 12.6307L5.46289 11.2656L6.31831 10.0703Z"
          fill="white"
        />
      </g>
      <g opacity="0.25">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.25"
          d="M6.32106 15.1355L7.70813 14.3376L5.97656 12.6306L6.32106 15.1355Z"
          fill="white"
        />
      </g>
      <g opacity="0.2">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.2"
          d="M5.54883 13.7399L6.32136 15.1355L5.97686 12.6306L5.54883 13.7399Z"
          fill="white"
        />
      </g>
      <g opacity="0.2">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.2"
          d="M6.91671 16.3004L8.30636 16.8127L6.32031 15.1355L6.91671 16.3004Z"
          fill="white"
        />
      </g>
      <g opacity="0.1">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.1"
          d="M9.1747 17.9416L8.30762 16.8129L6.91797 16.3005L9.1747 17.9416Z"
          fill="white"
        />
      </g>
      <g opacity="0.1">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.1"
          d="M11.6494 18.5378L10.5952 17.9221L9.17383 17.9415L11.6494 18.5378Z"
          fill="white"
        />
      </g>
      <g opacity="0.1">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.1"
          d="M14.5035 18.1211H13.0375L11.6504 18.5378L14.5035 18.1211Z"
          fill="white"
        />
      </g>
      <g opacity="0.2">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.2"
          d="M16.4964 16.7275L15.2402 17.026L14.502 18.1211L16.4964 16.7275Z"
          fill="white"
        />
      </g>
      <g opacity="0.3">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.3"
          d="M12.9297 16.9136L13.0372 18.1211L15.2415 17.026L12.9297 16.9136Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.4"
          d="M12.5829 14.7273L12.9287 16.9136L10.1973 16.1952L12.5829 14.7273Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.4"
          d="M15.2415 15.3047L12.9297 16.9134L15.2415 17.0259V15.3047Z"
          fill="white"
        />
      </g>
      <g opacity="0.3">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.3"
          d="M17.1408 15.2712L16.4965 16.7275L15.2402 17.026L17.1408 15.2712Z"
          fill="white"
        />
      </g>
      <g opacity="0.8">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.8"
          d="M16.5823 8.36328L17.7802 10.5826L15.8984 9.98496L16.5823 8.36328Z"
          fill="white"
        />
      </g>
      <g opacity="0.8">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.8"
          d="M14.1875 7.76636L16.5822 8.36334L15.8983 9.98502L14.1875 7.76636Z"
          fill="white"
        />
      </g>
      <g opacity="0.8">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.8"
          d="M14.7573 6.5498L16.5822 8.36337L14.1875 7.76638L14.7573 6.5498Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        {/* eslint-disable-next-line prettier/prettier */}
        <path opacity="0.7" d="M12.3066 6.1446L13.5668 5.80347L14.7583 6.5497" fill="white" />
      </g>
      <g opacity="0.8">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.8"
          d="M12.3066 6.14453L14.7583 6.54963L14.1884 7.76621L12.3066 6.14453Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.7"
          d="M14.7578 6.5498L16.0562 7.046L16.5826 8.36337L14.7578 6.5498Z"
          fill="white"
        />
      </g>
      <g opacity="0.8">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.8"
          d="M17.8046 9.13148L17.78 10.5826L16.582 8.36328L17.8046 9.13148Z"
          fill="white"
        />
      </g>
      <g opacity="0.3">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.3"
          d="M7.70703 14.3376L8.30602 16.8128L10.1969 16.1951L7.70703 14.3376Z"
          fill="white"
        />
      </g>
      <g opacity="0.3">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.3"
          d="M10.1975 16.1951L10.5951 17.9221L8.30664 16.8127L10.1975 16.1951Z"
          fill="white"
        />
      </g>
      <g opacity="0.3">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.3"
          d="M10.5957 17.9221L12.9295 16.9136L13.037 18.1211L10.5957 17.9221Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.7"
          d="M18.1231 13.0577L17.1297 15.2828L16.9219 12.8206L18.1231 13.0577Z"
          fill="white"
        />
      </g>
      <g opacity="0.8">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.8"
          d="M18.5136 11.7649L18.1232 13.0577L17.7812 10.5825L18.5136 11.7649Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.4"
          d="M8.19922 6.74223L9.82458 6.57102L10.851 5.80347L8.19922 6.74223Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.7"
          d="M13.5317 5.80664L12.2826 6.14519L10.8418 5.80664H13.5317Z"
          fill="white"
        />
      </g>
      <g opacity="0.25">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.25"
          d="M6.36133 8.72761L8.20038 6.74219L7.51592 7.85152L6.36133 8.72761Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        {/* eslint-disable-next-line prettier/prettier */}
        <path opacity="0.7" d="M16.0566 7.0459L17.8057 9.13146L16.5831 8.36327" fill="white" />
      </g>
      <g opacity="0.8">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.8"
          d="M17.8059 9.13135L18.5136 11.7648L17.7812 10.5825L17.8059 9.13135Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M18.5132 11.7649L18.0625 14.3512L18.1227 13.0577L18.5132 11.7649Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.7"
          d="M9.82617 6.56773L10.8428 5.80664L12.2836 6.14519L9.82617 6.56773Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.4"
          d="M8.20009 6.74226L9.82545 6.57104L7.51562 7.85159L8.20009 6.74226Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.4"
          d="M7.51569 7.85156L8.45659 8.70504L6.31836 10.0702L7.51569 7.85156Z"
          fill="white"
        />
      </g>
      <g opacity="0.8">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.8"
          d="M8.45703 8.7051L10.0824 10.1556L11.3684 7.74243L8.45703 8.7051Z"
          fill="white"
        />
      </g>
      <g opacity="0.35">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.35"
          d="M18.0632 14.3511L17.1301 15.2827L16.498 16.7274L18.0632 14.3511Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M18.1223 13.0576L18.062 14.3511L17.1289 15.2827L18.1223 13.0576Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.4"
          d="M15.2402 15.3047V17.0259L17.1285 15.2827L15.2402 15.3047Z"
          fill="white"
        />
      </g>
      <g opacity="0.2">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.2"
          d="M13.0371 18.121L15.2414 17.0259L14.5032 18.121H13.0371Z"
          fill="white"
        />
      </g>
      <g opacity="0.2">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.2"
          d="M10.5957 17.9221L11.6499 18.5378L13.037 18.1211L10.5957 17.9221Z"
          fill="white"
        />
      </g>
      <g opacity="0.3">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.3"
          d="M10.1973 16.1951L10.5949 17.9221L12.9287 16.9135L10.1973 16.1951Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.4"
          d="M12.584 14.7273L12.9298 16.9136L15.2415 15.3049L12.584 14.7273Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.7"
          d="M14.8734 12.5453L12.1621 12.1189L12.5843 14.7271L14.8734 12.5453Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.4"
          d="M9.74023 13.3135L10.1981 16.195L12.5836 14.7271L9.74023 13.3135Z"
          fill="white"
        />
      </g>
      <g opacity="0.8">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.8"
          d="M17.7812 10.5825L18.1231 13.0577L16.9219 12.8206L17.7812 10.5825Z"
          fill="white"
        />
      </g>
      <g opacity="0.9">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.9"
          d="M13.3379 9.65356L14.8732 12.5454L15.8996 9.98501L13.3379 9.65356Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.7"
          d="M15.2415 15.3048L14.873 12.5454L16.9219 12.8206L15.2415 15.3048Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.7"
          d="M12.584 14.7272L15.2415 15.3048L14.8731 12.5454L12.584 14.7272Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.7"
          d="M10.0821 10.1555L12.1614 12.119L9.74023 13.3136L10.0821 10.1555Z"
          fill="white"
        />
      </g>
      <g opacity="0.9">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.9"
          d="M11.3672 7.74243L13.3364 9.65355L14.1873 7.76634L11.3672 7.74243Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.4"
          d="M6.31847 10.0703L7.60128 11.4361L5.97656 12.6307L6.31847 10.0703Z"
          fill="white"
        />
      </g>
      <g opacity="0.4">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.4"
          d="M7.70841 14.3376L9.74044 13.3136L7.60156 11.436L7.70841 14.3376Z"
          fill="white"
        />
      </g>
      <g opacity="0.25">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.25"
          d="M8.30636 16.8128L6.32031 15.1356L7.70738 14.3376L8.30636 16.8128Z"
          fill="white"
        />
      </g>
      <g opacity="0.2">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.2"
          d="M10.5951 17.9221L9.17372 17.9415L8.30664 16.8127L10.5951 17.9221Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.7"
          d="M13.5664 5.80347L16.0563 7.04589L14.7579 6.5497L13.5664 5.80347Z"
          fill="white"
        />
      </g>
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M10.0839 10.173L10.0664 10.1478L11.3525 7.73401L11.3796 7.72949L13.3489 9.64126L13.3398 9.67033L10.0839 10.173ZM11.3719 7.7702L10.113 10.1336L13.3003 9.64191L11.3719 7.7702Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M12.1496 12.1311L10.0703 10.1683L10.0794 10.1392L13.3346 9.63721L13.3528 9.66176L12.1768 12.1266L12.1496 12.1311ZM10.1195 10.167L12.1561 12.0897L13.3074 9.67533L10.1195 10.167Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M14.8681 12.5622L12.1575 12.1358L12.1445 12.1112L13.3205 9.6464L13.3509 9.64575L14.8863 12.5376L14.8681 12.5622ZM12.1853 12.1054L14.8397 12.5234L13.3367 9.69227L12.1853 12.1054Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M12.5758 14.7426L9.73242 13.329V13.2986L12.1536 12.104L12.1782 12.1163L12.6004 14.7245L12.5758 14.7426ZM9.7784 13.3135L12.5609 14.6967L12.1478 12.1441L9.7784 13.3135Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M9.72807 13.3266L7.58984 11.449L7.59308 11.4213L10.0739 10.1414L10.0985 10.1582L9.75656 13.3162L9.72807 13.3266ZM7.63129 11.44L9.72613 13.2794L10.0616 10.1859L7.63129 11.44Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M7.60794 11.4509L7.58398 11.4309L8.4394 8.69989L8.46725 8.69214L10.0926 10.1432L10.0887 10.171L7.60794 11.4509ZM8.46336 8.73543L7.62802 11.4018L10.0499 10.1516L8.46336 8.73543Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M7.58749 11.4477L6.30469 10.0819L6.30793 10.056L8.44615 8.69019L8.47205 8.70957L7.61663 11.4412L7.58749 11.4477ZM6.34354 10.0735L7.59267 11.4025L8.42543 8.74446L6.34354 10.0735Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M5.47575 11.275L5.44531 11.2595L6.34347 8.72168L6.3765 8.72814L6.33376 10.0707L6.33052 10.0804L5.47575 11.275ZM6.33894 8.83733L5.51784 11.1568L6.29944 10.0649L6.33894 8.83733Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M6.33316 10.0786L6.30078 10.0702L6.34352 8.72764L6.35 8.71472L7.50459 7.83862L7.52984 7.86059L6.33316 10.0786ZM6.37784 8.73604L6.33769 9.99784L7.46314 7.91292L6.37784 8.73604Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M8.4448 8.71731L7.50391 7.86383L7.50714 7.83605L9.81697 6.55615L9.83964 6.58006L8.47071 8.71408L8.4448 8.71731ZM7.54405 7.85478L8.45257 8.67854L9.77359 6.61882L7.54405 7.85478Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M11.3569 7.75591L9.81445 6.5852L9.82222 6.55483L12.303 6.12842L12.3205 6.15362L11.3822 7.75138L11.3569 7.75591ZM9.86626 6.58132L11.3621 7.7165L12.2719 6.16718L9.86626 6.58132Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M8.46148 8.7212L8.44141 8.696L9.81034 6.56263L9.83494 6.55811L11.3774 7.72881L11.3722 7.75853L8.46148 8.7212ZM9.82912 6.59622L8.49645 8.67339L11.3308 7.73592L9.82912 6.59622Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M14.1866 7.78314L11.3665 7.75924L11.3516 7.7334L12.2899 6.13563L12.3158 6.1311L14.1976 7.75278L14.1866 7.78314ZM11.3962 7.725L14.1399 7.74826L12.3093 6.17051L11.3962 7.725Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M15.8956 10.0023L13.3339 9.67086L13.3203 9.64695L14.1712 7.75909L14.2003 7.75586L15.9112 9.97516L15.8956 10.0023ZM13.3618 9.63985L15.8594 9.96289L14.1913 7.79915L13.3618 9.63985Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M16.9364 12.8263L16.904 12.8257L15.8809 9.98998L15.9022 9.96802L17.784 10.5656L17.795 10.5883L16.9364 12.8263ZM15.9255 10.012L16.9208 12.7714L17.7568 10.5934L15.9255 10.012Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M16.9186 12.8374L14.8691 12.5622L14.8555 12.539L15.8818 9.97852L15.9142 9.97916L16.9374 12.8148L16.9186 12.8374ZM14.8956 12.5312L16.8953 12.7993L15.8974 10.0328L14.8956 12.5312Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M15.2408 15.3215L15.2266 15.295L16.907 12.8108L16.938 12.8192L17.1453 15.2814L17.1284 15.3002L15.2408 15.3215ZM16.9083 12.8702L15.2732 15.2872L17.1097 15.2659L16.9083 12.8702Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M10.1871 16.2087L7.69727 14.3512L7.69986 14.3221L9.73188 13.2981L9.75649 13.3104L10.2143 16.1919L10.1871 16.2087ZM7.74 14.3409L10.1735 16.1564L9.72606 13.3394L7.74 14.3409Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M7.69445 14.3498L5.96289 12.6428L5.96483 12.617L7.5902 11.4224L7.61739 11.4353L7.72359 14.3369L7.69445 14.3498ZM6.0011 12.6325L7.68798 14.2949L7.58437 11.4689L6.0011 12.6325Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M5.56381 13.7465L5.53079 13.7406L5.44531 11.2655L5.47834 11.259L5.99185 12.6249V12.6371L5.56381 13.7465ZM5.48287 11.3695L5.56187 13.656L5.95688 12.6313L5.48287 11.3695Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M5.95882 12.6371L5.44531 11.2713L5.44726 11.2552L6.30267 10.0605L6.33376 10.0728L5.99185 12.6333L5.95882 12.6371ZM5.48093 11.2681L5.96724 12.5609L6.29102 10.1361L5.48093 11.2681Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M6.32939 15.1503L6.30413 15.138L5.95898 12.6331L5.98812 12.6189L7.71969 14.3259L7.71645 14.353L6.32939 15.1503ZM5.99978 12.6783L6.33457 15.1083L7.67954 14.3343L5.99978 12.6783Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M6.30508 15.1438L5.53255 13.7483L5.53125 13.7341L5.95864 12.6248L5.99166 12.6286L6.33681 15.1335L6.30508 15.1438ZM5.56622 13.7386L6.29018 15.0469L5.96705 12.6991L5.56622 13.7386Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M8.29916 16.8289L6.9095 16.3165L6.90044 16.3081L6.30469 15.1432L6.33124 15.1226L8.31664 16.7998L8.29916 16.8289ZM6.92828 16.2868L8.22016 16.763L6.37398 15.204L6.92828 16.2868Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M9.16298 17.955L6.90625 16.3146L6.92244 16.2849L8.31209 16.7973L8.31986 16.8031L9.18694 17.9318L9.16298 17.955ZM7.02605 16.3592L9.08722 17.8575L8.29526 16.827L7.02605 16.3592Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M11.6442 18.5546L9.16797 17.9583L9.17185 17.9247L10.5932 17.9053L10.6023 17.9079L11.6572 18.5236L11.6442 18.5546ZM9.30914 17.9563L11.5393 18.4939L10.5894 17.9395L9.30914 17.9563Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M11.6517 18.5545L11.6445 18.5209L13.0361 18.1035H14.5022L14.5048 18.1378L11.6517 18.5545ZM13.0387 18.1378L11.877 18.4866L14.2665 18.1378H13.0387Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M14.5122 18.1353L14.4883 18.1114L15.2265 17.0163L15.2369 17.0092L16.4931 16.7107L16.5067 16.7411L14.5122 18.1353ZM15.2511 17.0415L14.5679 18.0546L16.4135 16.765L15.2511 17.0415Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M13.0442 18.1366L13.0196 18.123L12.9121 16.9155L12.9302 16.8967L15.242 17.0091L15.2485 17.0415L13.0442 18.1366ZM12.9477 16.9316L13.0513 18.0946L15.174 17.0402L12.9477 16.9316Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M12.9241 16.9298L10.192 16.2113L10.1875 16.1803L12.5737 14.7124L12.5996 14.724L12.9454 16.9104L12.9241 16.9298ZM10.2393 16.1887L12.9072 16.8904L12.5692 14.755L10.2393 16.1887Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M15.2388 17.0434L12.927 16.931L12.918 16.9L15.2297 15.2913L15.2569 15.3055V17.0273L15.2388 17.0434ZM12.9788 16.8987L15.2226 17.0079V15.3371L12.9788 16.8987Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M15.2441 17.0428L15.2285 17.0137L17.1291 15.2583L17.1563 15.2777L16.512 16.734L16.5003 16.7437L15.2441 17.0428ZM17.0915 15.3397L15.2997 16.9943L16.4841 16.7126L17.0915 15.3397Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M17.775 10.5986L15.8932 10.001L15.8828 9.9777L16.5673 8.35603L16.5984 8.35474L17.7957 10.5734L17.775 10.5986ZM15.921 9.97447L17.7445 10.5534L16.5841 8.40319L15.921 9.97447Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M15.8847 9.99531L14.1738 7.77665L14.1913 7.74951L16.5866 8.34714L16.5983 8.3704L15.9138 9.99207L15.8847 9.99531ZM14.2308 7.79474L15.8937 9.95137L16.5588 8.37557L14.2308 7.79474Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M16.5788 8.37999L14.1835 7.78236L14.1719 7.75846L14.7417 6.54252L14.7696 6.53735L16.595 8.35092L16.5788 8.37999ZM14.212 7.75458L16.5264 8.33218L14.7624 6.57935L14.212 7.75458Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M14.7472 6.56451L13.5629 5.82151L12.3098 6.16135L12.3008 6.12775L13.5609 5.78662L13.5745 5.78856L14.766 6.53544L14.7472 6.56451Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M14.1748 7.77908L12.293 6.15741L12.3072 6.12769L14.7589 6.53278L14.7718 6.55669L14.202 7.77262L14.1748 7.77908ZM12.3616 6.17097L14.1799 7.73773L14.7304 6.5625L12.3616 6.17097Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M16.5696 8.37544L14.7441 6.56188L14.7623 6.53345L16.0606 7.02964L16.0703 7.03933L16.5974 8.3567L16.5696 8.37544ZM14.8257 6.59483L16.5353 8.29274L16.0418 7.05936L14.8257 6.59483Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M17.7644 10.5901L16.5664 8.37149L16.5904 8.34888L17.8123 9.11707L17.8201 9.13193L17.7955 10.583L17.7644 10.5901ZM16.6273 8.41219L17.7631 10.5165L17.7864 9.14098L16.6273 8.41219Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M8.31046 16.8288L8.28844 16.8166L7.68945 14.3414L7.71665 14.324L10.2065 16.1815L10.2013 16.2112L8.31046 16.8288ZM7.73413 14.3795L8.31758 16.7901L10.1592 16.1886L7.73413 14.3795Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M10.5873 17.9376L8.29883 16.8283L8.30077 16.7966L10.1916 16.179L10.2136 16.1912L10.6112 17.9182L10.5873 17.9376ZM8.35193 16.816L10.5705 17.8911L10.1852 16.2171L8.35193 16.816Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M13.0344 18.1379L10.5931 17.939L10.5879 17.906L12.9223 16.8975L12.9463 16.9117L13.0538 18.1192L13.0344 18.1379ZM10.6637 17.9105L13.0169 18.1024L12.9133 16.9388L10.6637 17.9105Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M17.1445 15.2898L17.1115 15.284L16.9043 12.8218L16.925 12.8037L18.1262 13.0408L18.1385 13.0647L17.1445 15.2898ZM16.9406 12.8418L17.1407 15.2149L18.0984 13.0699L16.9406 12.8418Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M18.1393 13.0621L18.1056 13.0595L17.7637 10.5844L17.7954 10.5728L18.5278 11.7551L18.5297 11.7693L18.1393 13.0621ZM17.8084 10.66L18.1289 12.9768L18.4941 11.7667L17.8084 10.66Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M8.20113 6.75907L8.19336 6.72612L10.8451 5.78735L10.8613 5.81707L9.83491 6.58527L9.82649 6.5885L8.20113 6.75907ZM10.7506 5.85713L8.341 6.70996L9.81807 6.5549L10.7506 5.85713Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M12.3021 6.16135L10.8477 5.82022L10.8515 5.78662H13.5661L13.5706 5.82022L12.3105 6.16135H12.3021ZM10.9992 5.82022L12.3053 6.12711L13.4372 5.82022H10.9992Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M6.37032 8.74109L6.34766 8.71589L8.18671 6.73047L8.21391 6.75114L7.52944 7.86047L7.52491 7.86499L6.37032 8.74109ZM8.09217 6.8823L6.50566 8.59572L7.50225 7.8398L8.09217 6.8823Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M17.7946 9.14632L16.572 8.37812L16.5908 8.34905L17.7201 9.0591L16.041 7.05688L16.0676 7.03491L17.8166 9.12048L17.7946 9.14632Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M18.4986 11.7738L17.7663 10.5915L17.7637 10.5824L17.7883 9.13132L17.822 9.12744L18.5297 11.7609L18.4986 11.7738ZM17.7973 10.5779L18.4656 11.6569L17.8194 9.25407L17.7973 10.5779Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M18.0792 14.3543L18.0449 14.3504L18.1058 13.0524L18.4963 11.7603L18.5293 11.768L18.0792 14.3543ZM18.1395 13.0602L18.0922 14.0803L18.4471 12.0413L18.1395 13.0602Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M9.8274 6.58841L9.81445 6.55805L10.8408 5.78985L10.8551 5.78662L12.3095 6.12775L12.3082 6.16135L9.8274 6.58841ZM10.8551 5.82151L9.89216 6.5419L12.2195 6.14132L10.8551 5.82151Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M7.52266 7.86648L7.5 7.84258L8.18447 6.73325L8.19742 6.7255L9.82278 6.55493L9.83314 6.58724L7.52266 7.86648ZM8.20907 6.75845L7.56346 7.80511L9.74248 6.59757L8.20907 6.75845Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M6.32734 10.0851L6.30273 10.0624L7.50006 7.84315L7.52661 7.83862L8.46751 8.6921L8.46492 8.71924L6.32734 10.0851ZM7.52079 7.87868L6.3649 10.0205L8.42866 8.70244L7.52079 7.87868Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M10.0707 10.1684L8.44531 8.71733L8.45114 8.68825L11.3625 7.72559L11.3833 7.75014L10.0972 10.1639L10.0707 10.1684ZM8.48999 8.71216L10.0772 10.129L11.3328 7.7721L8.48999 8.71216Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M16.5103 16.7365L16.4805 16.7203L17.1118 15.2757L17.1157 15.2705L18.0495 14.3389L18.076 14.3602L16.5103 16.7365ZM17.1423 15.2925L16.5925 16.5504L17.955 14.4817L17.1423 15.2925Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M17.1411 15.295L17.1133 15.2757L18.1073 13.0505L18.1403 13.0583L18.0801 14.3524L18.0749 14.3634L17.1411 15.295ZM18.1014 13.1474L17.1826 15.2052L18.0458 14.344L18.1014 13.1474Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M15.2511 17.039L15.2227 17.0267V15.3049L15.2395 15.2881L17.1271 15.2661L17.1388 15.2958L15.2511 17.039ZM15.257 15.3217V16.9873L17.0837 15.301L15.257 15.3217Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M14.5012 18.1379H13.0351L13.0273 18.1056L15.2316 17.0105L15.2536 17.0357L14.5154 18.1308L14.5012 18.1379ZM13.1083 18.1037H14.4921L15.1889 17.0699L13.1083 18.1037Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M11.6544 18.5539L11.6408 18.5519L10.5859 17.9362L10.5963 17.9045L13.0376 18.1035L13.0408 18.1371L11.6544 18.5539ZM10.6688 17.9452L11.6518 18.519L12.945 18.1307L10.6688 17.9452Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M10.6006 17.9375L10.5773 17.9259L10.1797 16.1989L10.2011 16.1782L12.9331 16.8967L12.9357 16.929L10.6006 17.9375ZM10.2192 16.2189L10.6058 17.8981L12.8755 16.9173L10.2192 16.2189Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M12.9388 16.9278L12.9122 16.9162L12.5664 14.7298L12.5871 14.7104L15.2447 15.288L15.2512 15.3191L12.9388 16.9278ZM12.604 14.7492L12.9413 16.8839L15.1981 15.3132L12.604 14.7492Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M12.5952 14.7394L12.5667 14.7297L12.1445 12.1214L12.164 12.1021L14.8746 12.5285L14.8837 12.5575L12.5952 14.7394ZM12.1814 12.1389L12.5946 14.6922L14.8345 12.5569L12.1814 12.1389Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M10.2064 16.2098L10.1805 16.1982L9.72266 13.3167L9.74726 13.2986L12.5907 14.7122L12.592 14.7419L10.2064 16.2098ZM9.76151 13.3438L10.2103 16.1672L12.5479 14.729L9.76151 13.3438Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M18.1185 13.0744L16.9172 12.8373L16.9043 12.8147L17.7636 10.5767L17.7966 10.5805L18.1385 13.0557L18.1185 13.0744ZM16.9438 12.8076L18.1016 13.0363L17.772 10.651L16.9438 12.8076Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M14.8557 12.5531L13.3203 9.66118L13.3378 9.63599L15.8995 9.96743L15.9131 9.99069L14.8867 12.5511L14.8557 12.5531ZM13.3663 9.67475L14.8686 12.5046L15.873 9.99909L13.3663 9.67475Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M15.2537 15.3145L15.2226 15.3074L14.8535 12.5479L14.8729 12.5286L16.9225 12.8038L16.9341 12.8303L15.2537 15.3145ZM14.8904 12.5654L15.2505 15.2576L16.8901 12.8335L14.8904 12.5654Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M15.2363 15.3215L12.5787 14.7439L12.5703 14.7148L14.8588 12.533L14.8879 12.5433L15.257 15.3027L15.2363 15.3215ZM12.6176 14.7174L15.2195 15.2827L14.8581 12.5808L12.6176 14.7174Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M9.74726 13.3289L9.72266 13.3115L10.0646 10.1534L10.0937 10.1431L12.173 12.1059L12.1691 12.1337L9.74726 13.3289ZM10.095 10.1915L9.76021 13.2837L12.1316 12.1136L10.095 10.1915Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M13.3247 9.66569L11.3555 7.75393L11.3678 7.72485L14.1879 7.74876L14.2034 7.77266L13.3525 9.66053L13.3247 9.66569ZM11.4105 7.75974L13.3318 9.62435L14.162 7.783L11.4105 7.75974Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M5.98618 12.6449L5.95898 12.6287L6.30089 10.0683L6.33068 10.0586L7.61349 11.4244L7.6109 11.4496L5.98618 12.6449ZM6.33068 10.1083L5.99849 12.5932L7.57593 11.4335L6.33068 10.1083Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M7.71479 14.3531L7.69018 14.3382L7.58398 11.4367L7.61248 11.4231L9.7507 13.3006L9.74682 13.329L7.71479 14.3531ZM7.61895 11.4748L7.72321 14.3098L9.70861 13.309L7.61895 11.4748Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M8.294 16.8257L6.30859 15.1485L6.31118 15.1207L7.69825 14.3228L7.7235 14.3337L8.32249 16.8089L8.294 16.8257ZM6.34939 15.1381L8.27651 16.7656L7.69566 14.3635L6.34939 15.1381Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M9.17364 17.9581L9.16005 17.9517L8.29297 16.823L8.31434 16.7971L10.6028 17.9064L10.5957 17.9387L9.17364 17.9581ZM8.3642 16.8598L9.18206 17.9239L10.5225 17.9058L8.3642 16.8598Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M16.0491 7.06192L14.7507 6.56573L13.5566 5.81756L13.5735 5.78784L16.0633 7.03091L16.0491 7.06192ZM13.8739 5.97585L14.7663 6.53536L15.7363 6.90557L13.8739 5.97585Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M12.1724 12.3872C12.3141 12.3872 12.4289 12.2726 12.4289 12.1313C12.4289 11.99 12.3141 11.8755 12.1724 11.8755C12.0308 11.8755 11.916 11.99 11.916 12.1313C11.916 12.2726 12.0308 12.3872 12.1724 12.3872Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M9.69393 13.6081C9.83555 13.6081 9.95036 13.4936 9.95036 13.3523C9.95036 13.211 9.83555 13.0964 9.69393 13.0964C9.55231 13.0964 9.4375 13.211 9.4375 13.3523C9.4375 13.4936 9.55231 13.6081 9.69393 13.6081Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M12.5826 15.0115C12.7242 15.0115 12.839 14.8969 12.839 14.7556C12.839 14.6143 12.7242 14.4998 12.5826 14.4998C12.441 14.4998 12.3262 14.6143 12.3262 14.7556C12.3262 14.8969 12.441 15.0115 12.5826 15.0115Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M14.8248 12.8401C14.9664 12.8401 15.0812 12.7255 15.0812 12.5842C15.0812 12.4429 14.9664 12.3284 14.8248 12.3284C14.6832 12.3284 14.5684 12.4429 14.5684 12.5842C14.5684 12.7255 14.6832 12.8401 14.8248 12.8401Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M16.8795 13.1811C17.0211 13.1811 17.1359 13.0666 17.1359 12.9253C17.1359 12.784 17.0211 12.6694 16.8795 12.6694C16.7379 12.6694 16.623 12.784 16.623 12.9253C16.623 13.0666 16.7379 13.1811 16.8795 13.1811Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M15.2408 15.5774C15.3824 15.5774 15.4972 15.4628 15.4972 15.3215C15.4972 15.1802 15.3824 15.0657 15.2408 15.0657C15.0992 15.0657 14.9844 15.1802 14.9844 15.3215C14.9844 15.4628 15.0992 15.5774 15.2408 15.5774Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M12.9107 17.1694C13.0524 17.1694 13.1672 17.0549 13.1672 16.9136C13.1672 16.7723 13.0524 16.6577 12.9107 16.6577C12.7691 16.6577 12.6543 16.7723 12.6543 16.9136C12.6543 17.0549 12.7691 17.1694 12.9107 17.1694Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M10.1978 16.4729C10.3395 16.4729 10.4543 16.3583 10.4543 16.217C10.4543 16.0757 10.3395 15.9612 10.1978 15.9612C10.0562 15.9612 9.94141 16.0757 9.94141 16.217C9.94141 16.3583 10.0562 16.4729 10.1978 16.4729Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M7.67831 14.6355C7.81993 14.6355 7.93474 14.5209 7.93474 14.3796C7.93474 14.2383 7.81993 14.1238 7.67831 14.1238C7.53668 14.1238 7.42188 14.2383 7.42188 14.3796C7.42188 14.5209 7.53668 14.6355 7.67831 14.6355Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M5.97564 12.8013C6.07006 12.8013 6.1466 12.725 6.1466 12.6308C6.1466 12.5366 6.07006 12.4602 5.97564 12.4602C5.88123 12.4602 5.80469 12.5366 5.80469 12.6308C5.80469 12.725 5.88123 12.8013 5.97564 12.8013Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M7.6412 11.6448C7.78282 11.6448 7.89763 11.5302 7.89763 11.3889C7.89763 11.2476 7.78282 11.1331 7.6412 11.1331C7.49957 11.1331 7.38477 11.2476 7.38477 11.3889C7.38477 11.5302 7.49957 11.6448 7.6412 11.6448Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M8.41073 8.99974C8.55235 8.99974 8.66716 8.88519 8.66716 8.74389C8.66716 8.60258 8.55235 8.48804 8.41073 8.48804C8.2691 8.48804 8.1543 8.60258 8.1543 8.74389C8.1543 8.88519 8.2691 8.99974 8.41073 8.99974Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M10.1217 10.4502C10.2633 10.4502 10.3781 10.3356 10.3781 10.1943C10.3781 10.053 10.2633 9.93848 10.1217 9.93848C9.98004 9.93848 9.86523 10.053 9.86523 10.1943C9.86523 10.3356 9.98004 10.4502 10.1217 10.4502Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M13.2857 9.93846C13.4274 9.93846 13.5422 9.82391 13.5422 9.68261C13.5422 9.54131 13.4274 9.42676 13.2857 9.42676C13.1441 9.42676 13.0293 9.54131 13.0293 9.68261C13.0293 9.82391 13.1441 9.93846 13.2857 9.93846Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M15.9381 10.1943C16.0797 10.1943 16.1945 10.0798 16.1945 9.93847C16.1945 9.79716 16.0797 9.68262 15.9381 9.68262C15.7964 9.68262 15.6816 9.79716 15.6816 9.93847C15.6816 10.0798 15.7964 10.1943 15.9381 10.1943Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M14.1412 8.06028C14.2828 8.06028 14.3976 7.94574 14.3976 7.80443C14.3976 7.66313 14.2828 7.54858 14.1412 7.54858C13.9996 7.54858 13.8848 7.66313 13.8848 7.80443C13.8848 7.94574 13.9996 8.06028 14.1412 8.06028Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M11.3111 8.05052C11.4527 8.05052 11.5676 7.93597 11.5676 7.79467C11.5676 7.65337 11.4527 7.53882 11.3111 7.53882C11.1695 7.53882 11.0547 7.65337 11.0547 7.79467C11.0547 7.93597 11.1695 8.05052 11.3111 8.05052Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M9.77838 6.78034C9.87279 6.78034 9.94933 6.70398 9.94933 6.60978C9.94933 6.51557 9.87279 6.43921 9.77838 6.43921C9.68396 6.43921 9.60742 6.51557 9.60742 6.60978C9.60742 6.70398 9.68396 6.78034 9.77838 6.78034Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M8.1983 6.91291C8.29271 6.91291 8.36925 6.83654 8.36925 6.74234C8.36925 6.64814 8.29271 6.57178 8.1983 6.57178C8.10388 6.57178 8.02734 6.64814 8.02734 6.74234C8.02734 6.83654 8.10388 6.91291 8.1983 6.91291Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M6.35845 8.91438C6.45287 8.91438 6.52941 8.83801 6.52941 8.74381C6.52941 8.64961 6.45287 8.57324 6.35845 8.57324C6.26404 8.57324 6.1875 8.64961 6.1875 8.74381C6.1875 8.83801 6.26404 8.91438 6.35845 8.91438Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M6.31744 10.2654C6.41185 10.2654 6.48839 10.1891 6.48839 10.0949C6.48839 10.0007 6.41185 9.92432 6.31744 9.92432C6.22302 9.92432 6.14648 10.0007 6.14648 10.0949C6.14648 10.1891 6.22302 10.2654 6.31744 10.2654Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M5.46197 11.4361C5.55639 11.4361 5.63292 11.3597 5.63292 11.2655C5.63292 11.1713 5.55639 11.095 5.46197 11.095C5.36755 11.095 5.29102 11.1713 5.29102 11.2655C5.29102 11.3597 5.36755 11.4361 5.46197 11.4361Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M5.55572 13.9178C5.65014 13.9178 5.72667 13.8414 5.72667 13.7472C5.72667 13.653 5.65014 13.5767 5.55572 13.5767C5.4613 13.5767 5.38477 13.653 5.38477 13.7472C5.38477 13.8414 5.4613 13.9178 5.55572 13.9178Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M6.31744 15.3023C6.41185 15.3023 6.48839 15.2259 6.48839 15.1317C6.48839 15.0375 6.41185 14.9612 6.31744 14.9612C6.22302 14.9612 6.14648 15.0375 6.14648 15.1317C6.14648 15.2259 6.22302 15.3023 6.31744 15.3023Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M6.91314 16.4671C7.00756 16.4671 7.0841 16.3907 7.0841 16.2965C7.0841 16.2023 7.00756 16.126 6.91314 16.126C6.81873 16.126 6.74219 16.2023 6.74219 16.2965C6.74219 16.3907 6.81873 16.4671 6.91314 16.4671Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M8.29791 16.9835C8.39232 16.9835 8.46886 16.9071 8.46886 16.8129C8.46886 16.7187 8.39232 16.6423 8.29791 16.6423C8.20349 16.6423 8.12695 16.7187 8.12695 16.8129C8.12695 16.9071 8.20349 16.9835 8.29791 16.9835Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M9.17095 18.1075C9.26537 18.1075 9.34191 18.0311 9.34191 17.9369C9.34191 17.8427 9.26537 17.7664 9.17095 17.7664C9.07654 17.7664 9 17.8427 9 17.9369C9 18.0311 9.07654 18.1075 9.17095 18.1075Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M10.5499 18.0462C10.6443 18.0462 10.7208 17.9698 10.7208 17.8756C10.7208 17.7814 10.6443 17.7051 10.5499 17.7051C10.4554 17.7051 10.3789 17.7814 10.3789 17.8756C10.3789 17.9698 10.4554 18.0462 10.5499 18.0462Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M11.6456 18.7047C11.74 18.7047 11.8165 18.6283 11.8165 18.5341C11.8165 18.4399 11.74 18.3635 11.6456 18.3635C11.5511 18.3635 11.4746 18.4399 11.4746 18.5341C11.4746 18.6283 11.5511 18.7047 11.6456 18.7047Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M13.0362 18.2916C13.1306 18.2916 13.2071 18.2152 13.2071 18.121C13.2071 18.0268 13.1306 17.9504 13.0362 17.9504C12.9418 17.9504 12.8652 18.0268 12.8652 18.121C12.8652 18.2152 12.9418 18.2916 13.0362 18.2916Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M14.4991 18.2879C14.5935 18.2879 14.67 18.2115 14.67 18.1173C14.67 18.0231 14.5935 17.9468 14.4991 17.9468C14.4047 17.9468 14.3281 18.0231 14.3281 18.1173C14.3281 18.2115 14.4047 18.2879 14.4991 18.2879Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M15.2393 17.2249C15.3337 17.2249 15.4103 17.1486 15.4103 17.0544C15.4103 16.9602 15.3337 16.8838 15.2393 16.8838C15.1449 16.8838 15.0684 16.9602 15.0684 17.0544C15.0684 17.1486 15.1449 17.2249 15.2393 17.2249Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M16.4932 16.8936C16.5876 16.8936 16.6642 16.8173 16.6642 16.7231C16.6642 16.6289 16.5876 16.5525 16.4932 16.5525C16.3988 16.5525 16.3223 16.6289 16.3223 16.7231C16.3223 16.8173 16.3988 16.8936 16.4932 16.8936Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M17.1104 15.4417C17.2048 15.4417 17.2814 15.3654 17.2814 15.2712C17.2814 15.177 17.2048 15.1006 17.1104 15.1006C17.016 15.1006 16.9395 15.177 16.9395 15.2712C16.9395 15.3654 17.016 15.4417 17.1104 15.4417Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M18.0596 14.5179C18.154 14.5179 18.2306 14.4415 18.2306 14.3473C18.2306 14.2531 18.154 14.1768 18.0596 14.1768C17.9652 14.1768 17.8887 14.2531 17.8887 14.3473C17.8887 14.4415 17.9652 14.5179 18.0596 14.5179Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M18.0772 13.2664C18.1716 13.2664 18.2482 13.1901 18.2482 13.0959C18.2482 13.0017 18.1716 12.9253 18.0772 12.9253C17.9828 12.9253 17.9062 13.0017 17.9062 13.0959C17.9062 13.1901 17.9828 13.2664 18.0772 13.2664Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M18.4971 11.9317C18.5915 11.9317 18.6681 11.8553 18.6681 11.7611C18.6681 11.6669 18.5915 11.5906 18.4971 11.5906C18.4027 11.5906 18.3262 11.6669 18.3262 11.7611C18.3262 11.8553 18.4027 11.9317 18.4971 11.9317Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M17.7354 10.7066C17.8298 10.7066 17.9064 10.6302 17.9064 10.536C17.9064 10.4418 17.8298 10.3655 17.7354 10.3655C17.641 10.3655 17.5645 10.4418 17.5645 10.536C17.5645 10.6302 17.641 10.7066 17.7354 10.7066Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M17.8018 9.29816C17.8962 9.29816 17.9728 9.2218 17.9728 9.1276C17.9728 9.0334 17.8962 8.95703 17.8018 8.95703C17.7074 8.95703 17.6309 9.0334 17.6309 9.1276C17.6309 9.2218 17.7074 9.29816 17.8018 9.29816Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M16.5362 8.48737C16.6306 8.48737 16.7071 8.41101 16.7071 8.31681C16.7071 8.22261 16.6306 8.14624 16.5362 8.14624C16.4418 8.14624 16.3652 8.22261 16.3652 8.31681C16.3652 8.41101 16.4418 8.48737 16.5362 8.48737Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M12.294 6.34675C12.3884 6.34675 12.465 6.27038 12.465 6.17618C12.465 6.08198 12.3884 6.00562 12.294 6.00562C12.1996 6.00562 12.123 6.08198 12.123 6.17618C12.123 6.27038 12.1996 6.34675 12.294 6.34675Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M10.8487 5.98518C10.9431 5.98518 11.0196 5.90881 11.0196 5.81461C11.0196 5.72041 10.9431 5.64404 10.8487 5.64404C10.7543 5.64404 10.6777 5.72041 10.6777 5.81461C10.6777 5.90881 10.7543 5.98518 10.8487 5.98518Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M7.53033 8.04939C7.62475 8.04939 7.70128 7.97302 7.70128 7.87882C7.70128 7.78462 7.62475 7.70825 7.53033 7.70825C7.43591 7.70825 7.35938 7.78462 7.35938 7.87882C7.35938 7.97302 7.43591 8.04939 7.53033 8.04939Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M14.7315 6.79011C14.8259 6.79011 14.9025 6.71374 14.9025 6.61954C14.9025 6.52534 14.8259 6.44897 14.7315 6.44897C14.6371 6.44897 14.5605 6.52534 14.5605 6.61954C14.5605 6.71374 14.6371 6.79011 14.7315 6.79011Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M16.0518 7.21638C16.1462 7.21638 16.2228 7.14001 16.2228 7.04581C16.2228 6.95161 16.1462 6.87524 16.0518 6.87524C15.9574 6.87524 15.8809 6.95161 15.8809 7.04581C15.8809 7.14001 15.9574 7.21638 16.0518 7.21638Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M13.5635 5.96955C13.6579 5.96955 13.7345 5.89319 13.7345 5.79898C13.7345 5.70478 13.6579 5.62842 13.5635 5.62842C13.4691 5.62842 13.3926 5.70478 13.3926 5.79898C13.3926 5.89319 13.4691 5.96955 13.5635 5.96955Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M13.2869 10.3197C13.6396 10.3197 13.9254 10.0345 13.9254 9.68269C13.9254 9.33087 13.6396 9.04565 13.2869 9.04565C12.9343 9.04565 12.6484 9.33087 12.6484 9.68269C12.6484 10.0345 12.9343 10.3197 13.2869 10.3197Z"
        stroke="white"
        strokeMiterlimit="10"
      />
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M10.9704 3.96119L9.69336 4.03149L9.69539 4.06827L10.9724 3.99796L10.9704 3.96119Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M7.02331 20.7647L7 20.7363L7.87485 20.0179L7.64496 19.1276L7.11267 18.2708L7.14376 18.2515L7.67993 19.1159L7.91629 20.0314L7.02331 20.7647Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M6.20703 19.3621V18.3781L7.2347 18.2185L6.20703 19.3621ZM6.24329 18.4097V19.2665L7.13822 18.2702L6.24329 18.4097Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M4.56836 18.4525L5.48594 17.2495L4.95365 15.5703L4.98862 15.5593L5.52868 17.2631L6.25783 18.4112L4.56836 18.4525ZM5.50213 17.2889L4.64348 18.4137L6.19243 18.3763L5.50213 17.2889Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M7.11827 18.276L5.55053 17.2552L4.7793 17.1938L4.87773 15.5637L4.91399 15.5657L4.81815 17.1602L5.56672 17.2216L7.13834 18.2456L7.11827 18.276Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path opacity="0.5" d="M4.6052 18.4395H4.07227V18.4763H4.6052V18.4395Z" fill="white" />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M9.39039 20.1923L9.38197 20.1872L7.6543 19.136L7.67308 19.105L7.72099 19.134L7.72877 19.103L10.3138 19.7582L9.39039 20.1923ZM7.73395 19.1418L9.39298 20.1503L10.2063 19.7679L7.73395 19.1418Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M10.1843 20.9269L9.41502 20.2149L7.98393 20.0023L8.49808 20.684L8.46894 20.7059L7.90039 19.9526L9.43186 20.1807L10.1565 20.8506L10.2756 19.8298L10.3125 19.8344L10.1843 20.9269Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M10.2168 20.907L10.209 20.8708L11.8428 20.5187L10.2873 19.7795L10.3035 19.7466L11.9561 20.5322L10.2168 20.907Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M13.7903 19.7278L13.7871 19.6916L15.9098 19.5023L16.8934 18.2024L16.9226 18.2244L15.9292 19.5372L13.7903 19.7278Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M13.3854 21.004L13.3828 20.9678L15.6616 20.7817L13.8134 19.779L13.8309 19.7473L15.7878 20.8082L13.3854 21.004Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M16.6453 19.627L15.9434 19.5649L15.9466 19.5287L16.6168 19.5882C16.723 19.1282 16.9186 18.2766 16.9225 18.2476L16.9587 18.2527C16.9529 18.2908 16.6615 19.5578 16.6485 19.6121L16.6453 19.627Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M17.936 18.1016L17.9043 18.0842L18.9702 15.5825L19.0038 15.5967L18.0105 17.9278L18.5499 17.1409L18.9961 15.6155L19.031 15.6258L18.5816 17.159L17.936 18.1016Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M18.6474 18.2076L18.5801 17.1351L19.8376 16.8018L18.6474 18.2076ZM18.6189 17.1623L18.6785 18.114L19.7347 16.8664L18.6189 17.1623Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M18.6614 18.2218C18.6322 18.2186 17.9523 18.1456 17.918 18.1404L17.9238 18.1042C17.9575 18.1094 18.6581 18.185 18.6652 18.1857L18.6614 18.2218Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M18.136 19.7684L16.9062 18.1997L17.9417 18.0627L17.9462 18.0996L16.9749 18.2275L18.0816 19.6392L17.8763 18.1248L17.9126 18.1196L18.136 19.7684Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M15.7433 20.7978L15.707 20.792C15.709 20.7804 15.9013 19.6148 15.9013 19.5205H15.9382C15.9376 19.6174 15.7511 20.7494 15.7433 20.7978Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M15.7828 20.7568L15.7637 20.7883L16.2765 21.0985L16.2956 21.067L15.7828 20.7568Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M13.3651 20.9915L13.3302 20.9798L13.7252 19.78L11.9062 20.4927L11.8926 20.4584L13.7847 19.718L13.3651 20.9915Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M11.9734 20.5076L11.9609 20.5422L13.3534 21.0395L13.3659 21.0049L11.9734 20.5076Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M19.0565 15.5973L19.0234 15.5812L20.3723 12.8456L19.851 12.1918L19.8795 12.1692L20.4157 12.8411L20.4105 12.8515L19.0565 15.5973Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M20.5952 15.6956L20.0221 14.6605C19.9276 14.7672 19.688 15.0204 19.0812 15.6361L19.0547 15.6109L20.0221 14.5843L20.0299 14.5985C20.039 14.5875 20.0454 14.5798 20.048 14.5753C20.0623 14.4803 20.3524 13.1397 20.412 12.8657L20.4476 12.8735C20.3479 13.3341 20.0875 14.5397 20.0843 14.5811C20.0843 14.5843 20.0843 14.5895 20.048 14.6315L20.6276 15.6781L20.5952 15.6956Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M20.0318 14.5721L20.0059 14.5462L20.9649 13.5855L20.3996 12.8522L20.4281 12.8296L21.0141 13.5887L20.0318 14.5721Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M21.7276 13.0252L21.6913 13.0188L21.7872 12.4735L20.8398 10.9901L20.6585 11.1891C20.2784 11.6239 19.8212 12.1524 19.8024 12.1879L19.7694 12.1724C19.7713 12.1685 19.7791 12.1575 19.7921 12.1414L19.7461 12.1918L19.9054 9.82129L20.8094 10.9687L20.845 10.9293L21.826 12.4651L21.7276 13.0252ZM19.9358 9.91949L19.7901 12.0897L20.6319 11.1645C20.7012 11.0857 20.7543 11.0243 20.7815 10.9933L19.9358 9.91949Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M19.8947 9.90095L18.9512 8.75609L20.1375 8.48926L19.8947 9.90095ZM19.0179 8.7787L19.8726 9.81567L20.0928 8.53707L19.0179 8.7787Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M18.9697 8.83873L18.9341 8.83097L19.153 7.83406L18.3047 6.59229H19.671V6.62847H18.374L19.1925 7.82696L18.9697 8.83873Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M18.9882 8.76829L18.3251 6.59615L16.918 6.12644L16.9296 6.09155L18.3549 6.56707L18.3575 6.57612L19.0238 8.7573L18.9882 8.76829Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M16.8571 6.19371L16.8403 6.11036C16.8293 6.11876 16.8222 6.12328 16.8196 6.12393L16.8112 6.08839L16.8157 6.10648L16.8086 6.08969C16.8118 6.08775 16.8202 6.08193 16.8332 6.0716L16.6836 5.32924L18.3193 4.82788L18.2559 4.88409C17.888 5.20843 17.089 5.91137 16.874 6.08516L16.8947 6.1866L16.8571 6.19371ZM16.725 5.35509L16.8643 6.04575C17.0585 5.88552 17.6957 5.32795 18.1665 4.91316L16.725 5.35509Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M13.7688 4.57805L13.7598 4.61377L16.7393 5.3601L16.7483 5.32439L13.7688 4.57805Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M16.7722 5.37879L15.7271 4.43615L13.7663 4.56989L13.7637 4.53307L15.74 4.39868L16.7968 5.35166L16.7722 5.37879Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M10.9141 4.00209L12.1716 2.99097L12.7227 3.75722L13.2899 3.19448L13.3158 3.22033L12.7182 3.81343L12.1645 3.04395L11.03 3.95622L12.7687 3.81602L12.7719 3.8522L10.9141 4.00209Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M7.08385 6.1594L7.05859 6.13291L8.24362 4.74964L9.70903 4.03377L9.71227 4.04024L9.73429 4.02344L9.7453 4.04088C9.90913 4.30901 10.1105 4.58618 10.1636 4.58618V4.623C10.0678 4.623 9.80099 4.20176 9.72458 4.07771L7.08385 6.1594ZM8.26305 4.7813L7.20753 6.01467L9.60219 4.12681L8.26305 4.7813Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M6.50095 5.54877L6.4744 5.52357L6.4867 5.51129L4.98438 5.32328L4.98891 5.2871L6.51843 5.47834L8.47534 3.45093L8.27654 4.77476L8.26683 4.77863L6.53786 5.51711L6.53203 5.51646L6.50095 5.54877ZM8.42224 3.55882L6.59225 5.45379L8.24352 4.74827L8.42224 3.55882Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M8.49894 3.48789L8.48438 3.52173L9.70837 4.04615L9.72293 4.01231L8.49894 3.48789Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M5.25195 7.27721L6.48619 5.44556L7.10072 6.07226L5.25195 7.27721ZM6.49137 5.50306L5.38341 7.14799L7.04309 6.06644L6.49137 5.50306Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M3.88221 9.1269L4.01431 7.33466L2.58774 8.8885L2.56055 8.8633L4.05899 7.23193L4.0551 7.28685L4.07777 7.27393L4.99989 8.84392L3.88221 9.1269ZM4.05316 7.30623L3.92235 9.07909L4.9429 8.82066L4.05316 7.30623Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M5.27496 7.18367L4.97266 8.80859L5.00895 8.81531L5.31125 7.19039L5.27496 7.18367Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M5.31643 7.19385L4.06055 7.22192L4.06137 7.25874L5.31725 7.23066L5.31643 7.19385Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M4.17552 12.1538L4.07838 9.96291L3.88477 9.09198L3.92038 9.08423L4.11465 9.95838L4.21243 12.1525L4.17552 12.1538Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M3.0918 10.9379L3.88635 9.08105L3.92002 9.09527L3.19605 10.788L4.04953 9.97394L4.07479 10.0004L3.0918 10.9379Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M2.70203 14.1463L2.49805 12.7268L3.6617 13.172C4.02045 12.4483 4.13183 12.2603 4.17003 12.2112L3.1333 10.8932L3.16244 10.8706L4.22767 12.2248L4.20047 12.2287C4.15579 12.2739 3.8741 12.8263 3.69732 13.1829L3.72128 13.2088L2.70203 14.1463ZM2.54338 12.783L2.72793 14.072L3.663 13.2114L2.54338 12.783Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M4.91904 8.83204L4.12891 9.97729L4.15931 9.99818L4.94945 8.85292L4.91904 8.83204Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M4.22764 12.1628L2.51562 12.6992L2.52668 12.7344L4.2387 12.198L4.22764 12.1628Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M4.88425 15.5444L4.17518 14.7019L3.70117 13.1746L3.73614 13.1636L4.2095 14.6877L4.9121 15.5205L4.88425 15.5444Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M4.21528 14.6734C4.19845 14.6308 4.17902 12.615 4.17578 12.2112H4.21269C4.21852 12.8721 4.2373 14.6011 4.25025 14.6618L4.21528 14.6734Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M3.68082 15.6025L3.64844 15.5857C4.12569 14.6818 4.14058 14.6767 4.14965 14.6741L4.1613 14.709L4.15547 14.6915L4.16778 14.7051C4.14382 14.7316 3.86343 15.2562 3.68082 15.6025Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M13.7344 4.61643L10.9765 3.99878L10.2661 4.60933L10.2422 4.58155L10.9662 3.95937L13.7137 4.57444L14.1424 3.64989L12.7223 3.82175L12.7178 3.78557L14.2033 3.60596L13.7344 4.61643Z"
          fill="white"
        />
      </g>
      <g opacity="0.5">
        {/* eslint-disable-next-line prettier/prettier */}
        <path
          opacity="0.5"
          d="M14.1815 3.56764L14.1641 3.6001L15.7241 4.43389L15.7415 4.40142L14.1815 3.56764Z"
          fill="white"
        />
      </g>
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M16.8746 18.5726C17.0534 18.5726 17.1983 18.428 17.1983 18.2496C17.1983 18.0711 17.0534 17.9265 16.8746 17.9265C16.6957 17.9265 16.5508 18.0711 16.5508 18.2496C16.5508 18.428 16.6957 18.5726 16.8746 18.5726Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M13.7345 19.8415H10.2228L7.05887 18.3212L4.86949 15.5818L4.08789 12.166L4.86949 8.75012L7.05887 6.01072L10.2228 4.49048H13.7345L16.8985 6.01072L19.0879 8.75012L19.8695 12.166L19.0879 15.5818L16.8985 18.3212L13.7345 19.8415ZM10.2649 19.6586H13.6924L16.7806 18.1746L18.9175 15.5004L19.6804 12.166L18.9175 8.83153L16.7806 6.15738L13.6924 4.67332H10.2649L7.17608 6.15803L5.03915 8.83217L4.27633 12.1666L5.03915 15.5011L7.17608 18.1752L10.2649 19.6586Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M4.19292 12.5025C4.37174 12.5025 4.5167 12.3579 4.5167 12.1795C4.5167 12.0011 4.37174 11.8564 4.19292 11.8564C4.0141 11.8564 3.86914 12.0011 3.86914 12.1795C3.86914 12.3579 4.0141 12.5025 4.19292 12.5025Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M4.97221 9.13534C5.15103 9.13534 5.29599 8.99071 5.29599 8.8123C5.29599 8.63389 5.15103 8.48926 4.97221 8.48926C4.7934 8.48926 4.64844 8.63389 4.64844 8.8123C4.64844 8.99071 4.7934 9.13534 4.97221 9.13534Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M7.11089 6.42953C7.2897 6.42953 7.43466 6.2849 7.43466 6.10649C7.43466 5.92808 7.2897 5.78345 7.11089 5.78345C6.93207 5.78345 6.78711 5.92808 6.78711 6.10649C6.78711 6.2849 6.93207 6.42953 7.11089 6.42953Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M10.2378 4.92734C10.4167 4.92734 10.5616 4.7827 10.5616 4.60429C10.5616 4.42588 10.4167 4.28125 10.2378 4.28125C10.059 4.28125 9.91406 4.42588 9.91406 4.60429C9.91406 4.7827 10.059 4.92734 10.2378 4.92734Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M13.7652 4.91879C13.944 4.91879 14.089 4.77416 14.089 4.59575C14.089 4.41734 13.944 4.27271 13.7652 4.27271C13.5864 4.27271 13.4414 4.41734 13.4414 4.59575C13.4414 4.77416 13.5864 4.91879 13.7652 4.91879Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M16.8746 6.43197C17.0534 6.43197 17.1983 6.28734 17.1983 6.10893C17.1983 5.93052 17.0534 5.78589 16.8746 5.78589C16.6957 5.78589 16.5508 5.93052 16.5508 6.10893C16.5508 6.28734 16.6957 6.43197 16.8746 6.43197Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M19.0015 9.12094C19.1803 9.12094 19.3253 8.97631 19.3253 8.7979C19.3253 8.61948 19.1803 8.47485 19.0015 8.47485C18.8227 8.47485 18.6777 8.61948 18.6777 8.7979C18.6777 8.97631 18.8227 9.12094 19.0015 9.12094Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M19.7867 12.5033C19.9655 12.5033 20.1104 12.3586 20.1104 12.1802C20.1104 12.0018 19.9655 11.8572 19.7867 11.8572C19.6079 11.8572 19.4629 12.0018 19.4629 12.1802C19.4629 12.3586 19.6079 12.5033 19.7867 12.5033Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M18.9703 15.9229C19.1491 15.9229 19.294 15.7783 19.294 15.5999C19.294 15.4215 19.1491 15.2769 18.9703 15.2769C18.7914 15.2769 18.6465 15.4215 18.6465 15.5999C18.6465 15.7783 18.7914 15.9229 18.9703 15.9229Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M13.7535 20.0328C13.9323 20.0328 14.0772 19.8882 14.0772 19.7098C14.0772 19.5313 13.9323 19.3867 13.7535 19.3867C13.5746 19.3867 13.4297 19.5313 13.4297 19.7098C13.4297 19.8882 13.5746 20.0328 13.7535 20.0328Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M10.2593 20.0865C10.4381 20.0865 10.5831 19.9419 10.5831 19.7635C10.5831 19.5851 10.4381 19.4404 10.2593 19.4404C10.0805 19.4404 9.93555 19.5851 9.93555 19.7635C9.93555 19.9419 10.0805 20.0865 10.2593 20.0865Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M7.148 18.5855C7.32681 18.5855 7.47177 18.4409 7.47177 18.2625C7.47177 18.0841 7.32681 17.9395 7.148 17.9395C6.96918 17.9395 6.82422 18.0841 6.82422 18.2625C6.82422 18.4409 6.96918 18.5855 7.148 18.5855Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M4.97026 15.888C5.14908 15.888 5.29404 15.7434 5.29404 15.565C5.29404 15.3866 5.14908 15.2419 4.97026 15.2419C4.79144 15.2419 4.64648 15.3866 4.64648 15.565C4.64648 15.7434 4.79144 15.888 4.97026 15.888Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M7.66354 19.2175C7.71718 19.2175 7.76067 19.1741 7.76067 19.1206C7.76067 19.0671 7.71718 19.0237 7.66354 19.0237C7.60989 19.0237 7.56641 19.0671 7.56641 19.1206C7.56641 19.1741 7.60989 19.2175 7.66354 19.2175Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M6.22409 18.4905C6.27773 18.4905 6.32122 18.4471 6.32122 18.3935C6.32122 18.34 6.27773 18.2966 6.22409 18.2966C6.17044 18.2966 6.12695 18.34 6.12695 18.3935C6.12695 18.4471 6.17044 18.4905 6.22409 18.4905Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M4.80026 17.2554C4.8539 17.2554 4.89739 17.212 4.89739 17.1584C4.89739 17.1049 4.8539 17.0615 4.80026 17.0615C4.74661 17.0615 4.70312 17.1049 4.70312 17.1584C4.70312 17.212 4.74661 17.2554 4.80026 17.2554Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M4.19284 14.7634C4.24648 14.7634 4.28997 14.72 4.28997 14.6665C4.28997 14.613 4.24648 14.5696 4.19284 14.5696C4.13919 14.5696 4.0957 14.613 4.0957 14.6665C4.0957 14.72 4.13919 14.7634 4.19284 14.7634Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M4.60495 18.53C4.65859 18.53 4.70208 18.4866 4.70208 18.4331C4.70208 18.3796 4.65859 18.3362 4.60495 18.3362C4.5513 18.3362 4.50781 18.3796 4.50781 18.4331C4.50781 18.4866 4.5513 18.53 4.60495 18.53Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M5.5112 17.3425C5.56484 17.3425 5.60833 17.2991 5.60833 17.2456C5.60833 17.1921 5.56484 17.1487 5.5112 17.1487C5.45755 17.1487 5.41406 17.1921 5.41406 17.2456C5.41406 17.2991 5.45755 17.3425 5.5112 17.3425Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M17.9214 18.1882C17.975 18.1882 18.0185 18.1448 18.0185 18.0913C18.0185 18.0378 17.975 17.9944 17.9214 17.9944C17.8677 17.9944 17.8242 18.0378 17.8242 18.0913C17.8242 18.1448 17.8677 18.1882 17.9214 18.1882Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M18.1089 19.8008C18.1625 19.8008 18.206 19.7574 18.206 19.7038C18.206 19.6503 18.1625 19.6069 18.1089 19.6069C18.0552 19.6069 18.0117 19.6503 18.0117 19.7038C18.0117 19.7574 18.0552 19.8008 18.1089 19.8008Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M18.6323 18.2754C18.6859 18.2754 18.7294 18.232 18.7294 18.1785C18.7294 18.1249 18.6859 18.0815 18.6323 18.0815C18.5786 18.0815 18.5352 18.1249 18.5352 18.1785C18.5352 18.232 18.5786 18.2754 18.6323 18.2754Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M6.22409 19.4114C6.27773 19.4114 6.32122 19.368 6.32122 19.3144C6.32122 19.2609 6.27773 19.2175 6.22409 19.2175C6.17044 19.2175 6.12695 19.2609 6.12695 19.3144C6.12695 19.368 6.17044 19.4114 6.22409 19.4114Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M11.8979 20.622C11.9516 20.622 11.995 20.5787 11.995 20.5251C11.995 20.4716 11.9516 20.4282 11.8979 20.4282C11.8443 20.4282 11.8008 20.4716 11.8008 20.5251C11.8008 20.5787 11.8443 20.622 11.8979 20.622Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M15.9194 19.6167C15.973 19.6167 16.0165 19.5733 16.0165 19.5198C16.0165 19.4662 15.973 19.4229 15.9194 19.4229C15.8658 19.4229 15.8223 19.4662 15.8223 19.5198C15.8223 19.5733 15.8658 19.6167 15.9194 19.6167Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M15.726 20.8913C15.7797 20.8913 15.8232 20.8479 15.8232 20.7944C15.8232 20.7409 15.7797 20.6975 15.726 20.6975C15.6724 20.6975 15.6289 20.7409 15.6289 20.7944C15.6289 20.8479 15.6724 20.8913 15.726 20.8913Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M16.6303 19.7038C16.684 19.7038 16.7275 19.6604 16.7275 19.6069C16.7275 19.5534 16.684 19.51 16.6303 19.51C16.5767 19.51 16.5332 19.5534 16.5332 19.6069C16.5332 19.6604 16.5767 19.7038 16.6303 19.7038Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M13.3842 21.0827C13.4379 21.0827 13.4814 21.0394 13.4814 20.9858C13.4814 20.9323 13.4379 20.8889 13.3842 20.8889C13.3306 20.8889 13.2871 20.9323 13.2871 20.9858C13.2871 21.0394 13.3306 21.0827 13.3842 21.0827Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M3.90182 9.1909C3.95547 9.1909 3.99895 9.14751 3.99895 9.09398C3.99895 9.04046 3.95547 8.99707 3.90182 8.99707C3.84818 8.99707 3.80469 9.04046 3.80469 9.09398C3.80469 9.14751 3.84818 9.1909 3.90182 9.1909Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M2.5737 8.97312C2.62734 8.97312 2.67083 8.92973 2.67083 8.87621C2.67083 8.82269 2.62734 8.7793 2.5737 8.7793C2.52005 8.7793 2.47656 8.82269 2.47656 8.87621C2.47656 8.92973 2.52005 8.97312 2.5737 8.97312Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M4.09713 10.0844C4.15078 10.0844 4.19427 10.0411 4.19427 9.98754C4.19427 9.93401 4.15078 9.89062 4.09713 9.89062C4.04349 9.89062 4 9.93401 4 9.98754C4 10.0411 4.04349 10.0844 4.09713 10.0844Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M3.66549 15.6621C3.71914 15.6621 3.76263 15.6187 3.76263 15.5652C3.76263 15.5117 3.71914 15.4683 3.66549 15.4683C3.61185 15.4683 3.56836 15.5117 3.56836 15.5652C3.56836 15.6187 3.61185 15.6621 3.66549 15.6621Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M7.8901 20.0742C7.94375 20.0742 7.98723 20.0308 7.98723 19.9773C7.98723 19.9238 7.94375 19.8804 7.8901 19.8804C7.83646 19.8804 7.79297 19.9238 7.79297 19.9773C7.79297 20.0308 7.83646 20.0742 7.8901 20.0742Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M10.1714 20.9858C10.225 20.9858 10.2685 20.9424 10.2685 20.8889C10.2685 20.8354 10.225 20.792 10.1714 20.792C10.1177 20.792 10.0742 20.8354 10.0742 20.8889C10.0742 20.9424 10.1177 20.9858 10.1714 20.9858Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M9.3901 20.268C9.44375 20.268 9.48724 20.2247 9.48724 20.1711C9.48724 20.1176 9.44375 20.0742 9.3901 20.0742C9.33646 20.0742 9.29297 20.1176 9.29297 20.1711C9.29297 20.2247 9.33646 20.268 9.3901 20.268Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M8.48385 20.792C8.5375 20.792 8.58099 20.7486 8.58099 20.6951C8.58099 20.6415 8.5375 20.5981 8.48385 20.5981C8.43021 20.5981 8.38672 20.6415 8.38672 20.6951C8.38672 20.7486 8.43021 20.792 8.48385 20.792Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M20.1147 8.61008C20.1684 8.61008 20.2118 8.5667 20.2118 8.51317C20.2118 8.45965 20.1684 8.41626 20.1147 8.41626C20.0611 8.41626 20.0176 8.45965 20.0176 8.51317C20.0176 8.5667 20.0611 8.61008 20.1147 8.61008Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M19.6714 6.70725C19.725 6.70725 19.7685 6.66386 19.7685 6.61034C19.7685 6.55682 19.725 6.51343 19.6714 6.51343C19.6177 6.51343 19.5742 6.55682 19.5742 6.61034C19.5742 6.66386 19.6177 6.70725 19.6714 6.70725Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M19.1733 7.92771C19.2269 7.92771 19.2704 7.88432 19.2704 7.8308C19.2704 7.77728 19.2269 7.73389 19.1733 7.73389C19.1197 7.73389 19.0762 7.77728 19.0762 7.8308C19.0762 7.88432 19.1197 7.92771 19.1733 7.92771Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M19.9214 9.96751C19.975 9.96751 20.0185 9.92412 20.0185 9.87059C20.0185 9.81707 19.975 9.77368 19.9214 9.77368C19.8677 9.77368 19.8242 9.81707 19.8242 9.87059C19.8242 9.92412 19.8677 9.96751 19.9214 9.96751Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M20.394 12.9665C20.4477 12.9665 20.4911 12.9231 20.4911 12.8696C20.4911 12.8161 20.4477 12.7727 20.394 12.7727C20.3404 12.7727 20.2969 12.8161 20.2969 12.8696C20.2969 12.9231 20.3404 12.9665 20.394 12.9665Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M21.8061 12.5227C21.8598 12.5227 21.9032 12.4793 21.9032 12.4258C21.9032 12.3722 21.8598 12.3289 21.8061 12.3289C21.7525 12.3289 21.709 12.3722 21.709 12.4258C21.709 12.4793 21.7525 12.5227 21.8061 12.5227Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M20.8061 11.091C20.8598 11.091 20.9032 11.0477 20.9032 10.9941C20.9032 10.9406 20.8598 10.8972 20.8061 10.8972C20.7525 10.8972 20.709 10.9406 20.709 10.9941C20.709 11.0477 20.7525 11.091 20.8061 11.091Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M20.9878 13.6836C21.0414 13.6836 21.0849 13.6402 21.0849 13.5867C21.0849 13.5331 21.0414 13.4897 20.9878 13.4897C20.9341 13.4897 20.8906 13.5331 20.8906 13.5867C20.8906 13.6402 20.9341 13.6836 20.9878 13.6836Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M12.1264 3.16477C12.1801 3.16477 12.2236 3.12138 12.2236 3.06786C12.2236 3.01434 12.1801 2.97095 12.1264 2.97095C12.0728 2.97095 12.0293 3.01434 12.0293 3.06786C12.0293 3.12138 12.0728 3.16477 12.1264 3.16477Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M10.1245 3.60154C10.1781 3.60154 10.2216 3.55815 10.2216 3.50463C10.2216 3.4511 10.1781 3.40771 10.1245 3.40771C10.0708 3.40771 10.0273 3.4511 10.0273 3.50463C10.0273 3.55815 10.0708 3.60154 10.1245 3.60154Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M8.4487 3.60154C8.50234 3.60154 8.54583 3.55815 8.54583 3.50463C8.54583 3.4511 8.50234 3.40771 8.4487 3.40771C8.39505 3.40771 8.35156 3.4511 8.35156 3.50463C8.35156 3.55815 8.39505 3.60154 8.4487 3.60154Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M12.7202 3.88181C12.7738 3.88181 12.8173 3.83842 12.8173 3.7849C12.8173 3.73138 12.7738 3.68799 12.7202 3.68799C12.6665 3.68799 12.623 3.73138 12.623 3.7849C12.623 3.83842 12.6665 3.88181 12.7202 3.88181Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M18.5659 17.2456C18.6195 17.2456 18.663 17.2022 18.663 17.1487C18.663 17.0951 18.6195 17.0518 18.5659 17.0518C18.5122 17.0518 18.4688 17.0951 18.4688 17.1487C18.4688 17.2022 18.5122 17.2456 18.5659 17.2456Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M20.6108 15.7834C20.6645 15.7834 20.7079 15.74 20.7079 15.6865C20.7079 15.633 20.6645 15.5896 20.6108 15.5896C20.5572 15.5896 20.5137 15.633 20.5137 15.6865C20.5137 15.74 20.5572 15.7834 20.6108 15.7834Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M20.0171 14.6767C20.0707 14.6767 20.1142 14.6333 20.1142 14.5798C20.1142 14.5263 20.0707 14.4829 20.0171 14.4829C19.9634 14.4829 19.9199 14.5263 19.9199 14.5798C19.9199 14.6333 19.9634 14.6767 20.0171 14.6767Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M19.769 16.9658C19.8227 16.9658 19.8661 16.9224 19.8661 16.8689C19.8661 16.8154 19.8227 16.772 19.769 16.772C19.7154 16.772 19.6719 16.8154 19.6719 16.8689C19.6719 16.9224 19.7154 16.9658 19.769 16.9658Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M4.98776 5.40159C5.0414 5.40159 5.08489 5.3582 5.08489 5.30468C5.08489 5.25115 5.0414 5.20776 4.98776 5.20776C4.93411 5.20776 4.89062 5.25115 4.89062 5.30468C4.89062 5.3582 4.93411 5.40159 4.98776 5.40159Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M8.25143 4.86326C8.30508 4.86326 8.34856 4.81987 8.34856 4.76635C8.34856 4.71282 8.30508 4.66943 8.25143 4.66943C8.19778 4.66943 8.1543 4.71282 8.1543 4.76635C8.1543 4.81987 8.19778 4.86326 8.25143 4.86326Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M6.48776 5.59544C6.5414 5.59544 6.58489 5.55205 6.58489 5.49852C6.58489 5.445 6.5414 5.40161 6.48776 5.40161C6.43411 5.40161 6.39062 5.445 6.39062 5.49852C6.39062 5.55205 6.43411 5.59544 6.48776 5.59544Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M5.31784 7.30931C5.37148 7.30931 5.41497 7.26592 5.41497 7.21239C5.41497 7.15887 5.37148 7.11548 5.31784 7.11548C5.26419 7.11548 5.2207 7.15887 5.2207 7.21239C5.2207 7.26592 5.26419 7.30931 5.31784 7.30931Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M2.52096 12.8515C2.57461 12.8515 2.61809 12.8082 2.61809 12.7546C2.61809 12.7011 2.57461 12.6577 2.52096 12.6577C2.46732 12.6577 2.42383 12.7011 2.42383 12.7546C2.42383 12.8082 2.46732 12.8515 2.52096 12.8515Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M3.66549 13.2663C3.71914 13.2663 3.76263 13.2229 3.76263 13.1694C3.76263 13.1159 3.71914 13.0725 3.66549 13.0725C3.61185 13.0725 3.56836 13.1159 3.56836 13.1694C3.56836 13.2229 3.61185 13.2663 3.66549 13.2663Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M3.13424 10.9636C3.18789 10.9636 3.23138 10.9202 3.23138 10.8667C3.23138 10.8132 3.18789 10.7698 3.13424 10.7698C3.0806 10.7698 3.03711 10.8132 3.03711 10.8667C3.03711 10.9202 3.0806 10.9636 3.13424 10.9636Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M4.06198 7.38059C4.11562 7.38059 4.15911 7.3372 4.15911 7.28368C4.15911 7.23016 4.11562 7.18677 4.06198 7.18677C4.00833 7.18677 3.96484 7.23016 3.96484 7.28368C3.96484 7.3372 4.00833 7.38059 4.06198 7.38059Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M10.9721 4.07566C11.0258 4.07566 11.0693 4.03227 11.0693 3.97875C11.0693 3.92523 11.0258 3.88184 10.9721 3.88184C10.9185 3.88184 10.875 3.92523 10.875 3.97875C10.875 4.03227 10.9185 4.07566 10.9721 4.07566Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M9.71627 4.14744C9.76992 4.14744 9.81341 4.10405 9.81341 4.05053C9.81341 3.997 9.76992 3.95361 9.71627 3.95361C9.66263 3.95361 9.61914 3.997 9.61914 4.05053C9.61914 4.10405 9.66263 4.14744 9.71627 4.14744Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M2.71432 14.2063C2.76797 14.2063 2.81145 14.1629 2.81145 14.1094C2.81145 14.0558 2.76797 14.0125 2.71432 14.0125C2.66068 14.0125 2.61719 14.0558 2.61719 14.1094C2.61719 14.1629 2.66068 14.2063 2.71432 14.2063Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M18.2417 4.93504C18.2953 4.93504 18.3388 4.89165 18.3388 4.83812C18.3388 4.7846 18.2953 4.74121 18.2417 4.74121C18.188 4.74121 18.1445 4.7846 18.1445 4.83812C18.1445 4.89165 18.188 4.93504 18.2417 4.93504Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M18.3393 6.70725C18.393 6.70725 18.4365 6.66386 18.4365 6.61034C18.4365 6.55682 18.393 6.51343 18.3393 6.51343C18.2857 6.51343 18.2422 6.55682 18.2422 6.61034C18.2422 6.66386 18.2857 6.70725 18.3393 6.70725Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M15.7339 4.51438C15.7875 4.51438 15.831 4.47099 15.831 4.41747C15.831 4.36395 15.7875 4.32056 15.7339 4.32056C15.6802 4.32056 15.6367 4.36395 15.6367 4.41747C15.6367 4.47099 15.6802 4.51438 15.7339 4.51438Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M16.8823 4.11131C16.9359 4.11131 16.9794 4.06792 16.9794 4.01439C16.9794 3.96087 16.9359 3.91748 16.8823 3.91748C16.8286 3.91748 16.7852 3.96087 16.7852 4.01439C16.7852 4.06792 16.8286 4.11131 16.8823 4.11131Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M21.101 10.0644C21.1547 10.0644 21.1982 10.021 21.1982 9.96752C21.1982 9.91399 21.1547 9.87061 21.101 9.87061C21.0474 9.87061 21.0039 9.91399 21.0039 9.96752C21.0039 10.021 21.0474 10.0644 21.101 10.0644Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M19.7182 16.1621C19.7719 16.1621 19.8154 16.1187 19.8154 16.0652C19.8154 16.0117 19.7719 15.9683 19.7182 15.9683C19.6646 15.9683 19.6211 16.0117 19.6211 16.0652C19.6211 16.1187 19.6646 16.1621 19.7182 16.1621Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M6.54635 20.268C6.6 20.268 6.64348 20.2247 6.64348 20.1711C6.64348 20.1176 6.6 20.0742 6.54635 20.0742C6.49271 20.0742 6.44922 20.1176 6.44922 20.1711C6.44922 20.2247 6.49271 20.268 6.54635 20.268Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M3.90963 16.7383C3.96328 16.7383 4.00677 16.6949 4.00677 16.6413C4.00677 16.5878 3.96328 16.5444 3.90963 16.5444C3.85599 16.5444 3.8125 16.5878 3.8125 16.6413C3.8125 16.6949 3.85599 16.7383 3.90963 16.7383Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M14.1733 3.72483C14.227 3.72483 14.2704 3.68144 14.2704 3.62792C14.2704 3.5744 14.227 3.53101 14.1733 3.53101C14.1197 3.53101 14.0762 3.5744 14.0762 3.62792C14.0762 3.68144 14.1197 3.72483 14.1733 3.72483Z"
        fill="white"
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M16.7436 5.43894C16.7973 5.43894 16.8408 5.39555 16.8408 5.34203C16.8408 5.28851 16.7973 5.24512 16.7436 5.24512C16.69 5.24512 16.6465 5.28851 16.6465 5.34203C16.6465 5.39555 16.69 5.43894 16.7436 5.43894Z"
        fill="white"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_18_63045"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-8.75 12) scale(38.5833 433.66)"
      >
        <stop stopColor="#538557" />
        <stop offset="1" stopColor="#30A335" />
      </radialGradient>
      <clipPath id="clip0_18_63045">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FCEMLogo;
