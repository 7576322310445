export const ROLES_LIST = [
  {
    url: 'expert',
    label: 'Expert',
    index: 'exp',
  },
  {
    url: 'investor',
    label: 'Investor',
    index: 'inv',
  },
  {
    url: 'business',
    label: 'Business',
    index: 'bsn',
  },
  {
    url: 'individual',
    label: 'Individual',
    index: 'ind',
  },
];
