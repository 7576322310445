import React from 'react';
import clsx from 'clsx';
import styles from '../FceAdmin.module.scss';
import { Button, Input, Modal } from 'tt-ui-lib/core';

const ContractCompileRequestModal = ({
  showCompileDialog,
  setShowCompileDialog,
  ignoreContracts,
  compile,
}) => (
  <Modal
    open={showCompileDialog}
    onClose={() => setShowCompileDialog(false)}
    title="Compile"
    destroyOnClose
  >
    <div>
      <Input
        id="compile_ignore_contracts"
        name="compile_ignore_contracts"
        label="Ignore contracts"
        value={ignoreContracts}
        required
        style={{ width: '100%' }}
      />
    </div>

    <div className={clsx(styles.formButtonGroup, 'formButtonGroup')}>
      <Button
        type="primary"
        isSubmit
        onClick={() => {
          setShowCompileDialog(false);
          compile();
        }}
      >
        Compile
      </Button>
      <Button type="default" onClick={() => setShowCompileDialog(false)}>
        Close
      </Button>
    </div>
  </Modal>
);

export default ContractCompileRequestModal;
