import React from 'react';
import clsx from 'clsx';
import { Button } from 'tt-ui-lib/core';

import styles from './RemoveUserModal.module.scss';

const RemoveUserModal = ({ handleClose, loading, deleteUser }) => (
  <div className={clsx(styles.removeUserModal, 'removeUserModal')}>
    <div className={clsx(styles.modalActions, 'modalActions')}>
      <Button type="default" onClick={handleClose}>
        CANCEL
      </Button>
      <Button
        type="primary"
        onClick={async () => {
          await deleteUser();
          handleClose();
        }}
        loading={loading}
      >
        YES
      </Button>
    </div>
  </div>
);

export default RemoveUserModal;
