/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unknown-property */

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Loader, Table } from 'tt-ui-lib/core';
import { CopyIcon, DownloadIcon, ShareIcon } from 'tt-ui-lib/icons';
import TransactionDetailModal from '../TransactionDetailModal';
import {
  useDigitalAssets,
  useDigitalAssetsAPI,
  useDigitalAssetsWallet,
} from 'modules/tt-digital-assets-provider';

import styles from './styles.module.scss';
import { getAuthorizedImageByLink } from '../../../../../api/blockchain/endpoints/blockcahin-da-api';

const { REACT_APP_SSO_API } = process.env;

const CustomerCarbonCertificate = (props) => {
  const { className, style } = props;

  const {
    account,
    status,
    chainId,
    chainSettings,
    copyToClipboard,
    downloadPDFFile,
    downloadArrAsCSVFile,
    getterHash,
    getterDate,
    getterValue,
    fromWei,
    json2obj,
  } = useDigitalAssets();
  const { getWalletUID } = useDigitalAssetsWallet();
  const { getLatestEvents } = useDigitalAssetsAPI();

  const [UIDWallet, setUIDWallet] = useState('');
  const [certJPGURL, setCertJPGURL] = useState('');
  const [certLoading, setCertLoading] = useState(false);

  const [eventHistory, setEventHistory] = useState([]);
  const [tableCurrentPage, setTableCurrentPage] = useState(1);
  const [tableLoading, setTableLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const [transactionHash, setTransactionHash] = useState('');
  const [showTransactionDetail, setShowTransactionDetail] = useState(false);

  const downloadHandle = () => {
    setDownloadLoading(true);
    downloadPDFFile(
      `${REACT_APP_SSO_API}/wallet/personal_certificate/pdf?id=${UIDWallet}`,
      'userCertificate.pdf'
    ).finally(() => {
      setDownloadLoading(false);
    });
  };

  const shareHandle = () => {
    window.open(`/certificate/${UIDWallet}`);
  };

  const _findTransactionByHash = async (evnt) => {
    if (status === 'connected' && chainId === chainSettings?.chainId) {
      // eslint-disable-next-line no-restricted-globals
      const data = json2obj(evnt.target.parentElement.getAttribute('data')) || {};
      const field = evnt.target.parentElement.getAttribute('field');
      const hash = data[field];

      if (hash) {
        setTransactionHash(hash);
        setShowTransactionDetail(true);
      }
    }
  };

  const getterTransactionHash = (val) =>
    val ? (
      <span className={clsx(styles.link, 'link')} hash={val} onClick={_findTransactionByHash}>
        {getterHash(val)}
      </span>
    ) : (
      '-'
    );

  // const getterCarbonValue = (val) => (val ? <span>{val} CaFFee</span> : '-');

  const getEventHistory = (certId) => {
    setTableLoading(true);

    getLatestEvents(certId, account)
      .then((data) => {
        const parsedData = data.map(
          /**
           * @param {{hash: string, to: string, ts: string, cost: string, value: number|string}} el
           */
          (el) => {
            if (el) {
              // eslint-disable-next-line no-param-reassign
              el.cost = fromWei(el.cost || 0) || 0;
            }
            return el;
          }
        );

        setEventHistory(parsedData || []);
      })
      .catch()
      .finally(() => {
        setTableLoading(false);
      });
  };

  const getCSVEventsHistory = () => {
    if (eventHistory) {
      downloadArrAsCSVFile(eventHistory, 'eventsHistory.csv');
    }
  };

  const columns = [
    {
      key: 'hash',
      dataIndex: 'hash',
      title: 'Txn Hash',
      align: 'left',
      width: 110,
      render: (value) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <CopyIcon
            style={{ width: 20, height: 20, cursor: 'pointer' }}
            onClick={async () => {
              if (navigator.clipboard) {
                copyToClipboard(value);
              }
            }}
          />

          {getterTransactionHash(value)}
        </div>
      ),
    },
    {
      key: 'ts',
      dataIndex: 'ts',
      title: 'Date',
      align: 'left',
      width: 120,
      render: (value) => <span>{getterDate(value)}</span>,
    },
    {
      key: 'to',
      dataIndex: 'to',
      title: 'To',
      align: 'left',
      width: 120,
      render: (value) => <span>{getterHash(value)}</span>,
    },
    {
      key: 'value',
      dataIndex: 'value',
      title: `Value ${chainSettings.nativeCurrency.symbol}`,
      align: 'left',
      width: 140,
      render: (value) => <span>{getterValue(value)}</span>,
    },
    {
      key: 'cost',
      dataIndex: 'cost',
      title: 'FCEM',
      align: 'left',
      width: 100,
      render: (value, row) => <span style={{ wordBreak: 'break-all' }}>{value}</span>,
    },
  ];

  useEffect(() => {
    if (!UIDWallet) {
      setCertLoading(true);

      getWalletUID()
        .then((id) => {
          setUIDWallet(id);
          getAuthorizedImageByLink(`${REACT_APP_SSO_API}/wallet/personal_certificate/jpg?id=${id}`)
            .then((imageData) => {
              if (imageData) {
                setCertJPGURL(imageData);
              }
            })
            .catch(() => {
              setCertJPGURL('');
            })
            .finally(() => {
              setCertLoading(false);
            });
        })
        .catch(() => {
          setCertLoading(false);
        });
    }

    if (eventHistory?.length === 0 && UIDWallet) {
      getEventHistory();
    }
  }, [UIDWallet]);

  return (
    <div className={clsx(styles.modalBody, className)} style={{ ...style }}>
      <div className={clsx(styles.h1, 'h1')} style={{ margin: 0 }}>
        Certificate
      </div>

      <div className={clsx(styles.certificateCO2, 'certificateCO2')}>
        <div className={clsx(styles.certificateCO2_btn_row, 'certificateCO2_btn_row')}>
          {downloadLoading ? (
            <Loader />
          ) : (
            <DownloadIcon
              style={{
                width: 20,
                height: 20,
                cursor: 'pointer',
              }}
              onClick={() => downloadHandle()}
            />
          )}
          <ShareIcon
            style={{
              width: 20,
              height: 20,
              cursor: 'pointer',
            }}
            onClick={() => shareHandle()}
          />
        </div>

        {certLoading ? (
          <Loader style={{ position: 'relative', top: '6px', paddingBottom: 20 }} />
        ) : certJPGURL ? (
          <img src={certJPGURL} alt="" />
        ) : (
          <div className={styles.notFound}>No certificate found...</div>
        )}
      </div>

      <div style={{ marginTop: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
          <div className={clsx(styles.h1, 'h1')} style={{ marginBottom: 0 }}>
            My offsetting history
          </div>

          <DownloadIcon
            style={{
              width: 20,
              height: 20,
              marginLeft: '24px',
              cursor: 'pointer',
            }}
            onClick={() => getCSVEventsHistory()}
          />
        </div>

        <div>
          <Table
            loading={tableLoading}
            columns={columns}
            data={eventHistory}
            pagination={{
              position: ['bottomCenter'],
              current: tableCurrentPage,
              pageSize: 10,
              total: eventHistory?.length,
              responsive: true,
              showSizeChanger: false,
              hideOnSinglePage: true,
              onChange: (page) => setTableCurrentPage(parseInt(page, 10)),
            }}
          />
        </div>
      </div>

      <TransactionDetailModal
        showTransactionDetail={showTransactionDetail}
        setShowTransactionDetail={setShowTransactionDetail}
        transactionHash={transactionHash}
      />
    </div>
  );
};

export default CustomerCarbonCertificate;
