/* eslint-disable no-case-declarations */

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Table,
  Modal,
  Block,
  Input,
  Button,
  Tooltip,
  Select,
  DateRangePicker,
  Tabs,
} from 'tt-ui-lib/core';
import { SearchIcon, EditIcon, LockIcon, UnlockIcon, CopyIcon } from 'tt-ui-lib/icons';
import {
  useDigitalAssets,
  useDigitalAssetsTTAPI,
  useDigitalAssetsWallet,
} from 'modules/tt-digital-assets-provider';

// import { ContentCopy, Edit, Lock, LockOpen } from '@mui/icons-material';

import EditWalletStatusModal from './EditWalletStatusModal/EditWalletStatusModal';
import EditStatusModal from './EditStatusModal/EditStatusModal';

import styles from './FceUsersPage.module.scss';

const FceUsersPage = () => {
  const { chainSettings, switchChain, chainId } = useDigitalAssets();

  const statuses = {
    deleted: 'Deleted',
    blocked: 'Blocked',
    not_blocked: 'Not Blocked',
    recovered: 'Recover',
  };

  const filterOrderByList = [
    { name: 'By UID', value: '' },
    { name: 'By user name', value: 'user_name' },
    { name: 'By wallet', value: 'wallet' },
    { name: 'By wallet status', value: 'wallet_status' },
    { name: 'By status', value: 'status' },
    { name: 'By KYC', value: 'kyc' },
  ];

  const filterOrderByToIPList = [
    { name: 'By IP', value: 'ip' },
    { name: 'By date', value: 'date' },
  ];

  const filterWalletList = [
    { name: 'All wallets', value: 'all' },
    { name: 'Blocked wallet', value: 'blocked' },
    { name: 'Accepted wallet', value: 'accept' },
    { name: 'Deleted wallet', value: 'delete' },
    { name: 'Suspend wallet', value: 'suspend' },
  ];

  const filterStatusList = [
    { name: 'All statuses', value: 'all' },
    { name: 'Blocked', value: 'blocked' },
    { name: 'Not blocked', value: 'not_blocked' },
    { name: 'Deleted', value: 'deleted' },
  ];

  const filterKYCList = [
    { name: 'All KYC', value: 'all' },
    { name: 'Passed', value: 'success' },
    { name: 'Not passed', value: 'not_passed' },
  ];

  const { getBlockedIPList, unblockIP, getRoleList } = useDigitalAssetsTTAPI();
  const { getRegisterWalletStateIcon, getRegisterWalletStateText } = useDigitalAssetsWallet();
  const { copyToClipboard } = useDigitalAssets();

  const [allWallets, setAllWallets] = useState([]);

  const [roleList, setRoleList] = useState([{ id: 'all', name: 'All' }]);
  const [selectedRole, setSelectedRole] = useState('all');
  const [selectedRoleIndex, setSelectedRoleIndex] = useState(0);

  const onChangeRole = (newValue) => {
    if (roleList[newValue]?.id || roleList[newValue]?.id === 0) {
      setSelectedRoleIndex(newValue);
      setSelectedRole(roleList[newValue].id);
      return;
    }

    setSelectedRoleIndex(0);
    setSelectedRole('all');
  };

  const [tableLoading, setTableLoading] = useState(false);

  const [columns, setColumns] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [totalItems, setTotalItems] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 9;

  const [filter, setFilter] = useState({
    search: '',
    orderBy: 'wallet',
    walletStatus: 'all',
    status: 'all',
    kyc: 'all',
    dateStart: null,
    dateEnd: null,
  });

  const [editWalletStatusModal, setEditWalletStatusModal] = useState(false);
  const [editStatusModal, setEditStatusModal] = useState(false);
  const [userStatus, setUserStatus] = useState('');
  const [userId, setUserId] = useState('');
  const [userFullName, setUserFullName] = useState('');
  const [currentWallet, setCurrentWallet] = useState('');
  const [currentWalletState, setCurrentWalletState] = useState(6);

  //------------------------
  const getAllRolesWallets = async () => {
    try {
      setTableLoading(true);
      /** @type {{roles: [{id: Number, parentID: ?Number, name: String}], wallets: [{uid: String, roles: [Number], status: Number, tt: {first_name: String, last_name: String, email: String, roles: [{id: Number, bane: String}], status: String, kyc: String} }] }} */
      const res = await getRoleList(true);

      const roles = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const roleID of Object.keys(res?.roles)) {
        roles.push({
          id: parseInt(roleID, 10),
          name: res.roles[roleID].name,
          parentID: res.roles[roleID].parentID,
        });
      }
      setRoleList([{ id: 'all', name: 'All' }, ...roles, { id: 'blocked_ip', name: 'Blocked IP' }]);

      const wallets = [];

      // eslint-disable-next-line no-unsafe-optional-chaining,no-restricted-syntax
      for (const wallet of Object.keys(res?.wallets)) {
        const tmp = res.wallets[wallet];
        wallets.push({
          uid: tmp?.uid ?? '',
          first_name: tmp?.tt?.first_name ?? '',
          last_name: tmp?.tt?.last_name ?? '',
          email: tmp?.tt?.email ?? '',
          roles_tt: tmp?.tt?.roles ?? [],
          wallet: wallet,
          status: tmp?.tt?.status ?? '',
          kyc: tmp?.tt?.kyc ?? '',
          roles_da: tmp?.roles ?? [],
          wallet_status: tmp?.status ?? 6,
        });
      }
      setAllWallets(wallets);

      return wallets;
    } finally {
      setTableLoading(false);
    }
  };

  const getBlockedIPs = async () => {
    setTableLoading(true);
    setDataRows([]);
    setTotalItems(0);

    const res = ((await getBlockedIPList(true)) ?? []).map((el) => {
      const newEl = el;
      newEl.d = el.date;

      const d = new Date(newEl.date);
      newEl.date = d.toISOString().substring(0, 10);
      newEl.time = d.toISOString().substring(11, 19);

      return newEl;
    });

    setTableLoading(false);
    return res;
  };

  const getWalletsList = () => {
    const fltrRole = parseInt(selectedRole, 10);
    return (allWallets?.length > 0 ? allWallets : []).filter(
      (el) => selectedRole === 'all' || el?.roles_da?.includes(fltrRole)
    );
  };

  const getFullName = (row) => `${row?.first_name ?? ''} ${row?.last_name ?? ''}`;

  const renderName = (value) => (
    <div className={clsx(styles.nameColumn, 'nameColumn')}>{value ?? ''}</div>
  );

  const renderRoleTT = (value) => {
    const val = `${value?.map((el) => el?.name)?.join(', ') ?? ''}`;

    return (
      <div className={clsx(styles.nameColumn, 'nameColumn')}>
        {val ? (
          <Tooltip title={val} placement="top" arrow>
            <div className={clsx(styles.rolesColumn)}>{val}</div>
          </Tooltip>
        ) : (
          ''
        )}
      </div>
    );
  };

  const renderRolesDA = (value) => {
    const val = `${roleList.filter((el) => value?.includes(el.id)).map((el) => el.name) ?? ''}`;

    return (
      <div className={clsx(styles.nameColumn, 'nameColumn')}>
        {val ? (
          <Tooltip title={val} placement="top" arrow>
            <div className={clsx(styles.idColumn, 'idColumn')}>{val}</div>
          </Tooltip>
        ) : (
          ''
        )}
      </div>
    );
  };

  const renderStatus = (value, row) => (
    <div className={clsx(styles.nameColumn, 'nameColumn')}>
      {statuses[value] ?? ''}
      {value ? (
        <Button
          type="icon"
          onClick={((uid, fullName, status) => (evnt) => {
            // eslint-disable-next-line no-use-before-define
            onChangeStatus(evnt, uid, fullName, status).then();
          })(row?.uid, getFullName(row), value)}
        >
          <EditIcon
            style={{
              height: 20,
              width: 20,
            }}
          />
        </Button>
      ) : (
        ''
      )}
    </div>
  );

  const renderWallet = (value, row) => (
    <div className={clsx(styles.walletColumn)}>
      <div className={styles.walletRow}>
        <Tooltip title={getRegisterWalletStateText(row?.wallet_status)} placement="top" arrow>
          <div style={{ width: '17.5px', height: '17.5px', marginRight: '15px' }}>
            {getRegisterWalletStateIcon(row?.wallet_status)}
          </div>
        </Tooltip>

        <div style={{ width: '120px' }} className={clsx(styles.idColumn, 'idColumn')}>
          {value || '-'}
        </div>
      </div>

      <div className={styles.walletRow}>
        <Button type="icon" onClick={() => copyToClipboard(value)}>
          <CopyIcon
            style={{
              height: 20,
              width: 20,
            }}
          />
        </Button>

        {row?.wallet_status === 2 ? (
          <Button
            type="icon"
            onClick={(
              (wallet, state) => (evnt) =>
                // eslint-disable-next-line no-use-before-define
                onChangeWalletState(evnt, wallet, state)
            )(value, row?.wallet_status)}
          >
            <UnlockIcon
              style={{
                height: 20,
                width: 20,
              }}
            />
          </Button>
        ) : [1, 3, 4].includes(row?.wallet_status) ? (
          <Button
            type="icon"
            onClick={(
              (wallet, state) => (evnt) =>
                // eslint-disable-next-line no-use-before-define
                onChangeWalletState(evnt, wallet, state)
            )(value, row?.wallet_status)}
          >
            <LockIcon
              style={{
                height: 20,
                width: 20,
              }}
            />
          </Button>
        ) : (
          ''
        )}
      </div>
    </div>
  );

  const renderValWithCopy = (value) => (
    <div className={clsx(styles.nameColumn, 'nameColumn')}>
      {value ? (
        <>
          <Tooltip title={value} placement="top" arrow>
            <div className={clsx(styles.idColumn, 'idColumn')}>{value}</div>
          </Tooltip>
          <Button type="icon" onClick={() => copyToClipboard(value)}>
            <CopyIcon
              style={{
                height: 20,
                width: 20,
              }}
            />
          </Button>
        </>
      ) : (
        ''
      )}
    </div>
  );

  const doUnblockIP = async (ip) => {
    if (!ip) return;

    await unblockIP(ip);
    // eslint-disable-next-line no-use-before-define
    setDataForTable().then();
  };

  const walletColumns = [
    {
      key: 'uid',
      dataIndex: 'uid',
      title: 'ID',
      width: 120,
      render: renderValWithCopy,
    },
    {
      key: 'first_name',
      dataIndex: 'first_name',
      title: 'Username',
      width: 120,
      render: renderName,
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Contact',
      width: 120,
      render: renderValWithCopy,
    },
    {
      key: 'roles_tt',
      dataIndex: 'roles_tt',
      title: 'Roles TT',
      width: 100,
      render: renderRoleTT,
    },
    { key: 'wallet', dataIndex: 'wallet', title: 'Wallet', width: 220, render: renderWallet },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
      width: 150,
      render: renderStatus,
    },
    { key: 'kyc', dataIndex: 'kyc', title: 'KYC', width: 100 },
    {
      key: 'roles_da',
      dataIndex: 'roles_da',
      title: 'Roles DA',
      width: 150,
      render: renderRolesDA,
    },
  ];

  const blockedIpColumns = [
    {
      key: 'ip',
      dataIndex: 'ip',
      title: 'IP',
      width: 150,
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: 'Date',
      width: 150,
    },
    {
      key: 'time',
      dataIndex: 'time',
      title: 'Time',
      width: 150,
    },
    {
      key: 'actions',
      title: ' ',
      width: 150,
      filterable: false,
      sortable: false,
      render: (_, row) => (
        <div className={styles.unblockBtn} onClick={() => doUnblockIP(row?.ip)}>
          Unblock
        </div>
      ),
    },
  ];

  const setDataForTable = async () => {
    const addr2str = (str) => (str?.substring(0, 2) === '0x' ? str.substring(2) : str);

    try {
      setColumns(selectedRole === 'blocked_ip' ? blockedIpColumns : walletColumns);

      let data;
      if (selectedRole === 'blocked_ip') {
        console.dir(filter);

        data = await getBlockedIPs();
        data = data
          .filter((el) => {
            console.dir(el);
            return (
              (!filter?.search || el?.ip.indexOf(filter?.search) > -1) &&
              (!filter?.dateStart?.getTime() || el.d >= filter?.dateStart?.getTime()) &&
              (!filter?.dateEnd?.getTime() || el.d <= filter?.dateEnd?.getTime())
            );
          })
          .sort((a, b) => {
            switch (filter?.orderBy) {
              case 'ip':
                const ipA = parseInt(
                  (a.ip ?? '0')
                    .split('.')
                    .map((el) => parseInt(el, 10).toString(16).padStart(2, '0'))
                    .join(''),
                  16
                );
                const ipB = parseInt(
                  (b.ip ?? '0')
                    .split('.')
                    .map((el) => parseInt(el, 10).toString(16).padStart(2, '0'))
                    .join(''),
                  16
                );
                return ipA - ipB;

              case 'date':
                const dateA = parseInt(a?.d, 10);
                const dateB = parseInt(b?.d, 10);
                return dateA - dateB;

              default:
                return 0;
            }
          });
      } else {
        data = getWalletsList();

        let walletStates = [];
        switch (filter?.walletStatus) {
          case 'blocked':
            walletStates = [3, 4];
            break;
          case 'accept':
            walletStates = [2];
            break;
          case 'delete':
            walletStates = [5];
            break;
          case 'suspend':
            walletStates = [1];
            break;
          default:
            walletStates = [1, 2, 3, 4, 5, 6];
            break;
        }

        // filtering data
        data = data
          .filter((el) => {
            const user = getFullName({ row: el });

            return (
              (filter?.search === '' ||
                user?.toLowerCase().indexOf(filter?.search.toLowerCase()) > -1 ||
                el?.email?.toLowerCase().indexOf(filter?.search.toLowerCase()) > -1 ||
                el?.wallet?.toLowerCase().indexOf(addr2str(filter?.search.toLowerCase())) > -1 ||
                el?.uid?.toLowerCase().indexOf(filter?.search.toLowerCase()) > -1) &&
              (filter?.status === 'all' || el.status === filter?.status) &&
              walletStates.includes(parseInt(el.wallet_status, 10)) &&
              (filter?.kyc === 'all' || el.kyc === filter?.kyc)
            );
          })
          .sort((a, b) => {
            switch (filter?.orderBy) {
              case 'uid':
                return a?.uid > b?.uid ? -1 : 1;

              case 'wallet':
                return a?.wallet > b?.wallet ? -1 : 1;

              case 'user_name':
                return getFullName(a) > getFullName(b) ? -1 : 1;

              case 'wallet_status':
                return a?.walletStatus > b?.walletStatus ? 1 : -1;

              case 'status':
                return a?.status > b?.status ? -1 : 1;

              case 'kyc':
                return a?.kyc > b?.kyc ? -1 : 1;

              default:
                return 0;
            }
          });
      }

      const pgCnt = Math.ceil((data ?? []).length / pageSize);
      setTotalItems(Math.ceil((data ?? []).length));

      let pgN = currentPage;

      if (pgN > pgCnt) {
        setCurrentPage(pgCnt);
        pgN = pgCnt;
      }
      if (pgN < 1) {
        setCurrentPage(1);
        pgN = 1;
      }

      const res = [];
      for (let i = pageSize * (pgN - 1); i < pageSize * pgN && i < data.length; i++) {
        res.push(data[i]);
      }

      setDataRows(res);
    } finally {
      //
    }
  };

  const onChangeWalletState = async (evnt, wallet, state) => {
    setCurrentWallet(wallet);
    setCurrentWalletState(state);
    setEditWalletStatusModal(true);
  };

  const onChangeStatus = async (evnt, uid, fullName, status) => {
    setUserId(uid);
    setUserFullName(fullName);
    setUserStatus(status);
    setEditStatusModal(true);
  };

  const changedChain = async () => switchChain(chainSettings);

  useEffect(() => {
    if (selectedRole === 'blocked_ip') {
      setFilter({ ...filter, orderBy: 'ip' });
    } else {
      setFilter({ ...filter, orderBy: 'wallet' });
    }
    setCurrentPage(1);
  }, [selectedRole]);

  useEffect(() => {
    setSelectedRole('all');
    setDataRows([]);
    setColumns(walletColumns);
    getAllRolesWallets().then();
  }, []);

  useEffect(() => {
    setDataForTable().then();
  }, [filter, currentPage, selectedRole, allWallets]);

  if (chainId !== chainSettings.chainId) {
    return (
      <>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyItems: 'center',
              gap: 20,
              padding: 20,
            }}
          >
            <div>To proceed need change Blockchain NET</div>
            <Button type="primary" onClick={changedChain}>
              Changed Chain
            </Button>
          </div>
        </div>
      </>
    );
  }

  return (
    <Block
      className="adminPageContentBox"
      style={{ background: 'none', boxShadow: 'none', padding: 0 }}
    >
      <div style={{ width: '100%', background: 'none' }} className={styles.page}>
        <Tabs tabs={roleList} selectedTab={selectedRoleIndex} onChangeTab={onChangeRole} />

        <div className={clsx(styles.block, styles.tableContent)} style={{ marginTop: '20px' }}>
          <div className={clsx(styles.filters)}>
            <Input
              className={clsx(styles.searchFilter, 'searchFilter')}
              value={filter?.search}
              label="Search"
              onChange={(e) => setFilter({ ...filter, search: e?.target?.value })}
              prefix={<SearchIcon style={{ width: 20, height: 20, flexShrink: 0 }} />}
            />

            <Select
              className={clsx(styles.filterSelect)}
              value={filter?.orderBy}
              label="Sort By"
              onChange={(value) => setFilter({ ...filter, orderBy: value })}
              options={(selectedRole === 'blocked_ip'
                ? filterOrderByToIPList
                : filterOrderByList
              ).map((el) => ({ value: el.value, label: el.name }))}
            />

            {selectedRole !== 'blocked_ip' ? (
              <>
                <Select
                  className={clsx(styles.filterSelect)}
                  value={filter?.walletStatus}
                  label="Wallet status"
                  onChange={(value) => setFilter({ ...filter, walletStatus: value })}
                  options={filterWalletList.map((el) => ({ value: el.value, label: el.name }))}
                />

                <Select
                  className={clsx(styles.filterSelect)}
                  value={filter?.status}
                  label="Status"
                  onChange={(value) => setFilter({ ...filter, status: value })}
                  options={filterStatusList.map((el) => ({ value: el.value, label: el.name }))}
                />

                <Select
                  className={clsx(styles.filterSelect)}
                  value={filter?.kyc}
                  label="KYC"
                  onChange={(value) => setFilter({ ...filter, kyc: value })}
                  options={filterKYCList.map((el) => ({ value: el.value, label: el.name }))}
                />
              </>
            ) : null}

            <DateRangePicker
              style={{ width: '300px' }}
              label={['Start date', 'End date']}
              value={[filter?.dateStart || null, filter?.dateEnd || null]}
              format="DD.MM.YYYY"
              onChange={(vals) => {
                setFilter({
                  ...filter,
                  dateStart: vals ? vals[0] || null : null,
                  dateEnd: vals ? vals[1] || null : vals,
                });
              }}
            />
          </div>

          <Table
            columns={columns}
            data={dataRows || []}
            loading={tableLoading}
            scroll={{ x: 870, y: '42vh' }}
            pagination={{
              position: ['bottomCenter'],
              current: currentPage,
              pageSize: pageSize,
              total: totalItems,
              responsive: true,
              showSizeChanger: false,
              hideOnSinglePage: true,
              onChange: (page) => setCurrentPage(parseInt(page, 10)),
            }}
          />
        </div>

        <Modal
          open={editWalletStatusModal}
          onClose={() => setEditWalletStatusModal(false)}
          title={`You definitely want to ${
            currentWalletState === 2 ? 'block' : 'unblock'
          } the users wallet`}
        >
          <EditWalletStatusModal
            handleClose={setEditWalletStatusModal}
            wallet={currentWallet}
            walletState={currentWalletState}
            walletsUpdate={getAllRolesWallets}
          />
        </Modal>

        <Modal
          open={editStatusModal}
          onClose={() => setEditStatusModal(false)}
          title="Do you want to change a user status?"
        >
          <EditStatusModal
            open={editStatusModal}
            handleClose={setEditStatusModal}
            userFullName={userFullName}
            userId={userId}
            userStatus={userStatus}
            updateUserList={getAllRolesWallets}
          />
        </Modal>
      </div>
    </Block>
  );
};

export default FceUsersPage;
