import AppState from './App/AppState';
import AppContext from './App/appContext';
import messengerContext from './Messenger/messengerContext';
import userContext from './User/userContext';

export const allContexts = {
  app: AppContext,
  user: userContext,
  messenger: messengerContext,
};

export { AppState, AppContext };
