import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, Loader, Modal } from 'tt-ui-lib/core';
import { AddIcon, ArrowReloadIcon, CopyIcon, PowerIcon } from 'tt-ui-lib/icons';
import { KycModal } from '../../../modules/tt-modals';

import SimpleSend from './SimpleSend';
import CustomerCarbonCertificate from '../CarbonCertificate/private/Customer';

import {
  useDigitalAssets,
  useDigitalAssetsBridge,
  useDigitalAssetsWallet,
} from 'modules/tt-digital-assets-provider';

import styles from './styles.module.scss';
import { useWindowSize } from '../../../utils/useWindowSize';
import { useSelectedContext } from '../../../context/contextSelector';

const Wallet = (props) => {
  const { showedTab, setForceReload } = props;

  const { user, isIndividual, isHolder, isInvestor } = useSelectedContext((state) => state.user);
  const { width } = useWindowSize();

  /** Свойства от MetaMask'а */
  const {
    chainSettings,
    status,
    connect,
    account,
    chainId,
    switchChain,

    copyToClipboard,
  } = useDigitalAssets();

  const {
    registerWalletState,
    registerWalletStateIcon,
    getRegisterWalletStateText,
    doRegisterWalletInChain,
    claimAmount,
    doClaim,
    balance,
    getBalance,
  } = useDigitalAssetsWallet();

  const { bridge, wrapBalance, getWrapBalance } = useDigitalAssetsBridge();

  //* *********************************************************

  /** KYC данные пользователя и прохождение идентификации */
  const [showNeedKYCModal, setShowNeedKYCModal] = useState(false);
  const [showDisconnectPrompt, setShowDisconnectPrompt] = useState(false);
  const [showSimplePaymentDialog, setShowSimplePaymentDialog] = useState(false);
  const [showCustomerCertificate, setShowCustomerCertificate] = useState(false);

  //* ***********************************************************************

  /**
   * Флаг активности страницы (все условия для работы соблюдены)
   * @return {Boolean}
   */
  const bActivePages = () =>
    !!(
      status === 'connected' &&
      chainId === chainSettings?.chainId &&
      account &&
      user?.kyc === 'success'
    );

  /**
   * Подключение кошелька пользователя
   * @return {Promise<void>}
   */
  const walletConnect = async () => {
    if (user?.kyc === 'success') {
      await connect();
    } else {
      // KYC отсутствует - показываем форму прохождения KYC
      setShowNeedKYCModal(true);
    }
  };

  // eslint-disable-next-line no-underscore-dangle
  const _doClaim = () =>
    doClaim().then(() => {
      // eslint-disable-next-line no-unused-expressions
      setForceReload && setForceReload(true);
    });

  if (isIndividual && !(isHolder || isInvestor)) {
    return (
      <div className={`${styles.Wallet} ${styles.oneBlock}`}>
        <div>
          <div className={clsx(styles.h1, 'h1')}>Digital assets</div>
          <p>
            To access to the Digital Assets section, you should have another role on the
            TransparenTerra Platform (expert, investor, business, holder).
          </p>
        </div>
      </div>
    );
  }

  useEffect(() => {
    (async () => {
      if (chainId !== chainSettings?.chainId && bridge) {
        getWrapBalance();
      }
      getBalance();
    })();
  }, [chainId, bridge]);

  return (
    <>
      {status === 'unavailable' ? ( // MetaMask не доступен - предлагаем его установить
        <div className={`${styles.Wallet} ${styles.oneBlock} ${styles.walletWrapper}`}>
          <div>
            <div>
              <div className={clsx(styles.h1, 'h1')}>Digital assets</div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <p className={clsx(styles.p, 'p')}>
                To proceed, please install the MetaMask plugin in your browser and connect your
                wallet to TransparenTerra.
              </p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <Button type="default" onClick={() => window.open('https://metamask.io')}>
                Install MetaMask Wallet
              </Button>
            </div>
          </div>
        </div>
      ) : status === 'initializing' ? ( // Устанавливаем соединение с MetaMask
        <div className={`${styles.Wallet} ${styles.oneBlock} ${styles.walletWrapper}`}>
          <div>
            <div className={clsx(styles.h1, 'h1')}>Digital assets</div>
            <p className={clsx(styles.p, 'p')}>Synchronisation with MetaMask ongoing...</p>
          </div>
        </div>
      ) : status === 'connecting' ? ( // Соединение с MetaMask'ом
        <div className={`${styles.Wallet} ${styles.oneBlock} ${styles.walletWrapper}`}>
          <div>
            <div className={clsx(styles.h1, 'h1')}>Digital assets</div>
            <p className={clsx(styles.p, 'p')}>Connecting to MetaMask...</p>
          </div>
        </div>
      ) : status === 'connected' ? ( // MetaMask подключен
        user?.kyc !== 'success' ? ( // У пользователя ещё нет пройденного KYC
          <div className={`${styles.Wallet} ${styles.oneBlock} ${styles.walletWrapper}`}>
            <div>
              <div className={clsx(styles.h1, 'h1')}>Digital assets</div>
              <p className={clsx(styles.p, 'p')}>You are entering the world of digital assets.</p>
              <p className={clsx(styles.p, 'p')}>
                To move forward, please connect or create your MetaMask wallet
              </p>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type="primary"
                  startIcon={
                    <AddIcon
                      className="icon-white-fill"
                      style={{ width: 22, height: 22, display: 'flex' }}
                    />
                  }
                  onClick={walletConnect}
                >
                  Connect wallet
                </Button>
              </div>
            </div>

            {/* KYC Dialog */}
            <KycModal isOpen={showNeedKYCModal} setIsOpen={setShowNeedKYCModal} userId={user?.id} />
          </div>
        ) : !account ? ( // У пользователя ещё нет подключенного кошелька
          <div className={`${styles.Wallet} ${styles.oneBlock} ${styles.walletWrapper}`}>
            <div>
              <div className={clsx(styles.h1, 'h1')}>Digital assets</div>
              <p className={clsx(styles.p, 'p')}>You are entering the world of digital assets.</p>
              <p className={clsx(styles.p, 'p')}>
                To move forward, please connect or create your MetaMask wallet
              </p>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type="primary"
                  startIcon={
                    <AddIcon
                      className="icon-white-fill"
                      style={{ width: 22, height: 22, display: 'flex' }}
                    />
                  }
                  onClick={walletConnect}
                >
                  Connect wallet
                </Button>
              </div>
            </div>

            {/* KYC Dialog */}
            <KycModal isOpen={showNeedKYCModal} setIsOpen={setShowNeedKYCModal} userId={user?.id} />
          </div>
        ) : chainId !== chainSettings.chainId && // Выбрана не правильная сеть
          !showedTab.startsWith('bridge') ? (
          <div className={`${styles.Wallet} ${styles.oneBlock} ${styles.walletWrapper}`}>
            <div>
              <div className={clsx(styles.h1, 'h1')}>Digital assets</div>
              <p className={clsx(styles.p, 'p')}>
                To interact with the FCE blockchain, change the network in your MetaMask
                accordantly.
              </p>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                <Button
                  type="primary"
                  icon={
                    <ArrowReloadIcon
                      className="icon-white-fill"
                      style={{ width: 20, height: 20 }}
                    />
                  }
                  onClick={switchChain}
                >
                  Change network
                </Button>
              </div>
            </div>
          </div>
        ) : bridge ? ( // Основной рабочий блок
          <div className={styles.walletWrapper}>
            <div
              className={clsx(
                styles.Wallet
                // registerWalletState === 2 || chainId !== chainSettings.chainId ? styles.oneRow : '',
              )}
            >
              {/* Блок с номером кошелька */}
              <div className={clsx(styles.accountBox, 'accountBox')}>
                {chainSettings?.chainId === chainId ? (
                  <span style={{ position: 'relative', top: '2px', width: 18, height: 18 }}>
                    {registerWalletStateIcon}
                  </span>
                ) : (
                  ''
                )}
                <span
                  className={clsx(styles.p, 'p', styles.bold, 'bold')}
                  style={{ marginLeft: '5px' }}
                >
                  {bridge?.name || 'FCEnet'}:
                </span>
                <span className={clsx(styles.p, 'p')} style={{ marginLeft: '5px' }}>
                  {`${account.substring(0, 6)}*****${account.substring(account.length - 4)}`}
                </span>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 13,
                    marginLeft: 10,
                    width: 70,
                  }}
                >
                  <CopyIcon
                    style={{
                      position: 'relative',
                      top: '2px',
                      width: 20,
                      height: 20,
                      cursor: 'pointer',
                    }}
                    onClick={async () => copyToClipboard(account)}
                  />

                  <PowerIcon
                    style={{
                      position: 'relative',
                      top: '2px',
                      width: 20,
                      height: 20,
                      cursor: 'pointer',
                    }}
                    onClick={async () => setShowDisconnectPrompt(true)}
                  />
                </div>
              </div>
              <div className={styles.secondRow}>
                {/* Блок с балансом кошелька */}
                <div
                  className={clsx(styles.balanceBox, 'balanceBox')}
                  style={{
                    display:
                      registerWalletState === 2 && chainId === chainSettings.chainId ? '' : 'none',
                  }}
                >
                  <div className={clsx(styles.p, 'p')}>
                    Balance:&nbsp;
                    <span className={clsx(styles.p, 'po', styles.balance)}>
                      {balance === '' || Number.isNaN(balance) || balance === 'NaN' ? (
                        <Loader style={{ position: 'relative', top: '6px' }} />
                      ) : (
                        <span title={balance} style={{ position: 'relative', top: '2px' }}>
                          {parseFloat(parseFloat(balance).toFixed(5))}
                        </span>
                      )}
                    </span>
                    &nbsp;
                    {bridge?.symbol}
                  </div>
                </div>
                {/* Блок с функциональными кнопками или балансом обёрнутых токенов на кошельке */}
                {/**/}
                <div
                  className={clsx(styles.buttonsBox, 'buttonsBox')}
                  // style={{ display: chainId === chainSettings?.chainId ? 'flex' : 'none' }}
                >
                  {chainId === chainSettings?.chainId ? (
                    // Родная сеть
                    <>
                      <div>
                        <Button
                          type="primary"
                          onClick={() => doRegisterWalletInChain()}
                          style={{ display: registerWalletState === 6 ? '' : 'none' }}
                        >
                          Accept
                        </Button>

                        <Button
                          type="default"
                          onClick={_doClaim}
                          disabled={claimAmount === 0}
                          style={{
                            display: registerWalletState === 2 && claimAmount > 0 ? '' : 'none',
                          }}
                        >
                          Claim
                        </Button>

                        <Button
                          type="default"
                          onClick={() => setShowSimplePaymentDialog(true)}
                          disabled={claimAmount > 0 || registerWalletState !== 2}
                          style={{
                            display: registerWalletState === 2 && !(claimAmount > 0) ? '' : 'none',
                          }}
                        >
                          Send
                        </Button>
                      </div>
                      <div>
                        <Button
                          type="default"
                          onClick={() => setShowCustomerCertificate(true)}
                          disabled={claimAmount > 0 || registerWalletState !== 2}
                          style={{
                            display: registerWalletState === 2 && !(claimAmount > 0) ? '' : 'none',
                          }}
                        >
                          {width > 1580 ? 'CaFFee balance' : 'Balance'}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <div className={styles.balanceWrapper}>
                      <div className={clsx(styles.p, 'p')}>
                        Balance:&nbsp;
                        <span className={clsx(styles.p, 'p', styles.balance)}>
                          {wrapBalance === '' ||
                          Number.isNaN(wrapBalance) ||
                          wrapBalance === 'NaN' ? (
                            <Loader style={{ position: 'relative', top: '6px' }} />
                          ) : (
                            <span title={wrapBalance} style={{ position: 'relative', top: '2px' }}>
                              {parseFloat(parseFloat(wrapBalance).toFixed(5))}
                            </span>
                          )}
                        </span>
                        &nbsp;FCEM{bridge?.symbol}
                      </div>

                      <div className={clsx(styles.p, 'p')}>
                        Balance:&nbsp;
                        <span className={clsx(styles.p, 'po', styles.balance)}>
                          {balance === '' || Number.isNaN(balance) || balance === 'NaN' ? (
                            <Loader style={{ position: 'relative', top: '6px' }} />
                          ) : (
                            <span title={balance} style={{ position: 'relative', top: '2px' }}>
                              {parseFloat(parseFloat(balance).toFixed(5))}
                            </span>
                          )}
                        </span>
                        &nbsp;
                        {bridge?.symbol}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Блок с описанием различных статусов кошелька */}
            <div
              className={clsx(styles.p, 'p', styles.WalletStatusBox)}
              style={{
                display:
                  registerWalletState === 2 && chainId === chainSettings.chainId ? ' none' : '',
              }}
            >
              {registerWalletState === 0 && chainId === chainSettings.chainId ? ( // pending
                <p className={clsx(styles.p, 'p')} style={{ marginTop: 10 }}>
                  Your wallet registration is in progress. Please wait. Once the registration is
                  complete, you&apos;ll get a notification.
                </p>
              ) : registerWalletState === 1 && chainId === chainSettings.chainId ? ( // suspended
                <p className={clsx(styles.p, 'p')} style={{ marginTop: 10 }}>
                  You&apos;ve frozen your wallet. Change your status to proceed.
                  <Button onClick={() => {}}>Activate</Button>
                </p>
              ) : registerWalletState === 2 && chainId === chainSettings.chainId ? ( // accept
                ''
              ) : registerWalletState === 3 && chainId === chainSettings.chainId ? ( // rejected
                <p className={clsx(styles.p, 'p')} style={{ marginTop: 10 }}>
                  Your wallet has been banned from the FCE Blockchain network. Contact the support
                  team for details and to change your wallet&apos;s current status.
                </p>
              ) : registerWalletState === 4 && chainId === chainSettings.chainId ? ( // blocked
                <p className={clsx(styles.p, 'p')} style={{ marginTop: 10 }}>
                  Your wallet has been banned from the FCE Blockchain network. Contact the support
                  team for details and to change your wallet&apos;s current status.
                </p>
              ) : registerWalletState === 5 && chainId === chainSettings.chainId ? ( // deleted
                <p className={clsx(styles.p, 'p')} style={{ marginTop: 10 }}>
                  Your wallet has been banned from the FCE Blockchain network. Contact the support
                  team for details and to change your wallet&apos;s current status.
                </p>
              ) : registerWalletState === 6 && chainId === chainSettings.chainId ? ( // not registered
                <p className={clsx(styles.p, 'p')} style={{ marginTop: 10 }}>
                  To access FCE Network, you must sign a &quot;registration&quot; smart contract in
                  your MetaMask Wallet. It might take time.
                </p>
              ) : chainId === chainSettings.chainId ? (
                <p className={clsx(styles.p, 'p')} style={{ marginTop: 10 }}>
                  <Loader style={{ position: 'relative', top: '6px' }} /> Please wait. Checking
                  wallet status...
                </p>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          // Выбрана неправильная сеть или не настроен соответствующий блок в мосту
          <div className={`${styles.Wallet} ${styles.oneBlock} ${styles.walletWrapper}`}>
            <div>
              <div className={clsx(styles.h1, 'h1')}>Digital assets</div>
              <p className={clsx(styles.p, 'p')}>
                To interact with the FCE blockchain, change the network in your MetaMask
                accordantly.
              </p>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                <Button
                  type="primary"
                  icon={
                    <ArrowReloadIcon
                      className="icon-white-fill"
                      style={{ width: 20, height: 20 }}
                    />
                  }
                  onClick={switchChain}
                >
                  Change network
                </Button>
              </div>
            </div>
          </div>
        )
      ) : (
        // Неизвестное состояние
        <div className={`${styles.Wallet} ${styles.oneBlock} ${styles.walletWrapper}`}>
          <div>
            <div className={clsx(styles.h1, 'h1')}>Digital assets</div>
            <p className={clsx(styles.p, 'p')}>You are entering the world of digital assets.</p>
            <p className={clsx(styles.p, 'p')}>
              To move forward, please connect or create your MetaMask wallet
            </p>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="primary"
                icon={
                  <AddIcon
                    className="icon-white-fill"
                    style={{ width: 22, height: 22, display: 'flex' }}
                  />
                }
                onClick={walletConnect}
              >
                Connect wallet
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Simple payment */}
      <Modal //  Simple payment
        open={showSimplePaymentDialog && bActivePages()}
        onClose={() => setShowSimplePaymentDialog(false)}
        fullWidth
        title="Send FCEM"
        destroyOnClose
        closeOnlyByControls
      >
        <SimpleSend
          setShowSimplePaymentDialog={setShowSimplePaymentDialog}
          setForceReload={setForceReload}
        />
      </Modal>

      {/* Customer CaFFee certificate */}
      <Modal
        open={showCustomerCertificate && bActivePages()}
        onClose={() => setShowCustomerCertificate(false)}
        fullWidth
      >
        <CustomerCarbonCertificate />
      </Modal>

      {/* Disconnect wallet helper dialog */}
      <Modal
        open={showDisconnectPrompt && bActivePages()}
        onClose={() => setShowDisconnectPrompt(false)}
        fullWidth
        title="How to disconnect the Wallet?"
      >
        <div className={clsx(styles.p, styles.modalText, 'p')}>
          To deny TransparenTerra access to your wallet, open MetaMask and click the three dots at
          the top of the interface. Then select Connected Sites in the menu that opens and specify
          the resources for which you want to block the wallet.
        </div>
      </Modal>

      {/* KYC Dialog */}
      <KycModal isOpen={showNeedKYCModal} setIsOpen={setShowNeedKYCModal} userId={user?.id} />
    </>
  );
};

export default Wallet;
