import React from 'react';
import { Button, Modal } from 'tt-ui-lib/core';
import { HelpIcon } from 'tt-ui-lib/icons';
import clsx from 'clsx';
import styles from '../styles.module.scss';
import { useDigitalAssets, useDigitalAssetsCaFFee } from 'modules/tt-digital-assets-provider';

const TransferModal = ({
  showSendConfirm,
  setSendAmount,
  setSendAmountError,
  setSentBntActive,
  setShowSendConfirm,
  bridgeTo,
  bridges,
  sendAmount,
  co2TokenCost,
  sendTotalAmountStr,
  swap,
}) => {
  const { showAboutCaFFee } = useDigitalAssetsCaFFee();
  const { chainId, chainSettings, fromWei } = useDigitalAssets();

  return (
    <Modal //  Transfer to {bridge}
      open={showSendConfirm}
      onClose={() => {
        setSendAmount('');
        setSendAmountError('is not valid amount');
        setSentBntActive(false);
        setShowSendConfirm(false);
      }}
      fullWidth
      maxWidth="md"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      title={`Transfer to ${bridgeTo?.name}`}
    >
      <div>
        <div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
          <div className={clsx(styles.confirmDetailRow, 'confirmDetailRow')}>
            <div>From:</div>
            <div>{bridges.find((el) => el?.chain_id === parseInt(chainId, 16))?.name}</div>
          </div>
          <div className={clsx(styles.confirmDetailRow, 'confirmDetailRow')}>
            <div>To:</div>
            <div className={clsx(styles.recipientAddress, 'recipientAddress')}>
              {bridgeTo?.name}
            </div>
          </div>
          <div className={clsx(styles.confirmDetailRow, 'confirmDetailRow')}>
            <div>Amount:</div>
            <div>
              {sendAmount}&nbsp;{chainSettings?.nativeCurrency?.symbol}
            </div>
          </div>
          <div className={clsx(styles.confirmDetailRow, 'confirmDetailRow')}>
            <div className={styles.centerRow}>
              Commission CaFFee:
              <div
                style={{
                  width: 22,
                  cursor: 'pointer',
                }}
                onClick={() => showAboutCaFFee()}
              >
                <HelpIcon style={{ width: 20, height: 20, flexShrink: 0 }} />
              </div>
            </div>
            <div>
              {fromWei(co2TokenCost || 0)}&nbsp;
              {chainSettings?.nativeCurrency?.symbol}
            </div>
          </div>
          <div className={`${styles.confirmDetailRow} ${styles.bold}`}>
            <div>Total:</div>
            <div>
              {sendTotalAmountStr}&nbsp;{chainSettings?.nativeCurrency?.symbol}
            </div>
          </div>
        </div>
        <div className={clsx(styles.sendToRow2, 'sendToRow2')}>
          <Button
            type="default"
            onClick={() => {
              setSendAmount('');
              setSendAmountError('is not valid amount');
              setSentBntActive(false);
              setShowSendConfirm(false);
            }}
          >
            Back
          </Button>
          <Button type="primary" onClick={swap}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TransferModal;
