import React, { useState, useEffect } from 'react';
import styles from '../FceAdmin.module.scss';
import { clsx } from 'clsx';
import { Button, Input, Select } from 'tt-ui-lib/core';
import { EditIcon } from 'tt-ui-lib/icons';
import TypedFieldset from './TypedFieldset';
import { useDigitalAssetsTTAPI } from 'modules/tt-digital-assets-provider';

const FieldItem = ({
  itemData,
  fieldTypes,
  setFetchData,
  lists,
  setSecondLoading,
  openTupleModal,
  isTuple,
  currentFields,
  setName,
}) => {
  const { getSettingsParamsListValues, setField, setFieldsPropertiesList } =
    useDigitalAssetsTTAPI();

  const [data, setData] = useState(itemData);
  const [list, setList] = useState([]);
  const [fields, setFields] = useState([]);
  const [actions, setActions] = useState([]);
  const [datePatterns, setDatePatterns] = useState([]);

  const saveField = () => {
    setSecondLoading(true);

    const parsedData = {
      ...data,
    };

    if (isTuple) {
      setFieldsPropertiesList(parsedData, true)
        .then((res) => {
          if (res !== null) setFetchData(true);
        })
        .finally(() => {
          setSecondLoading(false);
        });

      return;
    }

    setField(parsedData, true)
      .then((res) => {
        if (res !== null) setFetchData(true);
      })
      .finally(() => {
        setSecondLoading(false);
      });
  };

  const changeField = (field, value) => {
    setData((prevData) => ({ ...prevData, [field]: value }));
  };

  useEffect(() => {
    if (currentFields) {
      setFields(
        currentFields.filter(
          (item) => item?.tt_type === 'int' || item?.tt_type === 'uint' || item?.tt_type === 'eth'
        )
      );
    }
  }, [currentFields]);

  useEffect(() => {
    setData(itemData);
  }, [itemData]);

  useEffect(() => {
    if (data?.tt_type === 'payment') {
      setSecondLoading(true);

      getSettingsParamsListValues('ACTION', true)
        .then((resList) => {
          setActions(resList?.map((item) => ({ ...item, value: item.id })) || []);
        })
        .finally(() => {
          setSecondLoading(false);
        });
    }

    if (`${data?.tt_type}`.includes('date')) {
      setSecondLoading(true);

      getSettingsParamsListValues(data?.tt_type, true)
        .then((resList) => {
          setDatePatterns(resList?.map((item) => ({ ...item, value: item.id })) || []);
        })
        .finally(() => {
          setSecondLoading(false);
        });
    }
  }, [data?.tt_type]);

  useEffect(() => {
    if (data?.list_values) {
      setSecondLoading(true);

      getSettingsParamsListValues(`${data?.list_values}`, true)
        .then((resList) => {
          setList(
            resList.map((item) => ({
              ...item,
              value: `${item.value || item.value === 0 ? item.value : item.id || item.id === 0 ? item.id : ''}`,
            })) || []
          );
          setData((prevData) => ({ ...prevData, default_value: null }));
        })
        .finally(() => {
          setSecondLoading(false);
        });
    }
  }, [data?.list_values]);

  return (
    <div className={styles.fieldModalAccordionContent}>
      <div className={styles.fieldModalAccordionSelectRow}>
        <Input
          label="Title"
          value={data?.caption}
          onChange={(e) => changeField('caption', e.target.value)}
        />
        <Input
          label="Hint"
          value={data?.hint}
          onChange={(e) => changeField('hint', e.target.value)}
        />
      </div>
      <div className={styles.fieldModalAccordionSelectRow}>
        <Select
          label="Type"
          value={data?.tt_type}
          onChange={(value) => changeField('tt_type', value)}
          options={fieldTypes || []}
        />
        {data?.tt_type === 'tuple' && (
          <Button
            type="icon"
            onClick={() => {
              if (openTupleModal && data?.method_hash && data?.param) {
                openTupleModal(data.method_hash, data.param);
                setName(data?.caption);
              }
            }}
          >
            <EditIcon className="icon-blue-fill" style={{ width: 24, height: 24 }} />
          </Button>
        )}
      </div>

      <TypedFieldset
        data={data}
        changeField={changeField}
        type={data?.tt_type}
        list={list || []}
        lists={lists || []}
        actions={actions || []}
        datePatterns={datePatterns || []}
        fields={fields || []}
      />

      <div className={clsx(styles.formButtonGroup, 'formButtonGroup')}>
        <Button type="primary" onClick={() => saveField()}>
          Save
        </Button>
        <Button type="default" onClick={() => setData(itemData)}>
          Reset
        </Button>
      </div>
    </div>
  );
};

export default FieldItem;
