import React from 'react';
import clsx from 'clsx';
import styles from '../FceAdmin.module.scss';
import { Button, Modal, Input, Select, Space } from 'tt-ui-lib/core';
import { EsgIcon } from 'tt-ui-lib/icons';
import {
  fromWei,
  useDigitalAssets,
  useDigitalAssetsCaFFee,
} from 'modules/tt-digital-assets-provider';

const ContractFunctionRequest = ({
  showParamsDialog,
  onCancelHandler,
  contract,
  onSubmitHandler,
  checkHasErrors,
  setContract,
  fieldValidation,
  fieldTransform,
}) => {
  const { chainSettings, toBN, toAscii, sha3 } = useDigitalAssets();
  const { getCO2TokenCost } = useDigitalAssetsCaFFee();

  const calculateTotalPayment = async () => {
    const field = contract?.inputs?.find((el) => el?.tt_type === 'payment');
    if (field) {
      // Есть платёжное поле - выполняем расчёт
      const caffee = toBN(await getCO2TokenCost()) || 0n;
      let payload = field?.default_value ? toBN(contract?.params[field?.default_value]) || 0n : 0n;
      const payMultiplierValue = toBN(parseInt(field?.pay_multiplier_value, 10)) || 0n;

      if (field.use_caffee_like_value) {
        // Если значение как CaFFee
        payload *= caffee;
      }

      // Если есть модификаторы, применяем их к значению транзакции
      // eslint-disable-next-line default-case
      switch (field?.action) {
        case '%':
          payload += payload * payMultiplierValue;
          break;

        case '+':
          payload += payMultiplierValue;
          break;

        case '-':
          payload -= payMultiplierValue;
          break;

        case '*':
          payload *= payMultiplierValue;
          break;

        case '/':
          payload /= payMultiplierValue;
          break;
      }

      if (payload < 0n) {
        payload = 0n;
      }

      if (field.add_caffee_to_payment) {
        payload += caffee;
      }

      setContract((prev) => {
        const res = { ...prev };
        res.params[field?.param] = payload;
        res.errors[field?.param] = '';
        return res;
      });
    }
  };

  /**
   * Событие изменения значения в параметре
   * @param {Object} field
   * @param {boolean} onBlur
   * @returns {(function(*): Promise<void>)|*}
   */
  const onParamChangeHandler =
    (field, onBlur = false) =>
    async (evnt) => {
      let val = field?.type === 'bool' ? evnt?.target?.value === 'on' : evnt?.target?.value;

      const err = fieldValidation(field, val);

      if (field?.tt_type !== 'address') {
        if (err) {
          val = null;
        } else {
          val = fieldTransform(field, val);
        }
      } else if (err && onBlur) {
        val = null;
      } else {
        val = fieldTransform(field, val);
      }

      setContract((prev) => {
        const setRecurVal = (objOld, path, value) => {
          const obj = objOld ?? {};
          if (path.length === 1) {
            obj[path[0]] = value;
          } else if (path.length > 1) {
            const p = path.shift();
            obj[p] = setRecurVal(obj[p], path, value);
          }

          return obj;
        };

        const res = { ...prev };
        // res.params[field?.param] = val;
        setRecurVal(
          res.params,
          (field.path ? `${field.path}.${field.param}` : field.param)?.split('.') || [],
          val
        );
        // res.errors[field?.param] = err;
        setRecurVal(
          res.errors,
          (field.path ? `${field.path}.${field.param}` : field.param)?.split('.') || [],
          err
        );
        return res;
      });

      await calculateTotalPayment();
    };

  /**
   * Трансформер значений
   * @param {Object} param
   * @param {*} val
   * @returns {*}
   */
  const fieldTransformToView = (param, val) => {
    const type = param?.type ?? '';
    const ttType = param?.tt_type ?? '';

    switch (ttType) {
      case 'payment':
      case 'eth':
        if (type.substring(type.length - 2) === '[]') {
          return val
            ?.map((el) => (val !== null && val !== undefined ? fromWei(val) : ''))
            ?.join(',');
        }
        return val !== null && val !== undefined ? fromWei(val) : '';

      case 'date':
        if (type.substring(type.length - 2) === '[]') {
          return val?.map((el) => new Date(parseInt(el, 10) * 1000))?.join(',');
        }
        return new Date(parseInt(val, 10) * 1000).toISOString().substring(0, 10);

      case 'time':
        if (type.substring(type.length - 2) === '[]') {
          return val?.map((el) => new Date(parseInt(el, 10) * 1000))?.join(',');
        }
        return new Date(parseInt(val, 10) * 1000);

      case 'date_time':
        if (type.substring(type.length - 2) === '[]') {
          return val?.map((el) => new Date(parseInt(el, 10) * 1000))?.join(',');
        }
        return new Date(parseInt(val, 10) * 1000);

      case 'tuple':
        return ''; // TODO: Объекты никак не отображаем

      case 'string':
        if (type.substring(type.length - 2) === '[]') {
          return val?.map((el) => (type.startsWith('bytes') ? toAscii(el || '') : el))?.join(',');
        }

        return type.startsWith('bytes') ? toAscii(val || '') : val;

      default:
        return type.substring(type.length - 2) === '[]' ? (val?.join(',') ?? '') : val;
    }
  };

  //-------------------------------
  /**
   * Добавление CaFFee к значению
   * @params {Field} field
   * @returns {Promise<BigInt>}
   */
  const toSHA3 = (field) => (evnt) => {
    let val = document.getElementById(`contract_params_${field?.param}`)?.value || '';
    val = sha3(val);

    document.getElementById(`contract_params_${field?.param}`).value = val;

    const err = fieldValidation(field, val);
    if (err) {
      val = null;
    } else {
      val = fieldTransform(field, val);
    }

    setContract((prev) => {
      const setRecurVal = (objOld, path, value) => {
        const obj = objOld ?? {};
        if (path.length === 1) {
          obj[path[0]] = value;
        } else if (path.length > 1) {
          const p = path.shift();
          obj[p] = setRecurVal(obj[p], path, value);
        }

        return obj;
      };

      const res = { ...prev };
      // res.params[field?.param] = val;
      setRecurVal(
        res.params,
        (field.path ? `${field.path}.${field.param}` : field.param)?.split('.') || [],
        val
      );
      // res.errors[field?.param] = err;
      setRecurVal(
        res.errors,
        (field.path ? `${field.path}.${field.param}` : field.param)?.split('.') || [],
        err
      );
      return res;
    });
  };

  /**
   * Добавление CaFFee к значению
   * @params {Field} field
   * @returns {Promise<BigInt>}
   */
  const addCaFFee = (field) => async (evnt) => {
    const caffee = toBN(await getCO2TokenCost());
    let val = toBN(contract?.params[field?.param] || 0n);
    val += caffee;
    val = fromWei(val);

    const err = fieldValidation(field, val);
    if (err) {
      val = null;
    } else {
      val = fieldTransform(field, val);
    }

    setContract((prev) => {
      const setRecurVal = (objOld, path, value) => {
        const obj = objOld ?? {};
        if (path.length === 1) {
          obj[path[0]] = value;
        } else if (path.length > 1) {
          const p = path.shift();
          obj[p] = setRecurVal(obj[p], path, value);
        }

        return obj;
      };

      const res = { ...prev };
      // res.params[field?.param] = val;
      setRecurVal(
        res.params,
        (field.path ? `${field.path}.${field.param}` : field.param)?.split('.') || [],
        val
      );
      // res.errors[field?.param] = err;
      setRecurVal(
        res.errors,
        (field.path ? `${field.path}.${field.param}` : field.param)?.split('.') || [],
        err
      );
      return res;
    });
  };

  /**
   * Рендер поля
   * @param {Object} field
   * @param {Object} paramsObject
   * @param {Object} errorsObject
   * @returns {Element}
   */
  const renderField = (field, paramsObject, errorsObject) => {
    const params = paramsObject ?? contract?.params;
    const errors = errorsObject ?? contract?.errors;

    switch (field?.tt_type) {
      case 'string':
      case 'address':
        return (
          <div>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Input
                id={`contract_params_${field?.param}`}
                name={field?.param}
                label={field?.caption}
                value={fieldTransformToView(field, params[field?.param])}
                status={errors[field?.param] ? 'error' : undefined}
                required
                hint={field?.hint}
                disabled={field?.readonly}
                onChange={onParamChangeHandler(field)}
                onBlur={onParamChangeHandler(field, true)}
                style={{ width: '100%' }}
                maxLength={field?.tt_type === 'address' ? 42 : undefined}
              />
              <div
                style={{
                  display: errors[field?.param] ? 'block' : 'none',
                  color: '#ff4d4f',
                  marginBottom: 10,
                }}
              >
                {errors[field?.param]}
              </div>
            </Space>
          </div>
        );

      case 'hex':
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Input
                id={`contract_params_${field?.param}`}
                name={field?.param}
                label={field?.caption}
                value={fieldTransformToView(field, params[field?.param])}
                status={errors[field?.param] ? 'error' : undefined}
                required
                disabled={field?.readonly}
                hint={field?.hint}
                onChange={onParamChangeHandler(field)}
                // onBlur={onParamChangeHandler(field)}
                style={{ width: '85%' }}
                maxLength={field?.tt_type === 'address' ? 42 : undefined}
              />
              <div
                style={{
                  display: errors[field?.param] ? 'block' : 'none',
                  color: '#ff4d4f',
                  marginBottom: 10,
                }}
              >
                {errors[field?.param]}
              </div>
            </Space>
            <Button type="primary" style={{ marginLeft: '10px' }} onClick={toSHA3(field)}>
              <EsgIcon style={{ width: 20, height: 20 }} />
            </Button>
          </div>
        );

      case 'int':
      case 'uint':
      case 'payment':
        return (
          <div>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Input
                id={`contract_params_${field?.param}`}
                name={field?.param}
                label={field?.caption}
                value={fieldTransformToView(field, params[field?.param])}
                status={errors[field?.param] ? 'error' : undefined}
                required
                disabled={field?.readonly}
                hint={field?.hint}
                onChange={onParamChangeHandler(field)}
                // onBlur={onParamChangeHandler(field)}
                style={{ width: '100%' }}
                suffix={
                  ['payment', 'eth'].includes(field?.tt_type) ? (
                    <>
                      {field?.use_native_symbol
                        ? chainSettings?.nativeCurrency?.symbol
                        : field?.value_symbol}
                    </>
                  ) : null
                }
              />
              <div
                style={{
                  display: errors[field?.param] ? 'block' : 'none',
                  color: '#ff4d4f',
                  marginBottom: 10,
                }}
              >
                {errors[field?.param]}
              </div>
            </Space>
          </div>
        );

      case 'eth':
        return field.param !== 'transactionPayload' ? (
          <div>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Input
                id={`contract_params_${field?.param}`}
                name={field?.param}
                label={field?.caption}
                value={fieldTransformToView(field, params[field?.param])}
                status={errors[field?.param] ? 'error' : undefined}
                required
                disabled={field?.readonly}
                hint={field?.hint}
                onChange={onParamChangeHandler(field)}
                // onBlur={onParamChangeHandler(field)}
                style={{ width: '100%' }}
                suffix={
                  ['payment', 'eth'].includes(field?.tt_type) ? (
                    <>
                      {field?.use_native_symbol
                        ? chainSettings?.nativeCurrency?.symbol
                        : field?.value_symbol}
                    </>
                  ) : null
                }
              />
              <div
                style={{
                  display: errors[field?.param] ? 'block' : 'none',
                  color: '#ff4d4f',
                  marginBottom: 10,
                }}
              >
                {errors[field?.param]}
              </div>
            </Space>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Input
                id={`contract_params_${field?.param}`}
                name={field?.param}
                label={field?.caption}
                value={fieldTransformToView(field, params[field?.param])}
                status={errors[field?.param] ? 'error' : undefined}
                required
                disabled={field?.readonly}
                hint={field?.hint}
                onChange={onParamChangeHandler(field)}
                // onBlur={onParamChangeHandler(field)}
                style={{ width: '85%' }}
                suffix={
                  ['payment', 'eth'].includes(field?.tt_type) ? (
                    <>
                      {field?.use_native_symbol
                        ? chainSettings?.nativeCurrency?.symbol
                        : field?.value_symbol}
                    </>
                  ) : null
                }
              />
              <div
                style={{
                  display: errors[field?.param] ? 'block' : 'none',
                  color: '#ff4d4f',
                  marginBottom: 10,
                }}
              >
                {errors[field?.param]}
              </div>
            </Space>

            <Button type="primary" style={{ marginLeft: '10px' }} onClick={addCaFFee(field)}>
              <EsgIcon style={{ width: 20, height: 20 }} />
            </Button>
          </div>
        );

      case 'bool':
        return (
          <div>
            <div>
              <input
                type="checkbox"
                id={`${field?.param}`}
                name={field?.param}
                readOnly={field?.readonly}
                disabled={field?.readonly}
                checked={params[field.name]}
                onChange={onParamChangeHandler(field)}
              />
              <label htmlFor={field?.param}>{field?.caption || field?.param}</label>
            </div>
            {/*
            <FormControlLabel
              control={
                <CheckBox
                  id={`${field?.param}`}
                  name={field?.param}
                  readOnly={field?.readonly}
                  disabled={field?.readonly}
                  checked={params[field.name]}
                  hint={field?.hint}
                  onChange={onParamChangeHandler(field)}
                />
              }
              label={field?.caption || field?.param}
              labelPlacement="end"
            />
*/}
          </div>
        );

      case 'string_list':
      case 'int_list':
      case 'uint_list':
      case 'address_list':
        return (
          <div>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Select
                id={field?.param}
                name={field?.param}
                label={field?.caption}
                options={
                  contract?.lists[field?.list_values]?.map((el) => ({
                    value: `${el?.id || el?.value}`,
                    label: el?.name,
                    tooltipText: el?.description,
                  })) || []
                }
                value={fieldTransformToView(field, params[field?.param])}
                onChange={(value) => onParamChangeHandler(field)({ target: { value: value } })}
                status={errors[field?.param] ? 'error' : undefined}
                disabled={field?.readonly}
                style={{ width: '100%' }}
                hint={field?.hint}
              />
              <div
                style={{
                  display: errors[field?.param] ? 'block' : 'none',
                  color: '#ff4d4f',
                  marginBottom: 10,
                }}
              >
                {errors[field?.param]}
              </div>
            </Space>
          </div>
        );

      case 'date':
        return (
          <div>
            {/*
            <FormControlLabel
              className={clsx(styles.ContractFieldList)}
              control={
                <FormControl>
                  <DatePicker
                    id={field?.param}
                    name={field?.param}
                    label={field?.caption}
                    defaultValue={fieldTransformToView(params[field?.param])}
                    value={fieldTransformToView(params[field?.param])}
                    error={!!errors[field?.param]}
                    helperText={errors[field?.param]}
                    required
                    readOnly={field?.readonly}
                    disabled={field?.readonly}
                    onChange={onParamChangeHandler(field)}
                    onBlur={onParamChangeHandler(field)}
                    _style={{ width: '628px' }}
                  />
                </FormControl>
              }
              label={field?.capture}
            />
*/}
            <label id={`${field?.param}_label`}>{field?.caption}</label>
            <input
              type="date"
              value={fieldTransformToView(field, params[field?.param])}
              id={field?.param}
              onChange={onParamChangeHandler(field)}
              onBlur={onParamChangeHandler(field)}
              required
            />
          </div>
        );

      case 'time':
        return (
          <div>
            {/*
            <FormControlLabel
              className={clsx(styles.ContractFieldList)}
              control={
                <FormControl>
                  <DatePicker
                    id={field?.param}
                    name={field?.param}
                    label={field?.caption}
                    defaultValue={fieldTransformToView(params[field?.param])}
                    value={fieldTransformToView(params[field?.param])}
                    error={!!errors[field?.param]}
                    helperText={errors[field?.param]}
                    required
                    readOnly={field?.readonly}
                    disabled={field?.readonly}
                    onChange={onParamChangeHandler(field)}
                    onBlur={onParamChangeHandler(field)}
                    _style={{ width: '628px' }}
                  />
                </FormControl>
              }
              label={field?.capture}
            />
*/}
            <label id={`${field?.param}_label`}>{field?.caption}</label>
            <input
              type="time"
              value={fieldTransformToView(field, params[field?.param])}
              id={field?.param}
              onChange={onParamChangeHandler(field)}
              onBlur={onParamChangeHandler(field)}
              required
            />
          </div>
        );

      case 'date_time':
        return (
          <div>
            {/*
            <FormControlLabel
              className={clsx(styles.ContractFieldList)}
              control={
                <FormControl>
                  <DatePicker
                    id={field?.param}
                    name={field?.param}
                    label={field?.caption}
                    defaultValue={fieldTransformToView(params[field?.param])}
                    value={fieldTransformToView(params[field?.param])}
                    error={!!errors[field?.param]}
                    helperText={errors[field?.param]}
                    required
                    readOnly={field?.readonly}
                    disabled={field?.readonly}
                    onChange={onParamChangeHandler(field)}
                    onBlur={onParamChangeHandler(field)}
                    _style={{ width: '628px' }}
                  />
                </FormControl>
              }
              label={field?.capture}
            />
*/}
            <label id={`${field?.param}_label`}>{field?.caption}</label>
            <input
              type="datetime-local"
              value={fieldTransformToView(field, params[field?.param])}
              id={field?.param}
              onChange={onParamChangeHandler(field)}
              onBlur={onParamChangeHandler(field)}
              required
            />
          </div>
        );

      case 'tuple':
        return field?.tuple?.length > 0 ? (
          <fieldset style={{ padding: '10px' }}>
            <legend>{field?.caption}</legend>
            <div>
              {field.tuple?.map((fieldT) =>
                renderField(fieldT, params[field?.param], errors[field?.param])
              )}
            </div>
          </fieldset>
        ) : (
          ''
        );

      default:
        return <div>{`Unknown type: "${field?.type}"`}</div>;
    }
  };

  return (
    <Modal
      open={showParamsDialog}
      onClose={onCancelHandler}
      title={`${contract?.caption}.${contract?.func}`}
      destroyOnClose
    >
      <div>{contract?.inputs?.map((field) => renderField(field))}</div>

      <div className={clsx(styles.formButtonGroup, 'formButtonGroup')}>
        <Button
          type="primary"
          isSubmit
          onClick={onSubmitHandler}
          disabled={checkHasErrors(contract?.errors)}
        >
          Submit
        </Button>
        <Button type="default" onClick={onCancelHandler}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default ContractFunctionRequest;
