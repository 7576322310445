import React, { useContext, useState } from 'react';
import userContext from '../../context/User/userContext';
import { GET_NOTIFICATIONS } from '../../api';
import { HeaderNavbar } from '../../modules/tt-navmenu';
import { SignInUpModalWrapper } from '../Modals/SignInUpModal/SignInUpModal';

const Navbar = (props) => {
  const { avaProps, logOut } = props;
  const { user } = useContext(userContext);

  const [anchorLoginEl, setAnchorLoginEl] = useState(null);
  const open = Boolean(anchorLoginEl);

  const handleLoginClose = () => {
    setAnchorLoginEl(null);
  };

  return (
    <>
      <HeaderNavbar
        avaProps={avaProps}
        getNotificationsQuery={GET_NOTIFICATIONS}
        anchorLoginEl={anchorLoginEl}
        setAnchorLoginEl={setAnchorLoginEl}
        user={user}
        notificationSocket={process.env.REACT_APP_WS}
        logOut={logOut} // TODO check if need
        redirectDomain={process.env.REACT_APP_TT}
        mode="DA"
      />

      <SignInUpModalWrapper open={open} closeModal={handleLoginClose} />
    </>
  );
};

export default Navbar;
