import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router';
import SupportChat from '../../modules/tt-concierge';
import { logoutUserHandler } from 'utils/appUtils';
import Navbar from 'components/Navbar';
import { MobileNavbarWrapper } from 'components/navbarMobile/NavbarMobile';
import { Breadcrumb } from 'components/Breadcrumb';
import { AdminNavbar, AppAvatar } from '../../modules/tt-navmenu';
import PersonalAreaBox from 'components/PersonalAreaBox';
import { useLazyQuery, useMutation } from '@apollo/client';
import PageContainer from 'components/Layout/PageContainer';
import DashboardWidgets from 'components/DashboardWidgets/DashboardWidgets';
import { GET_ACTIVE_USER_COMPANIES_LIST, GET_SHORT_COMPANY_BY_ID, LOGOUT_USER } from '../../api';

import userContext from 'context/User/userContext';
import { DigitalAssetsProvider } from 'modules/tt-digital-assets-provider';
import DigitalAssets from '../DigitalAssets';

import styles from './Dashboard.module.scss';
import { createTheme } from '@mui/material'; // TODO: remove
import Cookies from 'js-cookie';
import CarbonCertificatePage from '../DigitalAssets/CarbonCertificate';
import { ContentBlock } from 'tt-ui-lib/core';
import AuthWrapper from 'components/AuthWarpper';
import RegisterPageView from 'views/DigitalAssets/Register';

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#18397A',
      darker: '#18397A',
    },
    important: {
      main: '#d95e27',
      darker: '#bb5021',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    notSelected: {
      main: '#212121',
      contrastText: '#151515',
    },
  },
  box: {
    margin: '10px',
    padding: '60px 20px',
    borderRadius: '10px',
    backgroundColor: 'white',
  },
});

const sideMenuExcludedPage = ['pricing'];
const laSalleGreenTheme = {
  headerGradient: 'linear-gradient(99.65deg, #143471 100%, #1550BD 100%)',
  sendButtonColor: '#143471',
  messageBackgroundColor: '#E3EDFF',
  supportButton: '#143471',
};
const isProduction = process.env.REACT_APP_ENV === 'production';

const { REACT_APP_CONCIERGE_URL, REACT_APP_CONCIERGE_SECRETS, REACT_APP_TT, REACT_APP_MARKET } =
  process.env;

const DashboardRoutes = () => {
  const router = useNavigate();
  const pageName = window.location.pathname.split('/').pop();
  const companyID = Cookies.get('companyId');

  const endpointTT = REACT_APP_TT || '';
  const endpointMarket = REACT_APP_MARKET || '';

  const { user, selectedCompany, setSelectedCompany, removeSelectedCompany } =
    useContext(userContext);

  const [logoutUser] = useMutation(LOGOUT_USER);
  const [getActiveCompanies, { data: companiesData }] = useLazyQuery(
    GET_ACTIVE_USER_COMPANIES_LIST
  );

  const [getSelectedCompany] = useLazyQuery(GET_SHORT_COMPANY_BY_ID);

  const [activeCompanies, setActiveCompanies] = useState(null);
  const [userCompanies, setUserCompanies] = useState([]);
  const [isCompaniesFetch, setIsCompaniesFetch] = useState(false);
  const [isCompanyFetch, setIsCompanyFetch] = useState(false);

  const DASHBOARD_ROUTES_INFO = [
    {
      path: '/',
      element: <Navigate to="/dashboard/digital_assets" />,
    },
    {
      path: '/digital_assets',
      element: <DigitalAssets />,
    },
    {
      path: '/certificate/:id',
      name: 'Certificate',
      element: isProduction ? (
        <Navigate to="/dashboard/digital_assets" />
      ) : (
        <CarbonCertificatePage />
      ),
    },
    {
      path: '/certificate/:certId/register/:id',
      name: 'New register',
      element: isProduction ? (
        <Navigate to="/dashboard/digital_assets" />
      ) : (
        <AuthWrapper reloadAfterLogin>
          <RegisterPageView />
        </AuthWrapper>
      ),
    },
    {
      path: '*',
      element: <Navigate to="/dashboard/digital_assets" />,
    },
  ];

  const openOptionProfile = () => {
    router(`/dashboard/profile/${user?.id}`);
  };

  const redirectMarket = (url) => `${endpointMarket}${url}`;
  const redirectMain = (url) => `${endpointTT}${url}`;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [kyc, setKyc] = useState('');
  const [avatar, setAvatar] = useState('');
  const [ceo, setCeo] = useState('');
  const [roleName, setRoleName] = useState('');
  const [invited, setInvited] = useState('');
  const [visibleArea, setVisibleArea] = useState('mainContent');
  const [showSupportChat, setShowSupportChat] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);

  const onChangeCompany = (companyInfo) => {
    setSelectedCompany(companyInfo);
  };

  const onChangeToPersonal = () => {
    removeSelectedCompany();
  };

  const getUserCompanies = () => {
    (async () => {
      const companyValue = await getActiveCompanies();

      if (companyValue?.data?.showActiveUserCompanies?.length) {
        setUserCompanies(companyValue?.data.showActiveUserCompanies);
      } else {
        setUserCompanies([]);
      }
    })();
  };

  const getUserSelectedCompany = () => {
    (async () => {
      const companyValue = await getSelectedCompany({
        variables: { id: companyID },
      });

      if (companyValue.data?.showCompanyById) {
        onChangeCompany(companyValue.data.showCompanyById);
      } else {
        onChangeCompany(null);
      }
    })();
  };

  useEffect(() => {
    if (user.id && !isCompaniesFetch) {
      getUserCompanies();
      setIsCompaniesFetch(true);
    }
  }, [user]);

  useEffect(() => {
    if (!selectedCompany.isCompanySelected && companyID && user.id && !isCompanyFetch) {
      getUserSelectedCompany();
      setIsCompanyFetch(true);
    } else if (selectedCompany.isCompanySelected && !companyID) {
      removeSelectedCompany();
    }
  }, [companyID, selectedCompany, user]);

  useEffect(() => {
    if (user?.id) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setAvatar(user.avatar);
      setCeo(user.ceo_of);
      setKyc(user.kyc);
      setRoleName(user.role_name);
      setInvited(user.invited_by_name);

      if (user.roles_list) {
        JSON.parse(user.roles_list)?.forEach((role) => {
          if (role === 'admin') {
            setShowNavbar(true);
          }
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (user?.market_id) {
      getActiveCompanies().then();
      if (companiesData?.showActiveUserCompanies?.length) {
        setActiveCompanies(companiesData?.showActiveUserCompanies);
      }
    }
  }, [user, getActiveCompanies, companiesData]);

  const logOut = () => {
    logoutUserHandler(logoutUser);
  };

  return (
    <PageContainer>
      <SupportChat
        them={laSalleGreenTheme}
        showSupportChat={showSupportChat}
        setShowSupportChat={setShowSupportChat}
        companyName="TT Concierge"
        apiUrl={REACT_APP_CONCIERGE_URL} // API url
        apiSecret={REACT_APP_CONCIERGE_SECRETS} // API Authorization token
        supportMail="support@transparenterra.com"
        user={user}
      />

      <DigitalAssetsProvider userContextObject={userContext}>
        <div className={styles.content} id="content-layout">
          <div className={styles.headerWrapper}>
            <AdminNavbar
              showNavbar={showNavbar}
              theme={theme}
              baseUrl={process.env.REACT_APP_TT || ''}
              baseFceUrl=""
              mode="da"
            />
            <Navbar
              backProps={() => setVisibleArea('mainContent')}
              avaProps={
                user && (
                  <AppAvatar
                    user={user}
                    selectedCompany={selectedCompany}
                    arrowButton={1}
                    nav={2}
                    userName={
                      selectedCompany.isCompanySelected
                        ? selectedCompany.selectedCompany.company_name
                        : `${firstName} ${lastName}`
                    }
                    src={
                      selectedCompany.isCompanySelected
                        ? selectedCompany.selectedCompany.logos[0]?.path || ''
                        : avatar
                    }
                    openOptions={1}
                    selectProfile={openOptionProfile}
                    logOut={logOut}
                    logoutUserHandler={logOut}
                    userCompanies={userCompanies}
                    selectCompany={onChangeCompany}
                    selectToPersonalProfile={onChangeToPersonal}
                    mainTTUrl={process.env.REACT_APP_TT || ''}
                    marketTTUrl={process.env.REACT_APP_MARKET || ''}
                    digitalAssetsTTUrl=""
                    mode="DA"
                  />
                )
              }
              logOut={logOut}
              setOpenSupportChat={setShowSupportChat}
              redirectDomain={process.env.REACT_APP_TT || ''}
              marketTTUrl={process.env.REACT_APP_MARKET || ''}
              digitalAssetsTTUrl=""
              qaTTUrl={process.env.REACT_APP_QA}
            />

            <div className={styles.breadcrumb}>
              <Breadcrumb
                data={[
                  { href: `${endpointTT}/dashboard/feed`, name: 'TransparenTerra' },
                  { href: endpointTT, name: pageName },
                ]}
              />
            </div>
          </div>

          <div className={styles.pageContentWrapper}>
            <div className={styles.pageContent}>
              {!sideMenuExcludedPage.includes(pageName) && (
                <div className={styles.leftColumn} style={{ top: showNavbar ? 195 : 125 }}>
                  {activeCompanies ? (
                    <PersonalAreaBox
                      avaProps={
                        <AppAvatar
                          user={user}
                          selectedCompany={selectedCompany}
                          mainTTUrl={process.env.REACT_APP_TT || ''}
                          marketTTUrl={process.env.REACT_APP_MARKET || ''}
                          userName={
                            selectedCompany.isCompanySelected
                              ? selectedCompany.selectedCompany.company_name
                              : `${firstName} ${lastName}`
                          }
                          src={
                            selectedCompany.isCompanySelected
                              ? selectedCompany.selectedCompany.logos[0]?.path || ''
                              : avatar
                          }
                          role={selectedCompany.isCompanySelected ? '' : roleName}
                          nav={0}
                          onClickShowProfile
                          mode="DA"
                        />
                      }
                      firstNameProps={{
                        children: selectedCompany.isCompanySelected
                          ? selectedCompany.selectedCompany.company_name
                          : firstName,
                      }}
                      lastNameProps={{
                        children: selectedCompany.isCompanySelected ? '' : lastName,
                      }}
                      inviteProps={{ children: selectedCompany.isCompanySelected ? '' : invited }}
                      ceoProps={{ children: selectedCompany.isCompanySelected ? '' : ceo }}
                      kycProps={{ children: selectedCompany.isCompanySelected ? '' : kyc }}
                      mode="DA"
                    />
                  ) : (
                    <PersonalAreaBox
                      avaProps={
                        <AppAvatar
                          user={user}
                          selectedCompany={selectedCompany}
                          mainTTUrl={process.env.REACT_APP_TT || ''}
                          marketTTUrl={process.env.REACT_APP_MARKET || ''}
                          userName={
                            selectedCompany.isCompanySelected
                              ? selectedCompany.selectedCompany.company_name
                              : `${firstName} ${lastName}`
                          }
                          src={
                            selectedCompany.isCompanySelected
                              ? selectedCompany.selectedCompany.logos[0]?.path || ''
                              : avatar
                          }
                          role={selectedCompany.isCompanySelected ? '' : roleName}
                          nav={0}
                          onClickShowProfile
                          mode="DA"
                        />
                      }
                      firstNameProps={{
                        children: selectedCompany.isCompanySelected
                          ? selectedCompany.selectedCompany.company_name
                          : firstName,
                      }}
                      lastNameProps={{
                        children: selectedCompany.isCompanySelected ? '' : lastName,
                      }}
                      inviteProps={{ children: selectedCompany.isCompanySelected ? '' : invited }}
                      ceoProps={{ children: selectedCompany.isCompanySelected ? '' : ceo }}
                      kycProps={{ children: selectedCompany.isCompanySelected ? '' : kyc }}
                      mode="DA"
                    />
                  )}
                </div>
              )}

              <ContentBlock>
                <Routes>
                  {DASHBOARD_ROUTES_INFO.map((routeInfo) => (
                    <Route path={routeInfo.path} element={routeInfo.element} key={routeInfo.path} />
                  ))}
                </Routes>
              </ContentBlock>

              {!sideMenuExcludedPage.includes(pageName) && (
                <DashboardWidgets user={user} kyc={kyc} showNavbar={showNavbar} />
              )}

              <div className={styles.mobileNavigation}>
                <MobileNavbarWrapper />
              </div>
            </div>
          </div>
        </div>
      </DigitalAssetsProvider>
    </PageContainer>
  );
};

export default DashboardRoutes;
